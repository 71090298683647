<template lang="pug">
.w100.d-flex.justify-content-center
  span.material-icons-outlined(v-if="data.orderFormCustomerId")
    | checklist_rtl
</template>

<script>
import {
  reportingStatus,
  quoteStatus,
  invoiceStatus,
  creditStatus,
  orderFormProviderStatus,
  receiptFormProviderStatus,
  deliveryFormStatus,
  inventoryFormStatus,
  invoiceProviderStatus,
  creditProviderStatus,
  orderFormCustomerStatus,
  affairStatus,
} from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      item: null,
      data: null,
      signedDate: null,
    };
  },
};
</script>
