<template lang="pug">
.d-flex.align-items-center
    .e-chips.text-white.bg-primary(v-if="data.sourceType == 1" style="padding: 0 4px; line-height:16px; border-radius: 4px; margin-right:5px") Réalisé
    .e-chips.text-white.bg-warning(v-else style="padding: 0 4px; line-height:16px; border-radius: 4px; margin-right:5px") Prévu
</template>
<script>
export default {
  data() {
    return {
      data: null
    };
  },
}
</script>
