import PropertiesFr from "@/properties-fr";
import { valueAccessor } from "@syncfusion/ej2-vue-grids";
import { filter } from "vue/types/umd";

export async function setColumnsByObject(
  data: object,
  parent: string,
  that: any
) {
  for (const [key, value] of Object.entries(data)) {
    if (key !== "$id" && key !== "displayLabel") {
      // key !=="OperationsManager" &&
      const typeValue = value ? typeof value : "string";
      const translateKey = PropertiesFr.find((el) => el.key == key)
        ? PropertiesFr.find((el) => el.key == key).fr
        : strLcFirst(key);
      const format = PropertiesFr.find((el) => el.key == key)
        ? PropertiesFr.find((el) => el.key == key).format
        : null;
      if (typeValue === "object" && !Array.isArray(value)) {
        // On boucle
        // setColumnsByObject(value, (parent ? parent :'')+key+".", that)
      } else {
        if (parent) {
          // that.headerData.push({
          //   headerText : key == "name" || key == "label" ? parent :  parent + ' > ' + key,
          //   // caption : key == "Name" || key == "Label" ? translateProperties(parent) :  translateProperties(parent) + ' > ' + translateProperties(key),
          //   field: strLcFirst(parent)+key,
          //   visible:key == "name" || key == "label" ? true : false
          // })
        } else {
          if (
            !that.headerData.find((el) => el.field == strLcFirst(key)) &&
            PropertiesFr.find((el) => el.key == key) &&
            !PropertiesFr.find((el) => el.key == key).notUse &&
            !key.includes("Id")
          ) {
            const dataColumn = {
              field: strLcFirst(key),
              headerText: translateKey,
              visible: false,
              allowFiltering: true,
              allowSorting: true,
              isPrimaryKey: key == "id" ? true : false,
              defaultValue: key == "id" ? 0 : "",
              autoFit: true,
              allowEditing: false,
              textAlign:
                format && format == "formatCurrency" ? "right" : "left",
              type: key.includes("date") ? "date" : format == "P2" ? "" : "",
              valueAccessor:
                format == "P2"
                  ? (field, data) => {
                      return data[field] && parseFloat(data[field]) > 0
                        ? data[field] + "%"
                        : "";
                    }
                  : null,
              template: applyTemplate(key, typeValue),
              filter: applyFilter(key, typeValue),
              filterTemplate: filterTemplate(key, typeValue),
              format: key.includes("date") ? "yMd" : format ? format : null,
              showInColumnChooser: true,
              displayAsCheckBox:
                key.includes("show") || key.includes("hide") ? true : false,
              unavailableExport: PropertiesFr.find((el) => el.key == key)
                ?.unavailableExport
                ? true
                : false,
            };
            that.headerData.push(dataColumn);
          }
        }
      }
    }
  }
}

export function applyFilter(filterTemplate: string, type: any): string {
  if (filterTemplate === "collaborators") {
    return "collaboratorsFilterTemplate";
  }
}

export function applyTemplate(template: string, type: any): string {
  if (template === "collaboratorIds") {
    return "collaboratorsTemplate";
  } else if (template === "discountGlobalNumber") {
    return "discountGlobalTemplate";
  } else if (template == "discountGlobalType") {
    return "discountGlobalTypeTemplate";
  } else if (
    template.includes("date") ||
    template.includes("Date") ||
    template === "createdAt"
  )
    return "dateYmdTemplate";
  else if (type === "boolean" || template == "ownAddressAffair")
    return "booleanTemplate";
  else if (template === "internalNote") return "stripHtmlTemplate";
  else if (template === "customPerimeters")
    return "affairCustomPerimeterTemplate";
  else return "";
}

export function filterTemplate(template: string, type: string): string {
  if (template === "collaboratorIds") return "collaboratorsFilterTemplate";
}

export function strLcFirst(str: string): string {
  return (str + "").charAt(0).toLowerCase() + str.substr(1);
}

export function getType(value: any): string {
  const type = typeof value;

  if (type === "object") {
    if (value === null) {
      return "string";
    }

    if (Array.isArray(value)) {
      return "array";
    }

    if (value instanceof Date) {
      return "date";
    }

    if (Object.prototype.toString.call(value) === "[object RegExp]") {
      return "regexp";
    }

    return "object";
  }

  if (type === "function") {
    return "function";
  }

  if (value !== value) {
    return "nan";
  }

  return type;
}
