<template lang="pug">
p.m-0(v-html="formatStripHtml(item)")
</template>

<script>
export default {
  data() {
    return {
      data: null,
    };
  },
  beforeMount() {
    this.item =
      this.data[this.data.column.field] &&
      this.data[this.data.column.field] !== false
        ? this.data[this.data.column.field]
        : "";
  },
  methods: {
    formatStripHtml(str) {
      const haveMultiParagraph = str.match(/<p\b[^>]*>(.*?)<\/p>/gi);
      if (!str) {
        return null;
      } else if (!haveMultiParagraph && str) {
        return str.replace(/\r?\n|\r/g, "<br>");
      } else if (haveMultiParagraph.length < 2) {
        return str ? str.replace(/\r?\n|\r/g, "<br>") : "";
      } else {
        let div = document.createElement("div");
        div.innerHTML = str;
        let firstP = div.querySelector("p");
        firstP.textContent = firstP.textContent + "...";
        str = firstP.outerHTML;
        return str ? str.replace(/\r?\n|\r/g, "<br>") : "";
      }
    },
  },
};
</script>

<style>
p {
  p {
    margin: 0 !important;
  }
}
</style>
