<template lang="pug">
div(class="d-flex ")
    ejs-button(v-on:click.native="deleteItem" content="" iconCss='e-btn-sb-icons e-icon-trash' locale='fr' :cssClass="'e-outline e-danger e-small'")

</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        deleteItem(e) {
            if ( this.data.customFieldGroupId ) {
                const indexCustomFieldGroup = this.$parent.$parent.$parent.dataForm.customFieldGroups.findIndex( customFieldGroup => customFieldGroup.id === this.data.customFieldGroupId )
                const indexCustomField = this.$parent.$parent.$parent.dataForm.customFieldGroups[indexCustomFieldGroup].customFields.findIndex( customField => customField.id === this.data.id )
                this.$parent.$parent.$parent.dataForm.customFieldGroups[indexCustomFieldGroup].customFields.splice(indexCustomField, 1)
            }
            const trElement = e.target.closest("tr")
            this.$parent.$parent.$refs['overviewgrid'].deleteRow(trElement)
        }
    },
}
</script>
<style lang="">
    
</style>