<template lang="pug">
.w-100
    .w-100.d-flex.align-items-center.justify-content-between.mb-50
        div.d-flex.align-items-center
            h4 Détails des activités commerciales
        div
            ejs-button(v-on:click.native="addActivity()" content="+ Nouveau rapport d'activité" :isPrimary="true" locale='fr' :cssClass="'e-outline e-small'")
    .d-flex.w-100
        .text-center.flex-center.w-100(v-if='!headerLoaded')
            .loading-bg-inner
                .loader
                    .outer
                    .middle
                    .inner
            .mt-5(style="font-size:1rem !important")
                br
                br
                br
                | Chargement de la liste des activités commerciales...
        sync-grid#overviewsubgrid.details(v-else ref="overviewsubgrid" :key="'overviewsubgrid_'+data.id" :gridData="dataFormActivities" :name="name" :allowSelection="allowSelection" :allowPaging="allowPaging" :headerData="headerData" :editSettings="editSettings" :allowGrouping="allowGrouping" @setSelectedRows="setSelectedRows" @actionBegin="actionBegin")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { DateTimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

export default {
  components: {
    SyncGrid: () => import("@/components/global/grid/Grid.vue"),
    "ejs-datetimepicker": DateTimePickerComponent,
  },
  data() {
    return {
      name: "reportDetail",
      headerData: [],
      headerLoaded: false,
      selectedRows: [],
      dataFormActivities: [],
      allowGrouping: false,
      allowSelection: false,
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Normal",
        newRowPosition: "Bottom",
        titleAdd: "Nouvelle mission",
        titleEdit: "Modifier la mission",
        titleField: "label",
      },
      allowPaging: false,
    };
  },
  computed: {
    ...mapGetters([
      "collaboratorsList",
      "companiesList",
      "activityChanelTypesList",
      "activityResultTypesList",
      "activitySourceTypesList",
      "institutionSelected",
      "contactsList",
    ]),
  },
  async created() {
    this.getContacts({});
    let allPromises = [];
    if (!this.collaboratorsList || this.collaboratorsList.length == 0) {
      allPromises.push(this.getCollaborators({}));
    }
    if (!this.companiesList || this.companiesList.length == 0) {
      allPromises.push(this.getCompanies({}));
    }
    if (
      !this.activityChanelTypesList ||
      this.activityChanelTypesList.length == 0
    ) {
      allPromises.push(this.getActivityChanelTypes({}));
    }
    if (
      !this.activityResultTypesList ||
      this.activityResultTypesList.length == 0
    ) {
      allPromises.push(this.getActivityResultTypes({}));
    }
    if (
      !this.activitySourceTypesList ||
      this.activitySourceTypesList.length == 0
    ) {
      allPromises.push(this.getActivitySourceTypes({}));
    }
    await Promise.all(allPromises).then(async () => {
      await this.getActivitiesByCompanyId({
        companyId: this.data.id,
        updateState: false,
      }).then(async (res) => {
        this.dataFormActivities = structuredClone(res);
        await this.setHeaderData();
        if (res.length) {
          await this.setColumnsByObject(res[0], null, this);
        }
        this.headerLoaded = true;
      });
    });
  },
  methods: {
    ...mapActions([
      "getActivitiesByCompanyId",
      "getCollaborators",
      "getCompanies",
      "getActivityChanelTypes",
      "getActivityResultTypes",
      "getActivitySourceTypes",
      "updateActivity",
      "createActivity",
      "deleteActivities",
      "getContacts",
    ]),
    setColumnsByObject,
    async setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
        },
        {
          type: "date",
          field: "activityDate",
          headerText: "Date",
          width: 140,
          maxWidth: 140,
          format: "dd/MM/yyyy HH:mm",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          defaultValue: new Date(),
          editType: "datetimepickeredit",
        },
        {
          type: "number",
          field: "collaboratorId",
          filterTemplate: "collaboratorFilterTemplate",
          headerText: "Réalisé par",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          foreignKeyValue: "id",
          dataSource: this.collaboratorsList,
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown", //<<FIX
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "companyId",
          filterTemplate: "companyFilterTemplate",
          headerText: "Société",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.companiesList,
          edit: {
            source: this.companiesList,
            fields: { text: "name", value: "id" },
            type: "dropdown", //<<FIX
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "contactId",
          headerText: "Interlocuteur(trice)",
          filterTemplate: "contactFilterTemplate",
          minWidth: 160,
          allowFiltering: true,
          template: "contactTemplate",
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.contactsList,
          edit: {
            source: this.contactsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown", //<<FIX
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "activitySourceTypeId",
          filterTemplate: "activitySourceFilterTemplate",
          headerText: "Source",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.activitySourceTypesList,
          edit: {
            source: this.activitySourceTypesList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "activityChanelTypeId",
          filterTemplate: "activityChanelFilterTemplate",
          headerText: "Canal",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.activityChanelTypesList,
          edit: {
            source: this.activityChanelTypesList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
          },
          // editType: "dropdownedit",
        },
        {
          type: "number",
          field: "activityResultTypeId",
          filterTemplate: "activityResultFilterTemplate",
          headerText: "Résultat",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          dataSource: this.activityResultTypesList,
          edit: {
            source: this.activityResultTypesList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
          },
          // editType: "dropdownedit",
        },
        {
          type: "string",
          field: "comments",
          headerText: "Commentaire",
          minWidth: 300,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          allowGrouping: false,
          showInColumnChooser: true,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          filter: { type: "Menu", operator: "contains" },
          edit: { type: "textarea", name: "comments" },
        },
        {
          type: "boolean",
          field: "isUnreachable",
          headerText: "NRP",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "boolean",
          editType: "booleanedit",
        },
        {
          type: "boolean",
          field: "hasLeavingMessage",
          headerText: "Message laissé",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "boolean",
          editType: "booleanedit",
        },
        {
          type: "date",
          field: "reminderDate",
          headerText: "Date relance",
          width: 140,
          maxWidth: 140,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          defaultValue: new Date(),
          format: "dd/MM/yyyy HH:mm",
          editType: "datetimepickeredit",
        },
        {
          type: "commands",
          headerText: "Action",
          width: 55,
          maxWidth: 55,
        },
      ];
      this.headerLoaded = true;
    },
    addActivity() {
      this.$refs.overviewsubgrid.$refs.overviewgrid.addRecord();
    },

    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        this.dataFormActivities.push({
          ...args.data,
          institutionId: this.institutionSelected.id,
        });
        await this.createActivity({
          activity: args.data,
          route: "company",
        }).then((res) => {
          this.dataFormActivities.splice(
            this.dataFormActivities.length - 1,
            1,
            res[0]
          );
          this.$refs.overviewsubgrid.$refs.overviewgrid.refresh();
        });
      }
      if (args.requestType === "save" && args.action === "edit") {
        this.dataFormActivities.splice(args.rowIndex, 1, args.data);
        await this.updateActivity({ activity: args.data, route: "company" });
      }
      if (args.requestType === "delete") {
        for (let index = 0; index < args.data.length; index++) {
          const element = args.data[index];
          await this.deleteActivities({
            activityIds: [element.id],
            route: "company",
          });
        }
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewsubgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
