<template lang="pug">
div(style="display:contents")
  ejs-multiselect(v-show="tagsLoaded" id='multiselecttag' v-model='tagsSelected' :itemTemplate="itemTemplate" :tagging='tagging' :dataSource='tagsOption' mode="Box" :fields='customFields' cssClass="mb-50 e-multiselect-inverse" :showDropDownIcon="true"  popupWidth="200" placeholder='Trier par tags' sortOrder="Ascending" filterType='Contains')
  v-style(v-for="(tag, tagIndex) of tagsOption" :key="tagIndex")
    |.e-chips.bg_{{tag.color.substring(1)}}{
    |    background-color: {{tag.color}} !important;
    |    border:1px solid {{tag.color}} !important;
    |}
    |.e-chips.bg_{{tag.color.substring(1)}}:hover{
    |        background-color: {{tag.color}}75 !important;
    |}
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    name: {
      default: "",
    },
    companyTypeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tagsLoaded: false,
      customFields: { text: "label", value: "id", color: "color" },
      itemTemplate:
        "<span id:'${id}' class='e-chips text-white' style='padding: 2px 6px 2px 8px; border-radius: 4px; background-color: ${color};'>${label}</span>",
    };
  },
  async beforeMount() {
    switch (this.name) {
      case "company":
        await this.getCompanyTags({});
        break;
      case "quote":
        await this.getQuoteTags({});
        break;
      case "orderFormCustomer":
        await this.getOrderFormCustomerTags({});
        break;
      case "invoice":
        await this.getInvoiceTags({});
        break;
      case "credit":
        await this.getCreditTags({});
        break;
      case "orderForm":
        await this.getOrderFormProviderTags({});
        break;
      case "deliveryForm":
        await this.getDeliveryFormTags({});
        break;
      case "receiptForm":
        await this.getReceiptFormProviderTags({});
        break;
      case "invoiceProvider":
        await this.getInvoiceProviderTags({});
        break;
      case "creditProvider":
        await this.getCreditProviderTags({});
        break;
      case "affair":
        await this.getAffairTags({});
        break;
    }
    this.tagsLoaded = true;
  },
  computed: {
    ...mapGetters([
      "isLoadingTagsList",
      "companyTagsList",
      "quoteTagsList",
      "orderFormCustomerTagsList",
      "invoiceTagsList",
      "creditTagsList",
      "orderFormProviderTagsList",
      "deliveryFormTagsList",
      "receiptFormProviderTagsList",
      "invoiceProviderTagsList",
      "creditProviderTagsList",
      "affairTagsList",
      "companyTagsListSelected",
      "quoteTagsListSelected",
      "orderFormCustomerTagsListSelected",
      "invoiceTagsListSelected",
      "creditTagsListSelected",
      "orderFormProviderTagsListSelected",
      "deliveryFormTagsListSelected",
      "receiptFormProviderTagsListSelected",
      "invoiceProviderTagsListSelected",
      "creditProviderTagsListSelected",
      "affairTagsListSelected",
    ]),
    tagsSelected: {
      get() {
        switch (this.name) {
          case "company":
            return this.companyTagsListSelected;
          case "quote":
            return this.quoteTagsListSelected;
          case "orderFormCustomer":
            return this.orderFormCustomerTagsListSelected;
          case "invoice":
            return this.invoiceTagsListSelected;
          case "credit":
            return this.creditTagsListSelected;
          case "orderForm":
            return this.orderFormProviderTagsListSelected;
          case "deliveryForm":
            return this.deliveryFormTagsListSelected;
          case "receiptForm":
            return this.receiptFormProviderTagsListSelected;
          case "invoiceProvider":
            return this.invoiceProviderTagsListSelected;
          case "creditProvider":
            return this.creditProviderTagsListSelected;
          case "affair":
            return this.affairTagsListSelected;
          default:
            return [];
        }
      },
      set(value) {
        this.$emit("setTagsSelected", value);
        switch (this.name) {
          case "company":
            this.$store.commit("SET_COMPANY_TAGS_LIST_SELECTED", value);
            break;
          case "quote":
            this.$store.commit("SET_QUOTE_TAGS_LIST_SELECTED", value);
            break;
          case "orderFormCustomer":
            this.$store.commit(
              "SET_ORDER_FORM_CUSTOMER_TAGS_LIST_SELECTED",
              value
            );
            break;
          case "invoice":
            this.$store.commit("SET_INVOICE_TAGS_LIST_SELECTED", value);
            break;
          case "credit":
            this.$store.commit("SET_CREDIT_TAGS_LIST_SELECTED", value);
            break;
          case "orderForm":
            this.$store.commit(
              "SET_ORDER_FORM_PROVIDER_TAGS_LIST_SELECTED",
              value
            );
            break;
          case "deliveryForm":
            this.$store.commit("SET_DELIVERY_FORM_TAGS_LIST_SELECTED", value);
            break;
          case "receiptForm":
            this.$store.commit(
              "SET_RECEIPT_FORM_PROVIDER_TAGS_LIST_SELECTED",
              value
            );
            break;
          case "invoiceProvider":
            this.$store.commit(
              "SET_INVOICE_PROVIDER_TAGS_LIST_SELECTED",
              value
            );
            break;
          case "creditProvider":
            this.$store.commit("SET_CREDIT_PROVIDER_TAGS_LIST_SELECTED", value);
            break;
          case "affair":
            this.$store.commit("SET_AFFAIR_TAGS_LIST_SELECTED", value);
            break;
          default:
            return [];
        }
      },
    },
    tagsOption() {
      switch (this.name) {
        case "company":
          return this.companyTypeId
            ? this.$store.getters.companyTagsList.filter(
                (el) => el.companyType.id == this.companyTypeId
              )
            : this.$store.getters.companyTagsList;
        case "quote":
          return this.$store.getters.quoteTagsList;
        case "orderFormCustomer":
          return this.$store.getters.orderFormCustomerTagsList;
        case "invoice":
          return this.$store.getters.invoiceTagsList;
        case "credit":
          return this.$store.getters.creditTagsList;
        case "orderFormProvider":
          return this.$store.getters.orderFormProviderTagsList;
        case "deliveryForm":
          return this.$store.getters.deliveryFormTagsList;
        case "receiptFormProvider":
          return this.$store.getters.receiptFormProviderTagsList;
        case "invoiceProvider":
          return this.$store.getters.invoiceProviderTagsList;
        case "creditProvider":
          return this.$store.getters.creditProviderTagsList;
        case "affair":
          return this.$store.getters.affairTagsList;
        default:
          return [];
      }
    },
  },
  methods: {
    ...mapActions([
      "getCompanyTags",
      "getQuoteTags",
      "getOrderFormCustomerTags",
      "getInvoiceTags",
      "getCreditTags",
      "getOrderFormProviderTags",
      "getDeliveryFormTags",
      "getReceiptFormProviderTags",
      "getInvoiceProviderTags",
      "getCreditProviderTags",
      "getAffairTags",
    ]),
    tagging: function (e) {
      var msObject = document.getElementById("multiselecttag").ej2_instances[0];
      e.setClass("bg_" + e.itemData.color.substring(1));
    },
  },
};
</script>

<style lang="scss">
input#multiselecttag.e-control.e-multiselect.e-lib {
  display: none;
}
.e-multiselect-inverse {
  position: absolute !important;
  margin-top: -33px;
  right: 12px;
  // box-shadow: none !important;
  &:active,
  .e-input-focus {
    border-color: none !important;
    box-shadow: none !important;
  }
  border: 0px !important;
  width: auto !important;
  align-items: center;
  background: transparent !important;
  .e-searcher {
    flex: 1;
    order: 1;
    .e-lib.e-grid .e-focused:not {
      box-shadow: none !important;
    }
  }
  .e-chips-collection {
    order: 2;
  }
  .e-input-group-icon {
    order: 3;
  }
  .e-multi-select-wrapper {
    display: flex;
    align-content: center;
    align-items: center;
    .e-chips {
      background-color: #6c757d;
      border: 1px solid #6c757d;
      .e-chipcontent {
        color: white;
        -webkit-text-fill-color: white;
      }
      .e-chips-close::before {
        color: white;
        -webkit-text-fill-color: white;
      }
      &:hover {
        background-color: #6c757d;
        .e-chipcontent {
          color: white;
          -webkit-text-fill-color: white;
        }
        .e-chips-close::before {
          color: white;
          -webkit-text-fill-color: white;
        }
      }
    }
  }
  .e-input-group-icon.e-ddl-icon {
    border-radius: 4px;
    max-height: 24px;
    min-height: 24px;
    &::before {
      content: "\e821" !important;
      font-family: "e-icons";
    }
  }
  .e-close-hooker {
    display: none !important;
  }
}
</style>
