<template>
  <span class="chip">
    <feather-icon
      v-if="data.direction == 0"
      icon="ArrowUpIcon"
      class="text-success"
      size="12"
    />
    <feather-icon
      v-else-if="data.direction == 1"
      class="text-danger"
      icon="ArrowDownIcon"
      size="12"
    />
    <span
      class="label"
      :class="data.direction == 0 ? 'text-success' : 'text-danger'"
      >{{ data.quantity }}</span
    >
  </span>
</template>
<script>
export default {
  data() {
    return {
      data: null
    };
  },
}
</script>
