<template>
  <span>
    <span class="label">{{ typeList[data.deliveryType].label }} </span>
  </span>
</template>
<script>
export default {
  data() {
    return {
      typeList: [ 
        { label: "Ordre de livraison" },
        { label: "Transfert entre dépôts" },
        { label: "Remise en main propre" },
      ],
      data: null,
    };
  },
};
</script>