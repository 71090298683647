<template lang="pug">
span(v-html="formatStripHtml(item)")
</template>

<script>
export default {
  data() {
    return {
      data: null,
    };
  },
  beforeMount() {
      this.item = this.data[this.data.column.field] && this.data[this.data.column.field]!==false ? this.data[this.data.column.field] : ""
  },
  methods:{
    formatStripHtml(str){
    //   return str ? str.replace(/\r?\n|\r/g, '<br>') : ''
      if ((str===null) || (str===''))
          return "";
      else
          str = str.toString();
      return str.replace( /(<([^>]+)>)/ig, '');
    }
  }
};
</script>