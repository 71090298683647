<template lang="pug">
div(style="display:contents")
  ejs-dropdownbutton( ref="dropdownbutton" cssClass="e-outline mr-1 class-taguer" @beforeOpen="beforeOpen" @beforeClose="beforeClose" :enablePersistence="false" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icon-bookmark" :content="'Taguer ('+items.length+')'" :isPrimary="true" target= "#tagslist")
  ejs-listview(ref='listview' id='tagslist' :dataSource='tagsOptionFiltered' :height=300 :select="selectActionTag" :showHeader="true" :headerTemplate="'tagslistTemplateHeader'"  :template='"tagslistTemplate"' :fields='customFields' :showCheckBox="true")
    template(v-slot:tagslistTemplate='{data}')
      .d-flex.align-items-center(style='width:100%;height:100%;') 
        span.mr-50(:class='data.color')  
        span.e-chips.text-white(:class="'bg_'+data.color.substring(1)" style="padding: 0 6px 0 8px; border-radius: 4px") {{ data.label }}
    template(v-slot:tagslistTemplateHeader)
      .headerContainer
        input(class="e-input" ref='textboxEle' v-model="searchSrc" @keyup="searchTag" type="text" placeholder="Recherche" )
        ejs-button(@click.native="openPopupTag" v-b-modal.modal-prevent-closing cssClass='e-small' content="Nouveau" isPrimary='true' iconCss="e-btn-sb-icons e-icon-plus" iconPosition="Right" )
  v-style(v-for="(tag, tagIndex) of tagsOption" :key="tagIndex")
    |.e-chips.bg_{{tag.color.substring(1)}}{
    |    background-color: {{tag.color}} !important;
    |    border:1px solid {{tag.color}} !important;
    |}
    |.e-chips.bg_{{tag.color.substring(1)}}:hover{
    |        background-color: {{tag.color}}75 !important;
    |}
  popup-tag(:archive="false" :nature="name" :origine="name" :companyTypeId="companyTypeId" ref="popupTagTagguer" @refreshGrid="addItemListView")   
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { DataManager, Query, ODataV4Adaptor } from "@syncfusion/ej2-data";

import PopupTag from "@/components/parameters/tags/PopupTag.vue";

export default {
  props: {
    items: {
      type: Array,
      default: false,
    },
    name: {
      default: "",
    },
    companyTypeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isOpenPopupTag: false,
      tagsLoaded: false,
      customFields: { text: "label", value: "id" },
      searchSrc: "",
      search: "",
      tagsSelected: [],
      tagsOption: [],
    };
  },
  watch: {
    items: function (newVal, oldVal) {
      this.preselectTags();
    },
  },
  async beforeMount() {
    switch (this.name) {
      case "company":
        await this.getCompanyTags({});
        break;
      case "quote":
        await this.getQuoteTags({});
        break;
      case "orderFormCustomer":
        await this.getOrderFormCustomerTags({});
        break;
      case "invoice":
        await this.getInvoiceTags({});
        break;
      case "credit":
        await this.getCreditTags({});
        break;
      case "orderForm":
        await this.getOrderFormProviderTags({});
        break;
      case "deliveryForm":
        await this.getDeliveryFormTags({});
        break;
      case "receiptForm":
        await this.getReceiptFormProviderTags({});
        break;
      case "invoiceProvider":
        await this.getInvoiceProviderTags({});
        break;
      case "creditProvider":
        await this.getCreditProviderTags({});
        break;
      case "affair":
        await this.getAffairTags({});
        break;
      case "collaborator":
        await this.getCollaboratorTags({});
        break;
      case "contact":
        await this.getContactTags({});
        break;
    }
    // this.setTagsOption();
    this.tagsLoaded = true;
  },
  computed: {
    ...mapGetters([
      "isLoadingTagsList",
      "contactTagsList",
      "companyTagsList",
      "quoteTagsList",
      "orderFormCustomerTagsList",
      "invoiceTagsList",
      "creditTagsList",
      "orderFormProviderTagsList",
      "deliveryFormTagsList",
      "receiptFormProviderTagsList",
      "invoiceProviderTagsList",
      "creditProviderTagsList",
      "affairTagsList",
      "companyTagsListSelected",
      "contactTagsListSelected",
      "quoteTagsListSelected",
      "invoiceTagsListSelected",
      "creditTagsListSelected",
      "orderFormProviderTagsListSelected",
      "deliveryFormTagsListSelected",
      "receiptFormProviderTagsListSelected",
      "invoiceProviderTagsListSelected",
      "creditProviderTagsListSelected",
      "affairTagsListSelected",
      "collaboratorTagsList",
      "collaboratorTagsListSelected",
    ]),
    tagsOptionFiltered() {
      if (this.name == "company") {
        return this.companyTypeId
          ? this.companyTagsList.filter(
              (el) => el.companyType.id == this.companyTypeId
            )
          : this.companyTagsList;
      } else if (this.name == "quote") {
        return this.quoteTagsList;
      } else if (this.name == "orderFormCustomer") {
        return this.orderFormCustomerTagsList;
      } else if (this.name == "invoice") {
        return this.invoiceTagsList;
      } else if (this.name == "credit") {
        return this.creditTagsList;
      } else if (this.name == "orderForm") {
        return this.orderFormProviderTagsList;
      } else if (this.name == "deliveryForm") {
        return this.deliveryFormTagsList;
      } else if (this.name == "deliveryFormProvider") {
        return this.deliveryFormProviderTagsList;
      } else if (this.name == "receiptForm") {
        return this.receiptFormProviderTagsList;
      } else if (this.name == "invoiceProvider") {
        return this.invoiceProviderTagsList;
      } else if (this.name == "creditProvider") {
        return this.creditProviderTagsList;
      } else if (this.name == "affair") {
        return this.affairTagsList;
      } else if (this.name == "collaborator") {
        return this.collaboratorTagsList;
      } else if (this.name == "contact") {
        return this.contactTagsList;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions([
      "getContactTags",
      "getCompanyTags",
      "getQuoteTags",
      "getOrderFormCustomerTags",
      "getInvoiceTags",
      "getCreditTags",
      "getOrderFormProviderTags",
      "getDeliveryFormTags",
      "getReceiptFormProviderTags",
      "getInvoiceProviderTags",
      "getCreditProviderTags",
      "getAffairTags",
      "getCollaboratorTags",
      "updateCompanyTagCompany",
      "updateQuoteTagQuote",
      "updateInvoiceTagInvoice",
      "updateCreditTagCredit",
      "updateOrderFormProviderTagOrderFormProvider",
      "updateDeliveryFormTagDeliveryForm",
      "updateReceiptFormProviderTagReceiptFormProvider",
      "updateInvoiceProviderTagInvoiceProvider",
      "updateCreditProviderTagCreditProvider",
      "updateAffairTagAffair",
      "updateCollaboratorTagCollaborator",
      "updateContactTagContact",
      "updateOrderFormCustomerTagOrderFormCustomer",
    ]),
    setTagsOption() {
      if (this.name == "company") {
        return this.companyTypeId
          ? this.companyTagsList.filter(
              (el) => el.companyType.id == this.companyTypeId
            )
          : this.companyTagsList;
      } else if (this.name == "quote") {
        return this.quoteTagsList;
      } else if (this.name == "orderFormCustomer") {
        return this.orderFormCustomerTagsList;
      } else if (this.name == "invoice") {
        return this.invoiceTagsList;
      } else if (this.name == "credit") {
        return this.creditTagsList;
      } else if (this.name == "orderForm") {
        return this.orderFormProviderTagsList;
      } else if (this.name == "deliveryForm") {
        return this.deliveryFormTagsList;
      } else if (this.name == "deliveryFormProvider") {
        return this.deliveryFormProviderTagsList;
      } else if (this.name == "receiptForm") {
        return this.receiptFormProviderTagsList;
      } else if (this.name == "invoiceProvider") {
        return this.invoiceProviderTagsList;
      } else if (this.name == "creditProvider") {
        return this.creditProviderTagsList;
      } else if (this.name == "affair") {
        return this.affairTagsList;
      } else if (this.name == "collaborator") {
        return this.collaboratorTagsList;
      } else if (this.name == "contact") {
        return this.contactTagsList;
      } else {
        return [];
      }
    },
    preselectTags() {
      if (this.$refs.listview.dataSource.length > 0)
        this.$refs.listview.uncheckAllItems();
      let tags = [];
      this.items.forEach((el) => {
        el[this.name + "Tags"].forEach((tag) => {
          if (
            tags.length == 0 ||
            (tags.length > 0 && !tags.find((el) => el.id === tag.id))
          ) {
            tags.push(tag);
          }
        });
      });
      if (tags.length > 0) {
        this.$refs.listview.selectMultipleItems(tags);
      }
    },

    searchTag(event) {
      // this.search = JSON.parse(JSON.stringify(this.searchSrc))
      // let keyupvalue = this.$refs.textboxEle.value;
      // let data = new DataManager(this.tagsOptionFiltered).executeLocal(new Query().where("text", "startswith", keyupvalue, true));
      // if (!keyupvalue) {
      //   this.$refs.listview.dataSource = this.tagsOptionFiltered.slice();
      // } else {
      //   this.$refs.listview.dataSource = data;
      // }
    },
    addItemListView(res) {
      this.isOpenPopupTag = false;
      if (res) {
        this.$refs.listview.addItem([res]);
      }
    },
    beforeOpen(args) {
      this.preselectTags();
    },
    beforeClose(args) {
      if (args.event.pointerId || this.isOpenPopupTag) {
        args.cancel = true;
        return false;
      } else {
        args.cancel = false;
        return true;
      }
    },
    async selectActionTag(args) {
      if (args.isInteracted) {
        const allArrayTag = [];

        this.items.forEach((item) => {
          const tags = item[this.name + "Tags"];
          const tagIds = item[this.name + "Tags"].map((elem) => elem.id);
          if (args.isChecked) {
            tags.push(args.data);
            tagIds.push(args.data.id);
          } else {
            let index = tags.findIndex((elem) => elem.id === args.data.id);
            if (index > -1) {
              tags.splice(
                tags.findIndex((elem) => elem.id === args.data.id),
                1
              );
              tagIds.splice(
                tagIds.findIndex((elem) => elem === args.data.id),
                1
              );
            }
          }

          const existingArray = allArrayTag.findIndex(
            (item) => JSON.stringify(item.tagIds) === JSON.stringify(tagIds)
          );

          if (existingArray < 0) {
            allArrayTag.push({ tagIds: tagIds, itemIds: [item.id] });
          } else {
            allArrayTag[existingArray].itemIds.push(item.id);
          }
          item[this.name + "Tags"] = tags;
          this.$emit("refreshRow", item);
        });
        for (let i = 0; i < allArrayTag.length; i++) {
          const tagIds = allArrayTag[i].tagIds;
          const itemIds = allArrayTag[i].itemIds;
          if (this.name == "quote") {
            await this.updateQuoteTagQuote({
              quoteIds: itemIds,
              quoteTagIds: tagIds,
            });
          } else if (this.name == "orderFormCustomer") {
            await this.updateOrderFormCustomerTagOrderFormCustomer({
              orderFormCustomerIds: itemIds,
              orderFormCustomerTagIds: tagIds,
            });
          } else if (this.name == "invoice") {
            await this.updateInvoiceTagInvoice({
              invoiceIds: itemIds,
              invoiceTagIds: tagIds,
            });
          } else if (this.name == "credit") {
            await this.updateCreditTagCredit({
              creditIds: itemIds,
              creditTagIds: tagIds,
            });
          } else if (this.name == "orderForm") {
            await this.updateOrderFormProviderTagOrderFormProvider({
              orderFormProviderIds: itemIds,
              orderFormProviderTagIds: tagIds,
            });
          } else if (this.name == "deliveryForm") {
            await this.updateDeliveryFormTagDeliveryForm({
              deliveryFormIds: itemIds,
              deliveryFormTagIds: tagIds,
            });
          } else if (this.name == "receiptForm") {
            await this.updateReceiptFormProviderTagReceiptFormProvider({
              receiptFormProviderIds: itemIds,
              receiptFormProviderTagIds: tagIds,
            });
          } else if (this.name == "invoiceProvider") {
            await this.updateInvoiceProviderTagInvoiceProvider({
              invoiceProviderIds: itemIds,
              invoiceProviderTagIds: tagIds,
            });
          } else if (this.name == "creditProvider") {
            await this.updateCreditProviderTagCreditProvider({
              creditProviderIds: itemIds,
              creditProviderTagIds: tagIds,
            });
          } else if (this.name == "affair") {
            await this.updateAffairTagAffair({
              affairIds: itemIds,
              affairTagIds: tagIds,
            });
          } else if (this.name == "company") {
            await this.updateCompanyTagCompany({
              companyIds: itemIds,
              companyTagIds: tagIds,
            });
          } else if (this.name == "collaborator") {
            await this.updateCollaboratorTagCollaborator({
              collaboratorIds: itemIds,
              collaboratorTagIds: tagIds,
            });
          } else if (this.name == "contact") {
            await this.updateContactTagContact({
              contactIds: itemIds,
              contactTagIds: tagIds,
            });
          }
        }
      }
    },
    openPopupTag(id = 0) {
      this.isOpenPopupTag = true;
      this.$bvModal.show("modal-tag-" + this.name);
      this.$refs["popupTagTagguer"].popupTagEvent(id, this.name);
    },
  },
  components: {
    PopupTag,
  },
};
</script>

<style lang="scss">
.e-icon-bookmark:before {
  content: "\e750";
  font-family: e-icons;
}
.class-taguer {
  display: flex;
  align-items: center;
  &:not(:empty) {
    white-space: nowrap;
    width: fit-content;
  }
}
</style>
