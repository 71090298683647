<template lang="pug">
div(class="d-flex align-items-center justify-content-end h-100 w-100")
    pre {{ data }}
</template>

<script>
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
    data () {
      return {
        data: null,
        field: null
      }
     },
     methods:{
        formatCurrency,
     }
};
</script>