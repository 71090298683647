<template lang="pug">
div {{ item }}
</template>
<script>
export default {
    data() {
        return {
            item: null,
            data: null,
        };
    },
    beforeMount() {
        this.item = this.data.receiptFormStep && this.data.documentReference ? this.data.documentReference + " #" + this.data.receiptFormStep : this.data.documentReference; 
    },
};
</script>
<style lang="">
    
</style>