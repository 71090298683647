<template lang="pug">
div(class="d-flex align-items-center justify-content-start h-100 w-100")
    | {{ item }}
</template>

<script>
import { civilityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  beforeMount() {
    if (this.data.isParticular == false) {
      this.item = "Société";
    } else {
      if (this.data.civility) {
        this.item = civilityTranslate(this.data.civility);
      } else {
        this.item = civilityTranslate(this.data[this.data.column.field]);
      }
    }
  },
  methods: {
    civilityTranslate,
  },
};
</script>
