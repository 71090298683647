<template lang="pug">
  div(class="d-flex align-items-center justify-content-start h-100 w-100")
      | {{ item }}
  </template>

<script>
import { Log } from "oidc-client-ts";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  computed: {
    ...mapGetters(["collaboratorsList"]),
  },
  beforeMount() {
    if (this.data[this.data.column.field]) {
      if (this.data.column.field === "collaboratorIds") {
        this.item = this.data.collaboratorIds
          .map(
            (el) =>
              this.collaboratorsList.find((collab) => collab.id == el)
                .displayLabel
          )
          .join(", ");
      } else {
        this.item = this.collaboratorsList
          .filter((el) => this.data[this.data.column.field].includes(el.id))
          .map((el) => el.displayLabel)
          .join(", ");
      }
    } else {
      this.item = "--";
    }
  },
};
</script>
