<template lang="pug">
  div {{item}}
  </template>
<script>
export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  created() {
    this.item = this.data.parentId ? "Oui" : "Non";
  },
};
</script>
