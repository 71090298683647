<template lang="pug">
  div(class="d-flex align-items-center justify-content-start h-100 w-100")
    | {{ item }}
</template>

<script>
import { natureShortTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  methods: {
    natureShortTranslate,
  },
  beforeMount() {
    this.item =
      this.natureShortTranslate(this.data[this.data.column.field]) +
      (this.data.nature == 2 || this.data.nature == 3 || this.data.nature == 4
        ? this.data.documentStep
          ? ` #${this.data.documentStep}`
          : ""
        : "");
  },
};
</script>
