<template lang="pug">
span(style="line-height:26px") {{ data.Sum % 1 !== 0 ? formatCurrency(data.Sum.toFixed(2)) : formatCurrency(data.Sum) }}
</template>
  
<script>
  import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

  export default {
    data() {
      return {
        data: null,
      };
    },
    methods: {
      formatCurrency,
    },
  };
</script>