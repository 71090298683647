<template lang="pug">
div(class="d-flex align-items-center justify-content-start h-100 w-100")
    | {{ item }}
</template>

<script>
import { productTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts"

export default {
    data() {
        return {
            item: null,
            data: null,
        };
    },
    beforeMount() {
        this.item = productTypeTranslate(this.data[this.data.column.field])
    },
    methods: {
        productTypeTranslate,
    }
};
</script>