<template lang="pug">
div(class="d-flex align-items-center justify-content-start h-100 w-100")
  | {{ item.label }}
</template>

<script>
import { EContractStatus } from "@/types/api-orisis/enums/enums";
export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  beforeMount() {
    this.item = this.$store.getters.modulesList?.find(
      (el) => el.id === this.data.moduleId
    );
  },
};
</script>
