<template lang="pug">
div(class="d-flex align-items-center justify-content-end h-100 w-100")
    | {{ data }}
</template>

<script>
export default {
  data() {
    return {
      data: null,
      field: null,
    };
  },
  created() {
    this.data =
      this.data.discountGlobalNumber > 0
        ? this.data.discountGlobalType == 0
          ? "Pourcentage"
          : "Montant"
        : "";
  },
};
</script>
