<template lang="pug">
span {{ item.label }}
</template>
<script>
import { typeFields  } from "@/types/api-orisis/enums/enums";

export default {
    data() {
        return {
            item: null,
            data: null
        };
    },
    beforeMount() {
        this.item = typeFields.find((elem) => elem.value == this.data.typeField);
    }
}
</script>
