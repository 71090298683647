<template lang="pug">
div
  ejs-daterangepicker#daterangepicker(@change='setDates' @select="select" :strictMode="strictMode" :min="min" :max="max" locale='fr' @open="stopNavBar" @close="stopNavBar" :openOnFocus="true" :value='dateRangeDefault' :firstDayOfWeek='1' :fullScreenMode='true' :placeholder="placeholder" format='dd/MM/yyyy' width='100%' style="minWidth:220px" :maxDays="maxDays ? maxDays : null")
    e-presets
      e-preset(label="Aujourd'hui" v-if="showTodayButton" :start='today' :end='today')
      e-preset(label='Cette semaine' :start='weekStart' :end='weekEnd')
      e-preset(label='Ce mois-ci' :start='monthStart' :end='monthEnd')
      e-preset(label='Mois dernier' :start='lastStart' :end='lastEnd')
      e-preset(label='Cette année' v-if="showThisYearButton" :start='yearStart' :end='yearEnd')
      e-preset(label='Année dernière' v-if="showLastYearButton" :start='lastYearStart' :end='lastYearEnd')
      e-preset(label='Depuis le début' v-if="showFromStartButton" :start='beginningStart' :end='today')
      e-preset(label='Période fiscale en cours' v-if="showFiscalYearButton" :start='fiscalYearDate.startDate' :end='fiscalYearDate.endDate')
</template>

<script>
import { timeSpans } from "@/types/api-orisis/enums/enums";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      weekStart: new Date(new Date(dayjs().startOf("week")).toDateString()),
      weekEnd: new Date(new Date(dayjs().endOf("week")).toDateString()),
      monthStart: new Date(new Date(new Date().setDate(1)).toDateString()),
      monthEnd: new Date(
        new Date(
          new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)
        ).toDateString()
      ),
      lastStart: new Date(
        new Date(
          new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
        ).toDateString()
      ),
      lastEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
      yearStart: new Date(
        new Date(new Date().getFullYear(), 0, 1).toDateString()
      ),
      yearEnd: new Date(
        new Date(new Date().getFullYear(), 11, 31).toDateString()
      ),
      lastYearStart: new Date(
        new Date(new Date().getFullYear() - 1, 0, 1).toDateString()
      ),
      lastYearEnd: new Date(
        new Date(new Date().getFullYear() - 1, 11, 31).toDateString()
      ),
      beginningStart: new Date(new Date("2010-01-01").toDateString()),
      today: new Date(new Date(dayjs().startOf("day")).toDateString()),
    };
  },
  computed: {
    ...mapGetters(["userParametersList"]),
    fiscalYearDate() {
      let fiscalYearDateInProgress = this.$store.getters[
        "fiscalYearList"
      ]?.find(
        (fiscalYearDate) =>
          new Date(fiscalYearDate.endDate) > new Date() &&
          new Date(fiscalYearDate.startDate) < new Date()
      );

      return {
        startDate: new Date(fiscalYearDateInProgress.startDate),
        endDate: new Date(fiscalYearDateInProgress.endDate),
      };
    },
  },
  props: {
    strictMode: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Date,
      default: null,
    },
    max: {
      type: Date,
      default: null,
    },
    showTodayButton: {
      type: Boolean,
      default: true,
    },
    showThisYearButton: {
      type: Boolean,
      default: true,
    },
    showLastYearButton: {
      type: Boolean,
      default: true,
    },
    showFromStartButton: {
      type: Boolean,
      default: true,
    },
    showFiscalYearButton: {
      type: Boolean,
      default: true,
    },
    dateRangeDefault: {
      type: Array,
      default: () => [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
    },
    userFilter: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    maxDays: {
      type: Number,
      default: null,
    },
  },
  methods: {
    ...mapActions(["setFilter"]),
    select(args) {
      this.$emit("select", args);
    },
    setDates(e) {
      if (e.event !== null) {
        let selectedLabel = e.event?.target.outerText
          ? e.event?.target.outerText
          : e.event?.target.outerHTML == '<span class="e-apply"></span>'
          ? "Appliquer"
          : null;
        if (selectedLabel === "Appliquer") {
          selectedLabel = "Période personnalisée";
        }
        let dateRangeType = timeSpans.find(
          (x) => x.label === selectedLabel
        )?.value;

        let dateRange = [];
        switch (dateRangeType) {
          case 1:
            dateRange = {
              startDate: this.weekStart,
              endDate: this.weekEnd,
            };
            break;
          case 2:
            dateRange = { startDate: this.monthStart, endDate: this.monthEnd };
            break;
          case 3:
            dateRange = { startDate: this.lastStart, endDate: this.lastEnd };
            break;
          case 4:
            dateRange = { startDate: this.yearStart, endDate: this.yearEnd };
            break;
          case 5:
            dateRange = {
              startDate: this.lastYearStart,
              endDate: this.lastYearEnd,
            };
            break;
          case 6:
            dateRange = {
              startDate: this.beginningStart,
              endDate: null,
            };
            break;
          case 7:
            dateRange = {
              startDate: e.startDate,
              endDate: e.endDate,
            };
            break;
          case 8:
            dateRange = {
              startDate: this.today,
              endDate: this.today,
            };
            break;
          case 9:
            dateRange = {
              startDate: this.fiscalYearDate.startDate,
              endDate: this.fiscalYearDate.endDate,
            };
            break;
          default:
            dateRange = { startDate: this.yearStart, endDate: this.yearEnd };
            break;
        }

        if (this.userFilter) {
          this.setFilter({
            name: this.userFilter,
            filter: {
              dateRangeType: dateRangeType,
              dateRange: dateRange,
            },
          }).then((res) => {
            let endDate = dateRange.endDate
              ? new Date(new Date(dateRange.endDate).setHours(23, 59, 59))
              : new Date();
            endDate.toISOString();
            e = {
              ...e,
              startDate: dateRange.startDate,
              endDate,
              dateRangeType,
            };
            this.$emit("setDates", e);
          });
        } else {
          let endDate = new Date(
            new Date(dateRange.endDate).setHours(23, 59, 59)
          );
          endDate.toISOString();
          e = {
            ...e,
            startDate: dateRange.startDate,
            endDate,
            dateRangeType,
          };
          this.$emit("setDates", e);
        }
      }
    },
    stopNavBar(e) {
      let navbar = document.querySelector(".navbar");
      if (e.name === "open") {
        navbar.style.pointerEvents = "none";
      } else if (e.name === "close") {
        setTimeout(() => {
          navbar.style.pointerEvents = "auto";
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss">
.disabled {
  pointer-events: none;
}
#daterangepicker_popup {
  position: absolute;

  .e-presets {
    max-height: 1000px;
  }
}
.e-lib.e-daterangepicker .e-active {
  background-color: rgb(12, 53, 113) !important;
  color: #fff !important;
}
.e-range-mob-popup-wrap .e-model-header .e-model-title {
  display: none;
}
.e-range-mob-popup-wrap .e-model-header:after {
  content: "Sélectionner";
}
</style>
