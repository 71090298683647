<template lang="pug">
div(class="d-flex align-items-center justify-content-start h-100 w-100" style="font-weight:500; text-transform: capitalize;")
    | {{ item }}
</template>
<script>
var dayjs = require('dayjs')
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var advanced = require('dayjs/plugin/advancedFormat')
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Paris')
dayjs.extend(advanced)
dayjs.extend(quarterOfYear)

export default {
    data() {
        return {
            data: null,
            item: null
        };
    },
    beforeMount() {
        this.item = this.data[this.data.column.field] && this.data[this.data.column.field]!==false ? dayjs(this.data[this.data.column.field]).format("dddd D MMMM YYYY") : ""
    },
};
</script>