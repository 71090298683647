<template lang="pug">
.d-flex.align-items-center
    .e-chips.text-white(v-for="(tag, tagIndex) of item" :key="tagIndex" :class="'bg_'+tag.color.substring(1)" style="padding: 0 4px; line-height:16px; border-radius: 4px; margin-right:5px") {{ tag.label }}
</template>

<script>
export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  beforeMount() {
    this.item = this.data[this.data.column.field]
  },
};
</script>