<template lang="pug">
div(class="d-flex align-items-center justify-content-start h-100 w-100" style="text-transform: capitalize;")
  | {{ item }}
</template>

<script>
import { mapGetters } from "vuex";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      data: null,
      item: null,
    };
  },
  beforeMount() {
    let collaboratorId = this.data["collaboratorId"];
    let collaborator = this.collaboratorsList.find(
      (collaborator) => collaborator.id == collaboratorId
    );
    this.item =
      collaboratorId && collaborator?.reportEntryMode == 0
        ? "--"
        : this.data[this.data.column.field] &&
          this.data[this.data.column.field] !== false
        ? dayjs(this.data[this.data.column.field]).format("HH:mm")
        : "--";
  },
  computed: {
    ...mapGetters(["collaboratorsList"]),
  },
};
</script>
