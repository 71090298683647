<template>
  <div class="CellRendererRepartitions">
    {{ formatCurrency(this.total) }}
  </div>
</template>

<script>
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      affairId: null,
      data: null,
      total: 0,
    };
  },
  computed: {
    ...mapGetters(["lease"]),
  },
  beforeMount() {
    let array = [];
    this.affairId =
      this.$route.name == "lease" ? this.lease.affairId : this.$route.params.id;
    if (this.data.column.nature == "creditProvider") {
      array = this.data.creditProviderRepartitions;
    } else if (this.data.column.nature == "invoiceProvider") {
      array = this.data.invoiceProviderRepartitions;
    }
    const filteredRepartitions = array.filter((repartition) => {
      return repartition.affairId.toString() === this.affairId.toString();
    });

    // Additionner les priceHt des repartitions filtrées
    this.total = filteredRepartitions.reduce((acc, current) => {
      return acc + current.priceHt;
    }, 0);
  },
  methods: {
    formatCurrency,
  },
};
</script>

<style scoped>
.custom-cell {
  text-align: center;
}
</style>
