<template lang="pug">
div(class="d-flex align-items-center justify-content-start h-100 w-100")
    | {{ item }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  computed: {
    ...mapGetters(["payrollEventsList"]),
  },
  beforeMount() {
    if (this.data[this.data.column.field]) {
      this.item = this.payrollEventsList.find(
        (el) => el.id == this.data[this.data.column.field]
      )?.label;
    } else {
      this.item = "";
    }
  },
};
</script>
