<template lang="pug">
  .d-flex.align-items-center(v-if="data.remainingToBillHt !== null")
    //- | {{ formatPercent(percent) }}
    progress.mb-0.style2(:value="percent" max="100" :style="'--value: '+percent+'; --max: 100;'")
</template>

<script>
import { formatPercent } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  data() {
    return {
      item: null,
      percent: 0,
      data: null,
    };
  },
  methods: {
    formatPercent,
  },
  beforeMount() {
    this.percent =
      this.data.totalHt == 0
        ? 0
        : (
            (1 -
              (this.data.totalHt -
                (this.data.totalHt - this.data.remainingToBillHt)) /
                this.data.totalHt) *
            100
          ).toFixed(0);
  },
};
</script>
