<template lang="pug">
span(style="line-height:26px") {{ item }}
</template>
<script>
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
    data() {
        return {
            data: null,
        };
    },
    methods: {
        formatCurrency,
    },
    beforeMount() {
    this.item = this.formatCurrency(Math.round((this.data['totalTtc'] - this.data['totalHt']) * 100) / 100);
  },
};    
</script>