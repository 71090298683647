<template lang="pug">
  div.h-100
    .h-100(v-if='isLoadingProduct|| isUpdatingProduct || isDeletingProduct')
      .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        div(style='margin-top: 7rem;')
          | Chargement des détails du produit...
    .h-100(v-else='')
      .sidebar-header.py-1.px-2
        div
          h3 {{productTypeTranslate(product.productType)}}
        feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
      vs-divider.m-0
      .sidebar-action.py-05.px-2(v-if="!toInventory")
        .d-flex.align-items-center
          b-button.btn-icon(v-if='archived' variant='warning' @click='restoreProductLocal(product.id)')
            feather-icon(icon='ArrowDownIcon')
            | Restaurer
          b-button.btn-icon(v-else='' variant='primary' @click='editProduct(product.id)' :disabled='archived')
            feather-icon(icon='EditIcon')
            | Modifier
        b-button-group
          b-button.btn-icon(v-if='archived' variant='outline-danger' @click='deleteProductLocal(product)')
            feather-icon(icon='TrashIcon')
            | Supprimer d&eacute;finitivement
          b-button.btn-icon(title="Archiver" v-else='' variant='outline-danger' @click='archiveProductLocal(product.id)')
            feather-icon(icon='ArchiveIcon')
      vs-divider.m-0
      b-row.content-scrollable-sticky.m-0.justify-content-center
        b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
          ul.d-flex.border-bottom-grey-light.px-1
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'sales' ? 'current' : ''" @click="tabActive = 'sales'")
                span Vente
            li(v-if="haveProductProviders")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'purchases' ? 'current' : ''" @click="tabActive = 'purchases'")
                span Achats
            li(v-if="checkFunctionUser(34, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'stocks' ? 'current' : ''" @click="tabActive = 'stocks'")
                span Stock
      .sidebar-content
        div(v-if='tabActive == "sales"')
          .d-flex.mb-1
            img.thumbnail(v-if="product.urlImage" :src="product.urlImage")
            div.d-flex.flex-column
              span.w-100.font-weight-bold {{ product.label }}
              span.w-100 {{ formatHTMLToString(product.description) }}  
          ul.listTab
            li(v-if="product.reference")
              span Référence
              span {{ product.reference }}
            li(v-if="product.saleCategory")
              span Catégorie de vente
              span {{ product.saleCategory.label }}
            li(v-if="product.productCategories")
              span Catégorie d'article
              span {{ computedProductCategories }}
            li(v-if="product.unit")
              span Unité
              span {{ product.unit.label }}
            li(v-if="product.unit")
              span Quantité par défaut
              span {{ product.quantity }}
            li(v-if="product.priceHT && !product.useDegressiveSaleRate")
              span Prix Vente U. HT
              span {{ formatCurrency(product.priceHT) }}
          div(v-if='product.useDegressiveSaleRate')
            h5.my-1.text-primary.d-flex.justify-content-start
              span(style='min-width: min-content;')
                feather-icon(icon='ChevronRightIcon')
                | Tarifs dégressifs
            ul.arrayTab
              li
                span Quantité
                span PA. U HT
                span Remise
              li(v-for='(item, index) in product.degressiveSalesRates')
                span
                  | Entre {{ item.quantityMin }} {{ item.quantityMax ? &apos;et &apos; + item.quantityMax : &apos;ou plus&apos; }}
                span {{ formatCurrency(item.price) }}
                span {{ formatPercent(item.discount) }}
        div(v-if='tabActive == "purchases"')
          vs-divider.m-0
          div(v-for="(productProviderItem, productProviderIndex) in product.productProviders")
            h4.my-1.text-primary.d-flex.justify-content-start
              span(style='min-width: min-content;')
                feather-icon(icon='ChevronRightIcon')
                | {{ product.productProviders.length > 0 ? 'Fournisseur ' + (productProviderIndex + 1) + ' :' : '' }} {{ productProviderItem.company.name }}
            ul.arrayTab(v-if="!productProviderItem.useDegressivePurchaseRate")
              li
                span Référence
                span PA. U HT
                span Remise
              li
                span {{ productProviderItem.reference }}
                span {{ formatCurrency(productProviderItem.netPrice) }}
                span {{ formatPercent(productProviderItem.discount) }}
            div(v-else)
              ul.arrayTab
                li
                  span Référence
                  span Quantité
                  span PA. U HT
                  span Remise
                li(v-for='(item, index) in productProviderItem.degressivePurchaseRates')
                  span {{ productProviderItem.reference }}
                  span
                    | Entre {{ item.quantityMin }} {{ item.quantityMax ? &apos;et &apos; + item.quantityMax : &apos;ou plus&apos; }}
                  span {{ formatCurrency(item.price) }}
                  span {{ formatPercent(item.discount) }}
        div(v-if='tabActive == "stocks"')
          ul.listTab
            li(v-for="(stock, index) in product.stocks" :key="index")
              span {{ stock.storageLocation ? stock.storageLocation.label : '#N/A' }}
              span.text-right {{ stock.quantity }}
              

</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import { ProductDefaultProperties } from "@/types/api-orisis/models/ProductModel";
import {
  formatCurrency,
  formatPercent,
  formatHTMLToString,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { productTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  data() {
    return {
      ProductDefaultProperties,
      archived: false,
      tabActive: "sales",
      toInventory: false,
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingProduct",
      "isLoadingProduct",
      "isDeletingProduct",
    ]),
    computedDryDisbursed() {
      return formatCurrency(this.product.dryDisbursed);
    },
    computedPriceHt() {
      return formatCurrency(this.product.priceHT);
    },
    computedGrossMarginHt() {
      return formatCurrency(this.product.grossMarginHT);
    },
    computedMarginRate() {
      return formatPercent(this.product.marginRate);
    },
    computedProductCategories() {
      return this.product.productCategories
        .map((item) => item.label)
        .join(", ");
    },
    haveProductProviders() {
      return (
        this.product.productProviders?.length > 0 &&
        this.checkFunctionUser(74, "all")
      );
    },
    product: {
      get() {
        return this.$store.getters.product;
      },
      set(val) {
        this.$store.commit("SET_PRODUCT", val);
      },
    },
  },
  methods: {
    ...mapActions([
      "deleteProducts",
      "isArchivingProduct",
      "archiveProducts",
      "getProductById",
      "restoreProducts",
      "getProductArchivedById",
    ]),
    checkFunctionUser,
    formatCurrency,
    formatPercent,
    productTypeTranslate,
    formatHTMLToString,
    getProductDetails(id, toInventory = this.toInventory) {
      this.toInventory = toInventory;
      this.tabActive = "sales";
      this.archived = false;
      if (id != "") {
        this.getProductById({
          productId: id,
        }).then((res) => {
          this.product = res;
        });
      } else {
        this.product = this.ProductDefaultProperties({});
      }
    },
    async editProduct(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-product",
        params: {
          id: id,
          title: "Edit produit",
          tips: "Editer le produit",
          routeOrigine: "catalog",
        },
      });
      // this.$router.push({ name: 'edit-product', params: { id: id, title: "Edit : "+ this.product.description, tips:"Editer le produit : "+this.product.description, routeOrigine:'catalog' } })
    },
    getProductArchivedDetails(id, archived, toInventory = this.toInventory) {
      this.archived = archived;
      this.toInventory = toInventory;
      if (id > 0) {
        this.getProductArchivedById({
          productId: id,
        }).then((res) => {
          this.product = res;
        });
      } else {
        this.product = this.ProductDefaultProperties({});
      }
    },
    async editProduct(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-product",
        params: {
          id: id,
          title: "Edit produit",
          tips: "Editer le produit",
          routeOrigine: "catalog",
        },
      });
      // this.$router.push({ name: 'edit-product', params: { id: id, title: "Edit : "+ this.product.description, tips:"Editer le produit : "+this.product.description, routeOrigine:'catalog' } })
    },
    archiveProductLocal(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce produit en consultant la liste de vos archives.",
          {
            title: "Êtes-vous sûr de vouloir archiver le produit ?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveProducts({
              productIds: [id],
            }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    restoreProductLocal(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title: "Êtes-vous sûr de vouloir restaurer le produit ?",
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreProducts({
              productIds: [id],
            }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    deleteProductLocal(product) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir supprimer le produit ?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteProducts({ productIds: [product.id] }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style scoped>
.content-line-sidebar-product {
  display: flex;
  justify-content: space-between;
}
</style>
