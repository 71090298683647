<template lang="pug">
  .d-flex.flex-column.m-0
    .d-flex.align-items-center.content-header.w-100.p-1
      .d-flex.title-wrapper.align-items-center.mb-0.mb-md-0.justify-content-between.justify-content-md-start
        h2.content-header-title.m-0.pr-1.mr-md-1(style="white-space: nowrap; border-right: 1px solid #d6dce1;")
          | {{ title ? title : $route.meta.pageTitle }}
          span.ml-50(v-show="archive" class="text-danger") (archives)
          span.ml-50(v-show="template" class="text-primary") (modèles)
        .content-header-search-filter.d-flex.mr-0.mr-md-1.justify-content-center.align-items-center(v-if="switchIsValidation")
          switch-button.mb-0(:width="85" v-model="isValidationComputed" :sync="true" :value="isValidationComputed" @change="saveIsValidation" :labels="{checked: 'Validation', unchecked: 'Edition'}")
      .content-header-search-filter.mr-1(v-if="dateRanger && dateRangerTemplate" v-show='(isValidationComputed && schedulerModeComputed == "list" && !isMobile) || !schedulerModeButton')
        .position-relative.w-100.d-flex.align-items-center.date-ranger-desktop
          DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" :userFilter="userFilter" placeholder="Filtrer par période")
          span(:class="isSigned ? 'text-primary' : 'text-grey'" v-if="switchIsSigned" title="Rechercher par date de signature").material-icons-outlined
            | draw
          ejs-switch(@change="$emit('isSigned', isSigned)" v-model="isSigned" :checked="isSigned" v-if="switchIsSigned")
      .content-header-search-filter.mr-1.w-100(v-if="filterCollaborators")
          ejs-multiselect(name="Collaborateurs" cssClass="e-fixe-height" :dataSource='collaboratorsList' :closePopupOnSelect="false" :fields="{text:'displayLabel',value:'id'}" placeholder='Filtrer par collaborateur' v-model="collaboratorIds" sortOrder="Ascending" :allowFiltering="true" filterType='Contains' required="" @select="setCollaborators" @removed="removeCollaborator")
      .content-header-search-filter.mr-1.w-100(v-if="filterAffairs" v-show="(isValidationComputed || schedulerModeComputed == 'global' || schedulerModeComputed == 'details') || !schedulerModeButton")
          ejs-multiselect(name="Affaires" cssClass="e-fixe-height" :dataSource='affairsList' :closePopupOnSelect="false" :fields="{text:'displayLabel',value:'id'}" placeholder='Filtrer par affaire' v-model="affairIds" sortOrder="Ascending" :allowFiltering="true" filterType='Contains' required="" @select="setAffairs" @removed="removeAffair")
      .content-header-search.w-100.d-none.d-md-flex.mt-1.mt-md-0(v-if="searchButton")
        .e-input-group.e-float-icon-left.mr-1
          span.e-input-group-icon.e-icon-search
          .e-input-in-wrap
            input.e-input(@input="onFilterTextBoxChanged" type='text' :placeholder='searchPlaceholder' v-model='search')
      .content-header-actions.d-flex.d-md-flex.justify-content-end
        Tagguer(v-if="selectedRows.length && tagguerButton" :items="selectedRows" :name="name" @refreshRow="refreshRow" :companyTypeId="companyTypeId")
        div(v-if="selectedRows.length && groupActionButton && groupActionList && groupActionList.length > 0")
          .e-btn-group.e-lib.e-css.mr-1
            ejs-button(v-for="(groupButton, groupButtonIndex) of groupActionList" :key="groupButtonIndex" :cssClass="groupButton.css" v-on:click.native="clickActionGroup(groupButton.value)" :content="groupButton.text" :isPrimary="true" :iconCss="groupButton.iconCss" iconPosition="Right")
        div(v-if="tvaModeButton || mapModeButton")
          .e-btn-group.e-lib.e-css(role='group')
            input#tvaModeHT(v-if="tvaModeButton" type='radio' name='align' value="HT" v-model="tvaModeComputed")
            label.e-btn.e-outline(v-if="tvaModeButton" for='tvaModeHT') HT
            input#tvaModeTTC(v-if="tvaModeButton" type='radio' name='align' value="TTC" v-model="tvaModeComputed")
            label.e-btn.e-outline.mr-1(v-if="tvaModeButton" for='tvaModeTTC') TTC
            input#mapMode(v-if="mapModeButton" type='radio' name='align' :value="true" v-model="mapModeComputed")
            label.e-btn.e-outline(v-if="mapModeButton" for='mapMode') Map
            input#listMode(v-if="mapModeButton" type='radio' name='align' :value="false" v-model="mapModeComputed")
            label.e-btn.e-outline.mr-1(v-if="mapModeButton" for='listMode') Liste
        .d-block.d-lg-none(v-if="selectedRows.length && groupActionButton && groupActionList && !isMobile")
          ejs-dropdownbutton(:cssClass="groupActionButton ? 'e-outline mr-1' : 'e-outline'" :items="groupActionList" iconCss="e-icons e-drpdwnbtn-icons e-btn-sb-icons e-send" :isPrimary="true" @select="onDropdownActionSelect")
        ejs-button(v-else-if="selectedRows.length && groupActionButton" :cssClass="groupActionButtonSecondary ? 'e-outline mr-0 border-right-0' : addButton ? 'e-outline mr-1' : 'e-outline'" :content="groupActionButtonLabel + ' ('+selectedRows.length+')'" v-on:click.native="clickActionGroup" :isPrimary="true" :style="groupActionButtonSecondary ? 'border-radius: 0.35rem 0 0 0.35rem;' : ''")
        .d-block.d-lg-none(v-if="selectedRows.length && groupActionButtonSecondary && groupActionList && !isMobile")
          ejs-dropdownbutton(:cssClass="groupActionButtonSecondary ? 'e-outline mr-1' : 'e-outline'" :items="groupActionList" iconCss="e-icons e-drpdwnbtn-icons e-btn-sb-icons e-send" :isPrimary="true" @select="onDropdownActionSelect")
        ejs-button(v-else-if="selectedRows.length && groupActionButtonSecondary" cssClass="e-outline mr-1" :content="groupActionButtonSecondaryLabel + ' ('+selectedRows.length+')'" v-on:click.native="clickActionGroupSecondary" :isPrimary="true" :style="groupActionButtonSecondary ? 'border-radius: 0 0.35rem 0.35rem 0;' : ''")
        ejs-dropdownbutton(v-if="optionButton" :cssClass="addButton ? 'e-outline mr-1 row-btn' : 'e-outline row-btn mr-1'" :select="selectParamerterAction" :items="parametersActionList" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icon-field-settings" :isPrimary="true" )
        ejs-dropdownbutton(ref="dropdownbuttonPlanningParameterAction" v-if="planningOptionButton" :cssClass="addButton ? 'e-outline mr-1 row-btn' : 'e-outline row-btn mr-1'" :select="selectParamerterAction" :items="planningParametersActionList" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icon-field-settings" :isPrimary="true")
        div.w-100(v-if="schedulerModeButton")
          .d-flex.e-btn-group.e-lib.e-css(role='group')
            input#globalMode( type='radio' name='align' value="global" @click="saveReportView('global')" v-model="schedulerModeComputed")
            label.d-flex.align-items-center.e-btn.e-outline.flex.w-100( for='globalMode') 
              span.material-icons-outlined.mr-50(style="font-size: 1.2rem;") calendar_month
              | Global
            input#detailsMode( type='radio' name='align' value="details" @click="saveReportView('details')" v-model="schedulerModeComputed")
            label.d-flex.align-items-center.e-btn.e-outline.flex.w-100( for='detailsMode') 
              span.material-icons-outlined.mr-50(style="font-size: 1.2rem;") calendar_month
              | Détails
            input#listMode( type='radio' name='align' value="list" @click="saveReportView('list')" v-model="schedulerModeComputed")
            label.d-flex.align-items-center.e-btn.e-outline.flex.w-100( for='listMode') 
              span.material-icons-outlined.mr-50(style="font-size: 1.2rem;") view_list
              | Vue liste
        div.w-100(v-if="viewModeButton")
          .e-btn-group.e-lib.e-css(role='group') 
            input#viewCounted(type='radio' name='viewMode' value="counted" v-model="viewModeComputed")
            label.e-btn.e-outline(for='viewCounted') Réél
            input#viewCompare(type='radio' name='viewMode' value="compare" v-model="viewModeComputed")
            label.e-btn.e-outline.border-primary(for='viewCompare') Réél vs Prévu
            input#viewForecast(type='radio' name='viewMode' value="forecast" v-model="viewModeComputed")
            label.e-btn.e-outline(for='viewForecast') Prévu
        div(v-if="addButton && addButton2")
          .e-btn-group.e-lib.e-css.d-md-none
            ejs-button(v-if="addButton" v-on:click.native="clickAction" v-b-modal.modal-prevent-closing :content="''" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }"  :iconCss="addButtonIcon" iconPosition="Right")
            ejs-button(v-if="addButton2" v-on:click.native="clickAction2" v-b-modal.modal-prevent-closing :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="addButton2Icon" iconPosition="Right")
          .e-btn-group.e-lib.e-css.d-none.d-md-block(v-if="addButton && addButton2")
            ejs-button(v-if="addButton" v-on:click.native="clickAction" v-b-modal.modal-prevent-closing :content="addButtonLabel" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }"  :iconCss="addButtonIcon" iconPosition="Right")
            ejs-button(v-if="addButton2" v-on:click.native="clickAction2" v-b-modal.modal-prevent-closing :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="addButton2Icon" iconPosition="Right")
        div(v-else-if="addButton")
          ejs-button(v-on:click.native="clickAction" cssClass="d-none d-md-block" v-b-modal.modal-prevent-closing :content="addButtonLabel" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="addButtonIcon" iconPosition="Right")
          ejs-button(v-on:click.native="clickAction" cssClass="d-md-none" v-b-modal.modal-prevent-closing :content="''" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="addButtonIcon" iconPosition="Right")
    .d-flex.content-header.w-100
      .content-header-search-mobile(v-if="dateRanger && dateRangerTemplate")
        .position-relative.w-100.d-flex.align-items-center.px-1
          DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" :userFilter="userFilter" placeholder="Filtrer par période")
      .position-relative.w-100.d-flex.d-md-none.align-items-center.my-1.px-1(v-if="$route.name != 'my-reports' &&  $route.name != 'reports-by-collaborators'")
        .e-input-group.e-float-icon-left
            span.e-input-group-icon.e-icon-search
            .e-input-in-wrap.w-100
              input.e-input(@input="onFilterTextBoxChanged" type='text' :placeholder='searchPlaceholder' v-model='search')
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import Tagguer from "@/components/global/tags/Tagguer";
import SwitchButton from "@/components/form/Switch";
import DateRanger from "@/components/global/date-ranger/DateRanger.vue";
import { SwitchComponent } from "@syncfusion/ej2-vue-buttons";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      showAllowances: true,
      showDurations: true,
      isSchedulerGlobal: false,
      isSigned: false,
      collaboratorIds: [],
      affairIds: [],
      search: null,
      dateRangerTemplate: true,
      parametersActionList: [
        {
          text: "Exporter en CSV",
          iconCss: "e-btn-sb-icons e-icon-export-csv",
          type: "csv",
        },
        {
          text: "Exporter en Excel",
          iconCss: "e-btn-sb-icons e-icon-export-xls",
          type: "xls",
        },
        {
          text: "Imprimer",
          iconCss: "e-btn-sb-icons e-icon-export-print",
          type: "print",
        },
        {
          text: "Réinitialiser les colonnes",
          iconCss: "e-btn-sb-icons e-icons e-reset",
          type: "resetGrid",
        },
      ],
      planningParametersActionList: [
        {
          text: "Masquer les totaux d'indémnités",
          iconCss: "e-btn-sb-icons e-icon-columns",
          type: "allowances",
        },
        {
          text: "Masquer les totaux d'heures",
          iconCss: "e-btn-sb-icons e-icon-columns",
          type: "durations",
        },
      ],
    };
  },
  props: {
    name: String,
    dateRangeDefault: {
      type: Array,
      default: () => [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
    },
    title: {
      type: String,
      default: null,
    },
    tvaMode: {
      type: String,
      default: "HT",
    },
    tvaModeButton: {
      type: Boolean,
      default: false,
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    dateRanger: {
      type: Boolean,
      default: false,
    },
    filterCollaborators: {
      type: Boolean,
      default: false,
    },
    filterAffairs: {
      type: Boolean,
      default: false,
    },
    addButton: {
      type: Boolean,
      default: false,
    },
    addButton2: {
      type: Boolean,
      default: false,
    },
    addButtonIcon: {
      type: String,
      default: "e-btn-sb-icons e-icon-plus",
    },
    addButton2Icon: {
      type: String,
      default: "e-btn-sb-icons e-icon-search",
    },
    searchButton: {
      type: Boolean,
      default: false,
    },
    tagguerButton: {
      type: Boolean,
      default: false,
    },
    archiveMode: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
    templateMode: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Boolean,
      default: false,
    },
    homepageMode: {
      type: Boolean,
      default: false,
    },
    homepage: {
      type: Boolean,
      default: false,
    },
    reportModeButton: {
      type: Boolean,
      default: false,
    },
    reportMode: {
      type: String,
      default: "validation",
    },
    schedulerModeButton: {
      type: Boolean,
      default: false,
    },
    schedulerMode: {
      type: String,
      default: "list",
    },
    schedulerModeDetails: {
      type: Boolean,
      default: false,
    },
    mapModeButton: {
      type: Boolean,
      default: false,
    },
    mapMode: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: "Rechercher",
    },
    companyTypeId: {
      type: Number,
      default: null,
    },
    optionButton: {
      type: Boolean,
      default: true,
    },
    planningOptionButton: {
      type: Boolean,
      default: false,
    },
    groupActionButton: {
      type: Boolean,
      default: false,
    },
    groupActionButtonLabel: {
      type: String,
      default: "",
    },
    groupActionButtonSecondary: {
      type: Boolean,
      default: false,
    },
    groupActionButtonSecondaryLabel: {
      type: String,
      default: "",
    },
    groupActionList: {
      type: Array,
      default: null,
    },
    addButtonLabel: {
      type: String,
      default: "Nouveau",
    },
    switchIsSigned: {
      type: Boolean,
      default: false,
    },
    switchIsValidation: {
      type: Boolean,
      default: false,
    },
    isValidation: {
      type: Boolean,
      default: false,
    },
    userFilter: {
      type: String,
      default: null,
    },
    indexSearch: {
      type: Number,
    },
    uid: {
      type: String,
      default: null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    viewModeButton: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: String,
      default: "compare",
    },
  },
  computed: {
    ...mapGetters(["affairsList", "collaboratorsList", "userParametersList"]),
    tvaModeComputed: {
      get() {
        return this.tvaMode;
      },
      set(value) {
        this.$emit("updateTvaMode", value);
      },
    },
    reportModeComputed: {
      get() {
        return this.reportMode;
      },
      set(value) {
        this.$emit("updateReportMode", value);
      },
    },
    viewModeComputed: {
      get() {
        return this.viewMode;
      },
      set(value) {
        this.$emit("updateViewMode", value);
      },
    },
    schedulerModeComputed: {
      get() {
        return this.schedulerMode;
      },
      set(value) {
        this.$emit("updateSchedulerMode", value);
      },
    },
    isValidationComputed: {
      get() {
        return this.isValidation;
      },
      set(value) {
        this.$emit("updateIsValidation", value);
      },
    },
    mapModeComputed: {
      get() {
        return this.mapMode;
      },
      set(value) {
        this.$emit("updateMapMode", value);
      },
    },
  },
  watch: {
    archive: function () {
      this.selectParamerterActionArchive();
    },
    template: function () {
      this.selectParamerterActionTemplate();
    },
    homepage: function () {
      this.selectParamerterActionHomepage();
    },
    indexSearch: function (val) {
      if (this.search) this.onFilterTextBoxChanged();
    },
  },
  created() {
    this.selectParamerterActionArchive();
    this.selectParamerterActionTemplate();
    this.selectParamerterActionHomepage();
  },
  methods: {
    ...mapActions([
      "createOrUpdateUserParameter",
      "deleteUserParameter",
      "getUserParameterByKey",
    ]),
    saveReportView(view) {
      this.createOrUpdateUserParameter({
        label: "Vue de reporting",
        key:
          this.$route.name == "my-reports"
            ? "myReportingMode"
            : "reportingMode",
        value: view,
      });
    },
    saveIsValidation(args) {
      this.createOrUpdateUserParameter({
        label: "Validation de reporting",
        key:
          this.$route.name == "my-reports"
            ? "myReportingIsValidation"
            : "reportingIsValidation",
        value: args.value,
      });
    },
    selectParamerterActionTemplate() {
      if (this.templateMode && !this.template) {
        if (this.parametersActionList.find((elem) => elem.type == "current")) {
          this.parametersActionList.splice(
            this.parametersActionList.findIndex(
              (elem) => elem.type == "current"
            ),
            1
          );
        }
        this.parametersActionList.push({
          text: "Voir les modèles de document",
          iconCss: "e-btn-sb-icons e-icons e-thumbnail",
          type: "template",
        });
        this.dateRangerTemplate = true;
      } else if (this.templateMode && this.template) {
        if (this.parametersActionList.find((elem) => elem.type == "template")) {
          this.parametersActionList.splice(
            this.parametersActionList.findIndex(
              (elem) => elem.type == "template"
            ),
            1
          );
        }
        this.parametersActionList.push({
          text: "Voir les tous les documents",
          iconCss: "e-btn-sb-icons e-icons e-thumbnail",
          type: "current",
        });
        this.dateRangerTemplate = false;
      }
    },
    selectParamerterActionHomepage() {
      if (this.homepageMode && !this.homepage) {
        if (this.parametersActionList.find((elem) => elem.type == "current")) {
          this.parametersActionList.splice(
            this.parametersActionList.findIndex(
              (elem) => elem.type == "current"
            ),
            1
          );
        }
        this.parametersActionList.push({
          text: "Enregistrer comme page d'ouverture",
          iconCss: "e-btn-sb-icons e-icon-home",
          type: "homePage",
        });
        if (
          this.$route.name != "my-reports" &&
          this.$route.name != "reports-by-collaborators"
        ) {
          this.parametersActionList.unshift({
            text: "Gérer les colonnes",
            iconCss: "e-btn-sb-icons e-icon-columns",
            type: "columns",
          });
        }
      }
    },
    onDropdownActionSelect(args) {
      const actionValue = args.item.value;
      this.clickActionGroup(actionValue);
    },
    selectParamerterActionArchive() {
      if (this.archiveMode && !this.archive) {
        if (this.parametersActionList.find((elem) => elem.type == "current")) {
          this.parametersActionList.splice(
            this.parametersActionList.findIndex(
              (elem) => elem.type == "current"
            ),
            1
          );
        }
        this.parametersActionList.push({
          text: "Voir les données archivées",
          iconCss: "e-btn-sb-icons e-icon-columns",
          type: "archive",
        });
      } else if (this.archiveMode && this.archive) {
        if (this.parametersActionList.find((elem) => elem.type == "archive")) {
          this.parametersActionList.splice(
            this.parametersActionList.findIndex(
              (elem) => elem.type == "archive"
            ),
            1
          );
        }
        this.parametersActionList.push({
          text: "Voir les données actives",
          iconCss: "e-btn-sb-icons e-icon-columns",
          type: "current",
        });
      }
    },
    setAffairs(e) {
      const selectedId = e.itemData.id;

      if (!this.affairIds.includes(selectedId)) {
        this.affairIds.push(selectedId);
      }
      this.$emit("setAffairs", this.affairIds);
    },
    removeAffair(e) {
      const deselectedId = e.itemData.id;

      this.affairIds = this.affairIds.filter((id) => id !== deselectedId);

      this.$emit("setAffairs", this.affairIds);
    },

    setCollaborators(e) {
      const selectedId = e.itemData.id;

      if (!this.collaboratorIds.includes(selectedId)) {
        this.collaboratorIds.push(selectedId);
      }
      this.$emit("setCollaborators", this.collaboratorIds);
    },
    removeCollaborator(e) {
      const deselectedId = e.itemData.id;

      this.collaboratorIds = this.collaboratorIds.filter(
        (id) => id !== deselectedId
      );

      this.$emit("setCollaborators", this.collaboratorIds);
    },
    setDates(e) {
      if (e.event !== null) {
        this.$emit("setDates", e);
      }
    },
    selectGroupAction(args) {
      this.$emit("selectGroupAction", args);
    },
    selectParamerterAction(args) {
      if (args.item.type == "homePage") {
        this.createOrUpdateUserParameter({
          label: "Page d'accueil",
          key: "homePage",
          value: this.$route.path,
        });
      } else if (args.item.type == "resetGrid") {
        // Réinitialisation du tableau
        this.getUserParameterByKey({ key: this.uid }).then(
          (currentUserParameter) => {
            if (currentUserParameter) {
              this.deleteUserParameter({
                ids: [currentUserParameter.id],
              }).then(() => {
                // Forcer le rechargement de la grid en incrementant la clé
                this.$store.commit("appConfig/INCREMENT_GRID_KEY");
              });
            }
          }
        );
      } else {
        if (args.item.type == "allowances") {
          let newItem = {
            text: !this.showAllowances
              ? "Masquer les totaux d'indémnités"
              : "Afficher les totaux d'indémnités",
            iconCss: "e-btn-sb-icons e-icon-columns",
            type: "allowances",
          };
          this.showAllowances = !this.showAllowances;
          this.$refs.dropdownbuttonPlanningParameterAction.addItems(
            [newItem],
            args.item.text
          );
          this.$refs.dropdownbuttonPlanningParameterAction.removeItems([
            args.item.text,
          ]);
        } else if (args.item.type == "durations") {
          let newItem = {
            text: !this.showDurations
              ? "Masquer les totaux d'heures"
              : "Afficher les totaux d'heures",
            iconCss: "e-btn-sb-icons e-icon-columns",
            type: "durations",
          };
          this.showDurations = !this.showDurations;
          this.$refs.dropdownbuttonPlanningParameterAction.addItems(
            [newItem],
            args.item.text
          );
          this.$refs.dropdownbuttonPlanningParameterAction.removeItems([
            args.item.text,
          ]);
        }
        this.$emit("selectParamerterAction", args);
      }
    },
    refreshRow(val) {
      this.$emit("refreshRow", val);
    },
    onFilterTextBoxChanged() {
      this.$emit("onFilterTextBoxChanged", this.search);
    },
    clickAction() {
      this.$emit("clickAction");
    },
    clickAction2() {
      this.$emit("clickAction2");
    },
    clickActionGroup(val) {
      this.$emit("clickActionGroup", val);
    },
    clickActionGroupSecondary(val) {
      this.$emit("clickActionGroupSecondary", val);
    },
  },
  components: {
    SwitchButton,
    Tagguer,
    DateRanger,
    "ejs-switch": SwitchComponent,
  },
};
</script>
<style lang="scss" scoped>
.title-wrapper {
  width: fit-content;
}
@media (max-width: 580px) {
  .date-ranger-desktop {
    display: none !important;
  }
  .content-header {
    flex-direction: column;
  }
  .content-header > * {
    margin-right: 0 !important;
  }
  .content-header-actions {
    margin-top: 1rem;
    width: 100%;
  }
  .title-wrapper {
    width: 100%;

    h2 {
      font-size: 1.5rem;
      width: 100%;
      border-right: none !important;
      margin-left: 0 !important;
    }
  }
}
.row-btn {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
}
</style>
<style>
.switchWidth {
  width: 80px;
}
</style>
