<template lang="pug">
  div(class="d-flex align-items-center justify-content-start h-100 w-100")
    | {{ item }}
  </template>

<script>
import { EContractNature } from "@/types/api-orisis/enums/enums";
export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  beforeMount() {
    this.item = EContractNature[this.data[this.data.column.field]];
  },
};
</script>
