export default [
    {
      key: "id",
      fr: "Id",
    },
    {
      key: "createAt",
      fr: "Créé le",
    },
    {
      key: "updateAt",
      fr: "Modifié le",
    },
    {
      key: "affairId",
      fr: "Id de l'affaire",
    },
    {
      key: "address",
      fr: "Adresse",
    },
    {
      key: "addressComplement",
      fr: "Complément d'adresse",
    },
    {
      key: "zipCode",
      fr: "Code postal",
    },
    {
      key: "city",
      fr: "Ville",
    },
    {
      key: "country",
      fr: "Pays",
    },
    {
      key: "latitude",
      fr: "Latitude",
    },
    {
      key: "longitude",
      fr: "Longitude",
    },
    {
      key: "affairName",
      fr: "Nom de l'affaire",
    },
    {
      key: "affairAddress",
      fr: "Adresse de l'affaire",
    },
    {
      key: "affairAddressComplement",
      fr: "Suite adresse de l'affaire",
    },
    {
      key: "affairCity",
      fr: "Ville de l'affaire",
    },
    {
      key: "affairZipCode",
      fr: "Code postal de l'affaire",
    },
    {
      key: "affairCountry",
      fr: "Pays de l'affaire",
    },
    {
      key: "ownAddressAffair",
      fr: "Adresse différente",
    },
    {
      key: "affairType",
      fr: "Type d'affaire",
    },
    {
      key: "parentAffairId",
      fr: "Id de parent de l'affaire",
    },
    {
      key: "affairCategoryId",
      fr: "Id de la catégorie l'affaire",
    },
    {
      key: "documentDate",
      fr: "Date",
    },
    {
      key: "discountGlobalNumber",
      fr: "Remise globale",
    },
    {
      key: "discountGlobalType",
      fr: "Type de remise",
    },
    {
      key: "nature",
      fr: "Nature",
    },
    {
      key: "description",
      fr: "Description",
    },
    {
      key: "path",
      fr: "Chemin du fichier lié",
      notUse: true,
    },
    {
      key: "dateGenerated",
      fr: "Date de génération",
    },
    {
      key: "comments",
      fr: "Commentaire",
    },
    {
      key: "userId",
      fr: "Id de l'utilisateur",
    },
    {
      key: "userLastName",
      fr: "Nom de l'utilisateur",
    },
    {
      key: "userFirstName",
      fr: "Prénom de l'utilisateur",
    },
    {
      key: "userPhoneNumber",
      fr: "Téléphone de l'utilisateur",
    },
    {
      key: "userSecondaryPhoneNumber",
      fr: "Autre téléphone de l'utilisateur",
    },
    {
      key: "userMail",
      fr: "Adresse email de l'utilisateur",
    },
    {
      key: "status",
      fr: "Statut",
    },
    {
      key: "companyId",
      fr: "Id de la société",
    },
    {
      key: "companyName",
      fr: "Client",
    },
    {
      key: "companyAddress",
      fr: "Adresse du client",
    },
    {
      key: "companyAddressComplement",
      fr: "Suite de l'adresse du client",
    },
    {
      key: "companyZipCode",
      fr: "Code postal du client",
    },
    {
      key: "companyCity",
      fr: "Ville du client",
    },
    {
      key: "companyCountry",
      fr: "Pays du client",
    },
    {
      key: "companyPhoneNumber",
      fr: "Téléphone du client",
    },
    {
      key: "companyMail",
      fr: "Adresse email du client",
    },
    {
      key: "footer",
      fr: "Pieds de page du document",
      notUse: true,
    },
    {
      key: "footerType",
      fr: "Type de pied de page",
      notUse: true,
    },
    {
      key: "totalHt",
      fr: "Total HT",
      format: "formatCurrency"
    },
    {
      key: "totalTtc",
      fr: "Total TTC",
      format: "formatCurrency"
    },
    {
      key: "marginBrutHt",
      fr: "Marge Brut HT",
      format: "formatCurrency"
    },
    {
      key: "marginRate",
      fr: "Taux de marge",
      format: "P2"
    },
    {
      key: "quoteBTP",
      fr: "Détail BTP",
      notUse: true,
    },
    {
      key: "collaboratorId",
      fr: "Id du collaborateur associé",
      notUse: true,
    },
    {
      key: "quoteCEE",
      fr: "Détails CEE",
      notUse: true,
    },
    {
      key: "institutionId",
      fr: "Id de la société",
      notUse: true,
    },
    {
      key: "institutionName",
      fr: "Société",
    },
    {
      key: "institutionLegalForm",
      fr: "Forme juridique de la société",
    },
    {
      key: "institutionAddress",
      fr: "Adresse de la société",
    },
    {
      key: "institutionAddressComplement",
      fr: "Suite adresse de la société",
    },
    {
      key: "institutionZipCode",
      fr: "Code postal de la société",
    },
    {
      key: "institutionCity",
      fr: "Ville de la société",
    },
    {
      key: "institutionCountry",
      fr: "Pays de la société",
    },
    {
      key: "institutionPhoneNumber",
      fr: "Téléphone de la société",
    },
    {
      key: "institutionSecondaryPhoneNumber",
      fr: "Autre téléphone de la société",
    },
    {
      key: "institutionEmail",
      fr: "Adresse email de la société",
    },
    {
      key: "institutionWebSite",
      fr: "Site web de la société",
    },
    {
      key: "institutionRegistrationNumber",
      fr: "RCS de la société",
    },
    {
      key: "institutionIsSubjectTVA",
      fr: "Société asujetit à la TVE",
    },
    {
      key: "institutionTvaNumber",
      fr: "Numéro de TVA de la société",
    },
    {
      key: "institutionSiret",
      fr: "Numéro de SIRET de la société",
    },
    {
      key: "institutionRcsCity",
      fr: "Ville RCS de la société",
    },
    {
      key: "institutionCapital",
      fr: "Capital social de la société",
    },
    {
      key: "institutionApeCode",
      fr: "Code APE de la société",
    },
    {
      key: "institutionGuaranteeType",
      fr: "Type de garantie de l'assurance",
    },
    {
      key: "institutionInsuranceName",
      fr: "Nom de l'assurance de la société",
    },
    {
      key: "institutionInsuranceCoverage",
      fr: "Couverture de l'assurance",
    },
    {
      key: "institutionInsuranceAddress",
      fr: "Adresse de l'assurance",
    },
    {
      key: "institutionInsuranceAddressComplement",
      fr: "Suite de l'adresse de l'assurance",
    },
    {
      key: "institutionInsuranceZipCode",
      fr: "Code postal de l'assurance",
    },
    {
      key: "institutionInsuranceCity",
      fr: "Ville de l'assurance",
    },
    {
      key: "institutionInsuranceCountry",
      fr: "Pays de l'assurance",
    },
    {
      key: "institutionLogo",
      fr: "URL du logo de la société",
      notUse: true,
    },
    {
      key: "institutionColor",
      fr: "Couleur de la société",
    },
    {
      key: "institutionSecondaryColor",
      fr: "Autre couleur de la société",
    },
    {
      key: "isShowSiret",
      fr: "Afficher le numéro de Siret",
    },
    {
      key: "isCalculateMargin",
      fr: "Afficher le calcule des marges",
    },
    {
      key: "isReverseCharge",
      fr: "Autoliquidation",
    },
    {
      key: "isHideUnitColumn",
      fr: "Masquer la colonne unité",
      notUse: true,
    },
    {
      key: "isHideQuantityColumn",
      fr: "Cacher la colonne quantité",
      notUse: true,
    },
    {
      key: "isShowReferenceColumn",
      fr: "Afficher la colonne référence",
      notUse: true,
    },
    {
      key: "isHideReferencielTvaColumn",
      fr: "Masquer la colonne taux de tva",
      notUse: true,
    },
    {
      key: "isShowPhoneNumberCompany",
      fr: "Afficher numéro de téléphone du client",
    },
    {
      key: "isShowMailCompany",
      fr: "Afficher l'email du client",
      notUse: true,
    },
    {
      key: "isShowTvaCompany",
      fr: "Afficher Numéro TVA du client",
      notUse: true,
    },
    {
      key: "isShowSiretCompany",
      fr: "Afficher le Siret du client",
    },
    {
      key: "isHideUser",
      fr: "Cacher l'utilisateur",
      notUse: true,
    },
    {
      key: "isShowWaterMark",
      fr: "Afficher le filigrane",
      notUse: true,
    },
    {
      key: "isDiscountLine",
      fr: "Remise par ligne",
      notUse: true,
    },
    {
      key: "isDiscountGlobal",
      fr: "Remise globale",
      notUse: true,
    },
    {
      key: "isShowDescription",
      fr: "Afficher la description",
      notUse: true,
    },
    {
      key: "hideAffair",
      fr: "Masquer l'affaire",
      notUse: true,
    },
    {
      key: "isCounted",
      fr: true,
      notUse: true,
    },
    {
      key: "countedDate",
      fr: "Date de comptabilisation",
    },
    {
      key: "remainingToPayTtc",
      fr: "Restant dù TTC",
      format: "formatCurrency"
    },
    {
      key: "source",
      fr: "Source d'origine",
      notUse: true,
    },
    {
      key: "usedUrlGed",
      fr: "URL de la Ged associée",
      notUse: true,
    },
    {
      key: "hideInstitutionLogo",
      fr: "Masquer le logo de la société",
    },
    {
      key: "hideInstitutionName",
      fr: "Masquer le nom de la société",
    },
    {
      key: "hideInstitutionAddress",
      fr: "Masquer l'adresse de la société",
      notUse: true,
    },
    {
      key: "affairCode",
      fr: "Code de l'affaire",
    },
    {
      key: "showThumbnail",
      fr: "Afficher les miniatures",
      notUse: true,
    },
    {
      key: "showProductPicturesAttached",
      fr: "Afficher les photos de produit par défaut",
      notUse: true,
    },
    {
      key: "invoiceId",
      fr: "Id de la facture liée",
      notUse: true,
    },
    {
      key: "amount",
      fr: "Montant",
      format: "formatCurrency"
    },
    {
      key: "internalNote",
      fr: "Notes interne",
    },
    {
      key: "folderId",
      fr: "Id du dossier",
    },
    {
      key: "name",
      fr: "Nom",
    },
    {
      key: "code",
      fr: "Code",
    },
    {
      key: "status",
      fr: "Status",
    },
    {
      key: "startDate",
      fr: "Date de début",
    },
    {
      key: "endDate",
      fr: "Date de fin",
    },
    {
      key: "grossMarginHT",
      fr: "Marge brute HT",
      format: "formatCurrency"
    },
    {
      key: "dryDisbursed",
      fr: "Déboursé sec",
      format: "formatCurrency"
    },
    {
      key: "alertQuantityMin",
      fr: "Quantité min d'alerte",
    },
    {
      key: "alertQuantityMin",
      fr: "Quantité min d'alerte",
    },
    {
      key: "brand",
      fr: "Marque",
    },
    {
      key: "annualSales",
      fr: "Chiffre d'affaire annuel",
    },
    {
      key: "annualSalesYear",
      fr: "Année du chiffre d'affaire",
    },
    {
      key: "ecoContribution",
      fr: "Eco contribution",
      format: "formatCurrency"
    },
    {
      key: "apeCode",
      fr: "Code APE",
    },
    {
      key: "companyAccount",
      fr: "Compte auxiliaire",
    },
    {
      key: "rcsCode",
      fr: "Code RCS",
    },
    {
      key: "siret",
      fr: "Siret",
    },
    {
      key: "tvaNumber",
      fr: "Numéro de TVA intracommunautaire",
    },
    {
      key: "zone",
      fr: "Zone",
    },
    {
      key: "createdAt",
      fr: "Créé le",
    },
    {
      key: "updatedAt",
      fr: "Mise à jour le",
    },
    {
      key: "hoursByDayDefault",
      fr: "Nombre d'heure par jour",
    },
    {
      key: "analyticsSettingId",
      fr: "Id du paramétre analytique",
    },
    {
      key: "affairCategory",
      fr: "Catégorie de l'affaire",
    },
    {
      key: "color",
      fr: "Couleur",
    },
    {
      key: "affairTags",
      fr: "Tags de l'affaire",
    },
    {
      key: "customFields",
      fr: "Champs personnalisés",
    },
    {
      key: "customFieldTemplate",
      fr: "Template des champs personnalisé",
    },
    {
      key: "customPerimeters",
      fr: "Périmètres personnalisés",
    },
    {
      key: "caCollaboratorId",
      fr: "Id collaborateur CA",
    },
    // {
    //   key: "collaboratorIds",
    //   fr: "Id des collaborateurs associés",
    // },
    // {
    //   key: "collaborators",
    //   fr: "Collaborateurs associés",
    // },
    {
      key: "collaboratorIds",
      fr: "Collaborateurs associés",
    },
  ];
  