<template lang="pug">
ejs-dropdownlist(:dataSource='dataExcelTemplate' :fields="{ text: 'displayLabel', value: 'id' }" mode="Delimiter" v-model="collaboratorsSelected" placeholder="Sélectionner...")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      data: null,
      collaboratorsSelected: [],
    };
  },
  created() {
    this.getCollaborators({});
  },
  computed: {
    ...mapGetters(["collaboratorsList", "companiesList"]),
    dataExcelTemplate() {
      return this.collaboratorsList.filter((collab) =>
        this.companiesList
          .map((data) => {
            return data.collaboratorId;
          })
          .includes(collab.id)
      );
    },
  },
  methods: {
    ...mapActions(["getCollaborators"]),
  },
};
</script>
