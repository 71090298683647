<template lang="pug">
ejs-grid(:recordClick='onRowClicked' :key="globalGridKey" :resizeStop='resizeStop' :allowTextWrap='allowTextWrap' :commandClick="commandClick" :toolbar="toolbar" :enableStickyHeader="true" :id="gridId" :allowRowDragAndDrop="allowRowDragAndDrop" :rowDropSettings="dropOptions" :rowDrop="rowDrop" :frozenColumns='frozenColumns' :groupSettings="groupSettings" :allowKeyboard="allowKeyboard" :editSettings='editSettings' :actionComplete='actionComplete' :beforeDataBound="gridBeforeDataBound" :dataBound="dataBound" :clipMode="'EllipsisWithTooltip'" :showHider="'show'" :rowDeselected="rowSelectedGrid" :rowSelected="rowSelectedGrid"  locale='fr' ref='overviewgrid' :allowReordering='true' :allowPaging='allowPaging' :allowGrouping='allowGrouping' :pageSettings='pageOptions' :dataSource='gridData' :selectionSettings='selectionSettings' :filterSettings='filterOptions' :allowFiltering='true' :allowSelection='allowSelection' :allowSorting='true' height='100%' width='100%' rowHeight='38' :allowPdfExport='true' :allowResizing='true' :allowExcelExport='true' :enableHeaderFocus='true' :loadingIndicator='loadingIndicator' :showColumnChooser='true'  :actionBegin='actionBegin' :load='gridLoad' :created='gridCreated' :detailTemplate='applyTemplate(detailTemplate)' :sortSettings='sortOptions' :queryCellInfo='queryCellInfoEvent' :toolbarClick='toolbarClick' :rowDataBound="rowDataBound")
  FilterTags(v-if="tagguerButton && !isCreatingTag && !isUpdatingTag" :name="name" @setTagsSelected="setTagsSelected" :companyTypeId="companyTypeId")
  e-columns
    e-column(v-for="(item, index) in headerData" :key="index"
      :type='item.type ? item.type : "string"'
      :field="item.field"
      :headerText="item.headerText"
      :width="item.width"
      :maxWidth="item.maxWidth"
      :minWidth="item.minWidth"
      :allowFiltering="item.allowFiltering"
      :allowSorting="item.allowSorting"
      :allowGrouping="item.allowGrouping"
      :isPrimaryKey="item.isPrimaryKey"
      :showInColumnChooser="item.showInColumnChooser"
      :visible="item.visible"
      :validationRules="item.validationRules"
      :format="item.format ? applyFormat(item.format) : null"
      :template="applyTemplate(item.template)"
      :defaultValue="item.defaultValue"
      :textAlign="item.textAlign ? item.textAlign : null"
      :headerTextAlign="item.textAlign ? item.textAlign : null"
      :nature="item.nature ? item.nature : null"
      :valueAccessor="applyFormatter(item.valueAccessor)"
      :displayAsCheckBox="displayAsCheckBox"
      :allowEditing="item.allowEditing"
      :editType="item.editType"
      :editTemplate="item.editTemplate"
      :columns="item.columns"
      :foreignKeyValue="foreignKeyValue ? foreignKeyValue : (item.foreignKeyValue ? item.foreignKeyValue : null)"
      :foreignKeyField="item.foreignKeyField ? item.foreignKeyField : null"
      :dataSource="dataSource.length ? dataSource : (item.dataSource && item.dataSource.length ? item.dataSource : [])"
      :edit='item.edit && item.edit.type ? applyEdit(item.editType, item.edit, index) : item.edit ? item.edit : null'
      :customAttributes="item.customCss ? {class: item.customCss} :''"
      :commands='item.type=="commandsDelete" ? commandsDelete : item.commands ? item.commands : null'
      :unavailableExport="item.unavailableExport"
      :availableExport="item.availableExport ? item.availableExport : false"
      :freeze="item.freeze"
      :filter="item.filterTemplate ? applyFilterTemplate(item.filterTemplate) : item.filter ? item.filter : { type: 'Excel' }"
    )
  //- Template grouping
  template(#captionTemplate="{ data }")
    //- TEMPLATE STATUS
    div(class="chip" v-if="data.items[0].creditProviderStatus >= 0 || data.items[0].invoiceProviderStatus >= 0")
      span(:class="'point bg-'+groupNameFormat(data).class")
      span(:class="'label text-'+groupNameFormat(data).class")
        | {{groupNameFormat(data).label}} - {{ data.count }} {{ data.count > 1 ? 'résultats' : 'résultat' }}
    //- TEMPLATE STATUS NULL
    div(class="chip" v-else-if="data.field=='status' && data.key == null")
      span(class="point bg-primary")
      span(class="label text-primary")
        | Aucun statut - {{ data.count }} {{ data.count > 1 ? 'résultats' : 'résultat' }}
    //- TEMPLATE STATUS
    div(class="chip" v-else-if="data.field=='status'")
      span(:class="'point bg-'+groupNameFormat(data).class")
      span(:class="'label text-'+groupNameFormat(data).class")
        | {{groupNameFormat(data).label}} - {{ data.count }} {{ data.count > 1 ? 'résultats' : 'résultat' }}
    //- TEMPLATE DOCUMENTDATE / DATE / STARTDATE / ENDDATE / REMITTEDBANKDATE / ESTIMATEDATERECEIPT / RECEIPTDATE / REPORTDATE // ACTIVITYDATE / SIGNED DATE / REMINDERDATE
    div(class="chip" v-else-if="data.field=='documentDate' || data.field=='date' || data.field=='endDate' || data.field=='startDate' || data.field=='remittedBankDate' || data.field=='estimatedDateReceipt' || data.field=='receiptDate' || data.field=='reportDate' || data.field=='inventoryDate' || data.field=='movementDate' || data.field=='activityDate' || data.field=='reminderDate' || data.field=='signedDate' || data.field=='limitDate'")
      span(class="label")
        | {{groupNameFormat(data).key}} - {{ data.count }} {{ data.count > 1 ? 'résultats' : 'résultat' }}
    //- TEMPLATE TAGS / SOURCETYPE
    div(class="chip" v-else-if="data.headerText=='Tags' || data.field=='sourceType'")
      span(:class="groupNameFormat(data).color ? 'label text-white rounded' : 'label'" :style="groupNameFormat(data).color ? 'background-color: '+groupNameFormat(data).color+'; padding: 0 6px 0 8px;' : 'padding: 0 6px 0 8px;'" style="line-height: 1.5rem")
        | {{data.headerText=='Tags' ? groupNameFormat(data).key : groupNameFormat(data).label }}
      span(class="label px-1" style="line-height: 1.5rem")
        | {{ data.count }} {{ data.count > 1 ? 'résultats' : 'résultat' }}
    //- TEMPLATE COLOR NULL
    div(class="chip" v-else-if="data.field=='color' && (data.key == null || data.key =='') ")
      span(class="label text-primary")
        | Aucune couleur - {{ data.count }} {{ data.count > 1 ? 'résultats' : 'résultat' }}
    //- TEMPLATE COLOR
    div(class="chip" v-else-if="data.field=='color'")
      span(class="point big" :style="'width: 1.5rem; height: 1.5rem; background-color: '+data.key")
    //- TEMPLATE AUTRE
    div(class="chip" v-else)
      span(class="label")
        | {{groupNameFormat(data).toString().replace(/<[^>]*>/g, '')}} - {{ data.count }} {{ data.count > 1 ? 'résultats' : 'résultat' }}

  e-aggregates(v-if="allowGrouping")
    e-aggregate
      e-columns
        e-column(
          v-for="(item, index) in headerData" 
          :key="index" 
          v-if="item.type=='number' && item.allowAggregate" 
          :type="applyTypeAggregate(item.field)" 
          :field="item.field" 
          :customAggregate="applyCustomAggregate(item.field)" 
          :footerTemplate="applyTypeAggregate(item.field) == 'Custom' ? footerSumCurrencyCustomTemplate : (item.format && item.format == 'formatCurrency' ? footerSumCurrencyTemplate : footerSumTemplate)" 
          :groupFooterTemplate="item.format && item.format == 'formatCurrency' ? footerSumCurrencyTemplate : footerSumTemplate")
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  ENatureShort,
  EProductLabelType,
  affairStatus,
  civilities,
  creditProviderStatus,
  creditStatus,
  deliveryFormStatus,
  deliveryFormTypes,
  directionTypes,
  documentApplicationType,
  holdbackStatus,
  interventionStatus,
  inventoryFormStatus,
  invoiceProviderStatus,
  invoiceStatus,
  nature,
  orderFormCustomerStatus,
  orderFormProviderStatus,
  paymentMethods,
  paymentTypes,
  productLabelTypes,
  quoteStatus,
  receiptFormProviderStatus,
  reportingStatus,
  sourceTypes,
  typeFields,
  statisticsPeriods,
  smsStatus,
  EContractNature,
  EContractStatus,
  contractNature,
  contractStatus,
} from "@/types/api-orisis/enums/enums";
import FilterTags from "@/components/global/tags/FilterTags";
import CellRendererAvancementOrderFormCustomer from "@/components/global/ag-grid/CellRendererAvancementOrderFormCustomer.vue";
import CellRendererOrderForm from "@/components/global/ag-grid/CellRendererOrderForm.vue";
import CellRendererRepartitions from "@/components/global/ag-grid/CellRendererRepartitions.vue";
import CellRendererBoolean from "@/components/global/ag-grid/CellRendererBoolean.vue";
import CellRendererAutocompleteContact from "@/components/global/ag-grid/CellRendererAutocompleteContact.vue";
import CellRendererAffair from "@/components/global/ag-grid/CellRendererAffair.vue";
import CellRendererAffairCode from "@/components/global/ag-grid/CellRendererAffairCode.vue";
import RowRendererActivities from "@/components/global/ag-grid/RowRendererActivities.vue";
import CellRendererMission from "@/components/global/ag-grid/CellRendererMission.vue";
import CellRendererContact from "@/components/global/ag-grid/CellRendererContact.vue";
import CellRendererCollaborator from "@/components/global/ag-grid/CellRendererCollaborator.vue";
import CellRendererCollaborators from "@/components/global/ag-grid/CellRendererCollaborators.vue";
import CellRendererCollaboratorDisplayLabel from "@/components/global/ag-grid/CellRendererCollaboratorDisplayLabel.vue";
import CellRendererDiscount from "@/components/global/ag-grid/CellRendererDiscount.vue";
import CellRendererDiscountType from "@/components/global/ag-grid/CellRendererDiscountType.vue";
import CellRendererCompany from "@/components/global/ag-grid/CellRendererCompany.vue";
import CellRendererPayrollEvent from "@/components/global/ag-grid/CellRendererPayrollEvent.vue";
import CellRendererActive from "@/components/global/ag-grid/CellRendererActive.vue";
import CellRendererDefault from "@/components/global/ag-grid/CellRendererDefault.vue";
import CellRendererColor from "@/components/global/ag-grid/CellRendererColor.vue";
import CellRendererCivility from "@/components/global/ag-grid/CellRendererCivility.vue";
import CellRendererStatus from "@/components/global/ag-grid/CellRendererStatus.vue";
import CellRendererOrigine from "@/components/global/ag-grid/CellRendererOrigine.vue";
import CellRendererCurrency from "@/components/global/ag-grid/CellRendererCurrency.vue";
import CellRendererTVA from "@/components/global/ag-grid/CellRendererTVA.vue";
import CellRendererTVAProvider from "@/components/global/ag-grid/CellRendererTVAProvider.vue";
import CellRendererCustomField from "@/components/global/ag-grid/CellRendererCustomField.vue";
import CellRendererDate from "@/components/global/ag-grid/CellRendererDate.vue";
import CellRendererDateYmd from "@/components/global/ag-grid/CellRendererDateYmd.vue";
import CellRendererDateYmdHm from "@/components/global/ag-grid/CellRendererDateYmdHm.vue";
import CellRendererDelete from "@/components/global/ag-grid/CellRendererDelete.vue";
import CellRendererDirectionType from "@/components/global/ag-grid/CellRendererDirectionType.vue";
import CellRendererHeureHm from "@/components/global/ag-grid/CellRendererHeureHm.vue";
import CellRendererMonth from "@/components/global/ag-grid/CellRendererMonth.vue";
import CellRendererMovement from "@/components/global/ag-grid/CellRendererMovement.vue";
import CellRendererNature from "@/components/global/ag-grid/CellRendererNature.vue";
import CellRendererParent from "@/components/global/ag-grid/CellRendererParent.vue";
import CellRendererPaymentType from "@/components/global/ag-grid/CellRendererPaymentType.vue";
import CellRendererProductType from "@/components/global/ag-grid/CellRendererProductType.vue";
import CellRendererSourceType from "@/components/global/ag-grid/CellRendererSourceType.vue";
import CellRendererStatisticsPeriod from "@/components/global/ag-grid/CellRendererStatisticsPeriod.vue";
import CellRendererStripHtml from "@/components/global/ag-grid/CellRendererStripHtml.vue";
import CellRendererStripTags from "@/components/global/ag-grid/CellRendererStripTags.vue";
import CellRendererTags from "@/components/global/ag-grid/CellRendererTags.vue";
import CellRendererType from "@/components/global/ag-grid/CellRendererType.vue";
import CellRendererTypeField from "@/components/global/ag-grid/CellRendererTypeField.vue";
import CellRendererDocumentReferenceTemplate from "@/components/global/ag-grid/CellRendererDocumentReferenceTemplate.vue";
import CellRendererContractNature from "@/components/global/ag-grid/CellRendererContractNature.vue";
import CellRendererContractStatus from "@/components/global/ag-grid/CellRendererContractStatus.vue";
import CellRendererAffairZone from "@/components/global/ag-grid/CellRendererAffairZone.vue";
import CellRendererModule from "@/components/global/ag-grid/CellRendererModule.vue";
import FilterRendererCollaborator from "@/components/global/ag-grid/FilterRendererCollaborator.vue";
import SumCurrencyFooter from "@/components/global/ag-grid/SumCurrencyFooter.vue";
import SumCurrencyFooterCustom from "@/components/global/ag-grid/SumCurrencyFooterCustom.vue";
import SumFooter from "@/components/global/ag-grid/SumFooter.vue";
import { Query } from "@syncfusion/ej2-data";
import {
  AutoComplete,
  CheckBoxSelection,
  DropDownList,
  DropDownTree,
} from "@syncfusion/ej2-dropdowns";
import {
  DatePicker,
  DateTimePicker,
  TimePicker,
} from "@syncfusion/ej2-vue-calendars";
import { MultiSelect } from "@syncfusion/ej2-vue-dropdowns";
import {
  Aggregate,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  DetailRow,
  Edit,
  ExcelExport,
  Filter,
  ForeignKey,
  Freeze,
  GridComponent,
  Group,
  Page,
  PdfExport,
  Reorder,
  Resize,
  RowDD,
  Selection,
  Sort,
  Toolbar,
  VirtualScroll,
} from "@syncfusion/ej2-vue-grids";
import { ColorPicker } from "@syncfusion/ej2-vue-inputs";

import { NumericTextBox, TextBox } from "@syncfusion/ej2-inputs";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import { DataUtil } from "@syncfusion/ej2-data";

import {
  formatCurrency,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { unitPeriodicityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

let action;
let dropdownaffair;
let dropdownmission;

MultiSelect.Inject(CheckBoxSelection);

export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    uid: {
      type: String,
      default: null,
    },
    allowTextWrap: {
      type: Boolean,
      default: false,
    },
    commandClick: {
      type: Function,
      default: null,
    },
    allowRowDragAndDrop: {
      type: Boolean,
      default: false,
    },
    allowSelection: {
      type: Boolean,
      default: true,
    },
    editSettings: {
      type: Object,
      default: null,
    },
    archive: {
      type: Boolean,
      default: false,
    },
    archiveMode: {
      type: Boolean,
      default: false,
    },
    allowPaging: {
      type: Boolean,
      default: true,
    },
    allowKeyboard: {
      type: Boolean,
      default: true,
    },
    allowGrouping: {
      type: Boolean,
      default: true,
    },
    toolbar: {
      type: Array,
      default: null,
    },
    gridData: {
      type: Array,
      default: () => [],
    },
    headerData: {
      type: Array,
      default: () => [],
    },
    tagguerButton: {
      type: Boolean,
      default: false,
    },
    companyTypeId: {
      type: Number,
      default: null,
    },
    displayAsCheckBox: {
      type: Boolean,
      default: false,
    },
    detailTemplate: {
      type: String,
      default: null,
    },
    selectionSettings: {
      type: Object,
      default: null,
    },
    dropOptions: {
      type: Object,
      default: null,
    },
    gridId: {
      type: String,
      default: "Grid",
    },
    // sortOptions: {
    //   type: Object,
    //   default: null,
    // },
    frozenColumns: {
      type: Number,
      default: null,
    },
    foreignKeyValue: {
      type: String,
      default: null,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    saveGridStateProps: {
      type: Boolean,
      default: true,
    },
    groupSettings: {
      type: Object,
      default: () => ({
        captionTemplate: "captionTemplate",
        columns: [],
      }),
    },
    sortSettings: {
      type: Object,
      default: () => ({
        columns: [],
      }),
    },
  },
  components: {
    "ejs-grid": GridComponent,
    "e-column": ColumnDirective,
    "e-columns": ColumnsDirective,
    CellRendererBoolean,
    CellRendererRepartitions,
    CellRendererMission,
    CellRendererAffair,
    CellRendererAffairCode,
    CellRendererCollaborator,
    CellRendererCollaborators,
    CellRendererCollaboratorDisplayLabel,
    CellRendererDiscount,
    CellRendererDiscountType,
    CellRendererCompany,
    CellRendererPayrollEvent,
    CellRendererContact,
    CellRendererActive,
    CellRendererDefault,
    CellRendererColor,
    CellRendererCivility,
    CellRendererStatus,
    CellRendererOrigine,
    CellRendererTVA,
    CellRendererTags,
    CellRendererProductType,
    CellRendererAutocompleteContact,
    CellRendererPaymentType,
    CellRendererStripHtml,
    CellRendererStripTags,
    FilterTags,
    SumFooter,
    SumCurrencyFooter,
    SumCurrencyFooterCustom,
    CellRendererType,
    CellRendererMovement,
    CellRendererNature,
    CellRendererDocumentReferenceTemplate,
    CellRendererContractNature,
    CellRendererContractStatus,
    CellRendererModule,
    FilterRendererCollaborator,
    CellRendererParent,
    CellRendererAffairZone,
  },
  data() {
    return {
      sortOptions: {
        columns: this.headerData
          .filter((elem) => {
            return elem.direction && elem.field;
          })
          .map((element) => {
            return { field: element.field, direction: element.direction };
          }),
      },
      filterOptions: { type: "Menu" },
      formatDateOptions: { type: "date", format: "dd/MM/yyyy" },
      formatDateTimeOptions: { type: "date", format: "dd/MM/yyyy" },
      loadingIndicator: { indicatorType: "Shimmer" },
      pageOptions: {
        currentPage: 1,
        pageSize: 20,
        pageCount: 8,
        pageSizes: true,
      },
      commandsDelete: [
        {
          type: "Delete",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
        {
          type: "Duplicate",
          buttonOption: { cssClass: "e-flat", iconCss: "e-copy e-icons" },
        },
      ],
      avancementOrderFormCustomerTemplate() {
        return {
          template: CellRendererAvancementOrderFormCustomer,
        };
      },
      activitiesTemplate() {
        return {
          template: RowRendererActivities,
        };
      },
      tagsTemplate() {
        return {
          template: CellRendererTags,
        };
      },
      productTypeTemplate() {
        return {
          template: CellRendererProductType,
        };
      },
      paymentTypeTemplate() {
        return {
          template: CellRendererPaymentType,
        };
      },
      autocompleteContactTemplate() {
        return {
          template: CellRendererAutocompleteContact,
        };
      },
      booleanTemplate() {
        return {
          template: CellRendererBoolean,
        };
      },
      repartitionsTemplate() {
        return {
          template: CellRendererRepartitions,
        };
      },
      affairTemplate() {
        return {
          template: CellRendererAffair,
        };
      },
      affairCodeTemplate() {
        return {
          template: CellRendererAffairCode,
        };
      },
      missionTemplate() {
        return {
          template: CellRendererMission,
        };
      },
      collaboratorTemplate() {
        return {
          template: CellRendererCollaborator,
        };
      },
      discountGlobalTemplate() {
        return {
          template: CellRendererDiscount,
        };
      },
      discountGlobalTypeTemplate() {
        return {
          template: CellRendererDiscountType,
        };
      },
      collaboratorsTemplate() {
        return {
          template: CellRendererCollaborators,
        };
      },
      collaboratorDisplayLabelTemplate() {
        return {
          template: CellRendererCollaboratorDisplayLabel,
        };
      },
      companyTemplate() {
        return {
          template: CellRendererCompany,
        };
      },
      payrollEventTemplate() {
        return {
          template: CellRendererPayrollEvent,
        };
      },
      activeTemplate() {
        return {
          template: CellRendererActive,
        };
      },
      defaultTemplate() {
        return {
          template: CellRendererDefault,
        };
      },
      colorTemplate() {
        return {
          template: CellRendererColor,
        };
      },
      civilityTemplate() {
        return {
          template: CellRendererCivility,
        };
      },
      contactTemplate() {
        return {
          template: CellRendererContact,
        };
      },
      statusTemplate() {
        return {
          template: CellRendererStatus,
        };
      },
      origineTemplate() {
        return {
          template: CellRendererOrigine,
        };
      },
      orderFormTemplate() {
        return {
          template: CellRendererOrderForm,
        };
      },
      dateTemplate() {
        return {
          template: CellRendererDate,
        };
      },
      dateYmdTemplate() {
        return {
          template: CellRendererDateYmd,
        };
      },
      dateYmdHmTemplate() {
        return {
          template: CellRendererDateYmdHm,
        };
      },
      heureHmTemplate() {
        return {
          template: CellRendererHeureHm,
        };
      },
      monthTemplate() {
        return {
          template: CellRendererMonth,
        };
      },
      currencyTemplate() {
        return {
          template: CellRendererCurrency,
        };
      },
      tvaTemplate() {
        return {
          template: CellRendererTVA,
        };
      },
      tvaProviderTemplate() {
        return {
          template: CellRendererTVAProvider,
        };
      },
      stripHtmlTemplate() {
        return {
          template: CellRendererStripHtml,
        };
      },
      stripTagsTemplate() {
        return {
          template: CellRendererStripTags,
        };
      },
      footerSumTemplate() {
        return {
          template: SumFooter,
        };
      },
      typeTemplate() {
        return {
          template: CellRendererType,
        };
      },
      footerSumCurrencyTemplate() {
        return {
          template: SumCurrencyFooter,
        };
      },
      footerSumCurrencyCustomTemplate() {
        return {
          template: SumCurrencyFooterCustom,
        };
      },
      quantityTemplate() {
        return {
          template: CellRendererMovement,
        };
      },
      sourceTypeTemplate() {
        return {
          template: CellRendererSourceType,
        };
      },
      directionTypeTemplate() {
        return {
          template: CellRendererDirectionType,
        };
      },
      typeFieldTemplate() {
        return {
          template: CellRendererTypeField,
        };
      },
      statisticsPeriodTemplate() {
        return {
          template: CellRendererStatisticsPeriod,
        };
      },
      natureTemplate() {
        return {
          template: CellRendererNature,
        };
      },
      customFieldTemplate() {
        return {
          template: CellRendererCustomField,
        };
      },
      deleteTemplate() {
        return {
          template: CellRendererDelete,
        };
      },
      parentTemplate() {
        return {
          template: CellRendererParent,
        };
      },
      documentReferenceTemplate() {
        return {
          template: CellRendererDocumentReferenceTemplate,
        };
      },
      contractNatureTemplate() {
        return {
          template: CellRendererContractNature,
        };
      },
      moduleTemplate() {
        return {
          template: CellRendererModule,
        };
      },
      contractStatusTemplate() {
        return {
          template: CellRendererContractStatus,
        };
      },
      affairZoneTemplate() {
        return {
          template: CellRendererAffairZone,
        };
      },
    };
  },
  provide: {
    grid: [
      Filter,
      RowDD,
      Selection,
      Sort,
      VirtualScroll,
      Reorder,
      Group,
      Resize,
      Page,
      ExcelExport,
      ColumnChooser,
      Aggregate,
      Toolbar,
      Edit,
      DetailRow,
      CommandColumn,
      Freeze,
      ForeignKey,
      PdfExport,
    ],
  },
  computed: {
    ...mapGetters([
      "isCreatingTag",
      "isUpdatingTag",
      "workspaceSelected",
      "tagsList",
      "affairsList",
      "collaboratorsList",
      "contactsList",
      "companiesList",
      "usersList",
      "missionsList",
      "payrollEventsList",
      "activitySourceTypesList",
      "activityChanelTypesList",
      "activityResultTypesList",
      "companiesTypesList",
      "userParametersList",
      "initialDocument",
    ]),
    globalGridKey() {
      return this.$store.getters["appConfig/globalGridKey"];
    },
    gridDataUtil() {
      return DataUtil.parse.parseJson(this.gridData);
    },
    missionsListFiltered() {
      return this.$store.getters["missionsList"].map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsList.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
  },
  methods: {
    ...mapActions(["createOrUpdateUserParameter", "getUserParameterByKey"]),
    formatCurrency,
    formatNumber,
    unitPeriodicityTranslate,
    applyTypeAggregate(field) {
      let customFields = [
        "documentTva",
        "amountAffairCosting",
        "documentTvaProvider",
        "invoiceProviderRepartitions",
        "creditProviderRepartitions",
      ];
      return customFields.includes(field) ? "Custom" : "Sum";
    },
    footerSumCurrencyCustomTemplateCalc() {
      return this.gridData.reduce((accumulateur, element) => {
        return accumulateur + (element.totalTtc - element.totalHt);
      }, 0);
    },
    footerSumDocumentTvaProviderCustomTemplateCalc() {
      return this.gridData.reduce((accumulateur, element) => {
        return accumulateur + (element.totalTTC - element.totalHT);
      }, 0);
    },
    footerSumAmountAffairCostingTemplateCalc() {
      return this.gridData.reduce((accumulateur, element) => {
        return accumulateur + element.amountUnit * element.valuatedQuantity;
      }, 0);
    },
    footerSumInvoiceRepartitionsTemplateCalc() {
      let affairId = this.$route.params.id;
      return this.gridData.reduce((accumulateur, element) => {
        let array = element.invoiceProviderRepartitions;
        const filteredRepartitions = array.filter((repartition) => {
          return repartition.affairId.toString() === affairId.toString();
        });
        const sumPrices = filteredRepartitions.reduce((acc, current) => {
          return acc + current.priceHt;
        }, 0);
        return accumulateur + sumPrices;
      }, 0);
    },
    footerSumCreditRepartitionsTemplateCalc() {
      let affairId = this.$route.params.id;
      return this.gridData.reduce((accumulateur, element) => {
        let array = element.creditProviderRepartitions;
        const filteredRepartitions = array.filter((repartition) => {
          return repartition.affairId.toString() === affairId.toString();
        });
        const sumPrices = filteredRepartitions.reduce((acc, current) => {
          return acc + current.priceHt;
        }, 0);
        return accumulateur + sumPrices;
      }, 0);
    },
    applyCustomAggregate(field) {
      if (field == "documentTva") {
        return this.footerSumCurrencyCustomTemplateCalc;
      } else if (field == "documentTvaProvider") {
        return this.footerSumDocumentTvaProviderCustomTemplateCalc;
      } else if (field == "amountAffairCosting") {
        return this.footerSumAmountAffairCostingTemplateCalc;
      } else if (field == "invoiceProviderRepartitions") {
        return this.footerSumInvoiceRepartitionsTemplateCalc;
      } else if (field == "creditProviderRepartitions") {
        return this.footerSumCreditRepartitionsTemplateCalc;
      } else {
        return "";
      }
    },
    rowDataBound(args) {
      if (
        this.uid == "viewEditReport" &&
        args.data &&
        (args.data.status == -1 || args.data.status == -2)
      ) {
        args.row.classList.add("bg-row-5");
      } else if (
        this.uid == "viewEditReport" &&
        args.data &&
        (args.data.status == 2 ||
          args.data.status == 3 ||
          args.data.status == 4)
      ) {
        args.row.querySelectorAll(".e-deletebutton")[0].style.display = "none";
      } else if (
        (args.data && args.data.status == 11) ||
        args.data.status == 12
      ) {
        args.row.classList.add("bg-row-5");
      }
    },
    checkCollaboratorMode(args) {
      const collaborator = this.collaboratorsList.find(
        (collab) => collab.id === args.rowData.collaboratorId
      );

      if (collaborator && !collaborator.reportEntryMode) {
        return true;
      }

      return false;
    },
    checkCollaboratorModeForDates(args) {
      const collaborator = this.collaboratorsList.find(
        (collab) => collab.id === args.rowData.collaboratorId
      );

      if (collaborator && collaborator.reportEntryMode) {
        return true;
      }

      return false;
    },
    saveGridState(a) {
      if (this.saveGridStateProps && this.uid) {
        const grid = this.$refs.overviewgrid;
        this.getUserParameterByKey({ key: this.uid }).then(
          (currentUserParameter) => {
            // Ancien UserParameter
            const currentUserParameterColumns = currentUserParameter
              ? JSON.parse(currentUserParameter.value).columns
              : null;
            const columnReordered = grid.getColumns().map((el) => {
              const previousSortDirection = currentUserParameterColumns
                ? currentUserParameterColumns.find(
                    (element) => el.field == element.field
                  ).sortDirection
                : null;
              let setSortDirection = "none";

              // Si on a des arguments
              if (a) {
                // Update la colonne cliquée
                if (a.columnName == el.field) {
                  setSortDirection = a.direction;
                  // Mettre les autres à none
                } else {
                  setSortDirection = "none";
                }
                // sinon si on a des colonnes sauvegardées
              } else if (previousSortDirection) {
                setSortDirection = previousSortDirection;
                // sinon on met none car il n'y a pas de colonnes sauvegardées
              } else {
                setSortDirection = "none";
              }
              return {
                field: el.field,
                headerText: el.headerText,
                visible: el.visible,
                width: el.width,
                minWidth: el.minWidth,
                maxWidth: el.maxWidth,
                textAlign: el.textAlign,
                type: el.type,
                format: el.format,
                defaultValue: el.defaultValue,
                validationRules: el.validationRules,
                allowEditing: el.allowEditing,
                editType: el.editType,
                columns: el.columns,
                nature: el.nature,
                valueAccessor: el.valueAccessor,
                customCss: el.customCss,
                customAttributes: el.customAttributes,
                showInColumnChooser: el.showInColumnChooser,
                allowFiltering: el.allowFiltering,
                allowSorting: el.allowSorting,
                allowGrouping: el.allowGrouping,
                isPrimaryKey: el.isPrimaryKey,
                commands:
                  el.type == "commandsDelete"
                    ? commandsDelete
                    : el.commands
                    ? el.commands
                    : null,
                unavailableExport: el.unavailableExport,
                availableExport: el.availableExport,
                filter: el.filter,
                sortDirection: setSortDirection,
              };
            });
            const interfaceParameters = {
              columns: columnReordered,
            };
            const interfaceName = this.uid;
            this.createOrUpdateUserParameter({
              label: "Colonnes de l'interface " + interfaceName,
              key: interfaceName,
              value: JSON.stringify(interfaceParameters),
            });
          }
        );
      }
    },
    saveGridGroupingState(a) {
      if (this.saveGridStateProps && this.uid) {
        this.getUserParameterByKey({ key: this.uid + "Grouping" }).then(
          (currentUserParameter) => {
            let groupedColumns = currentUserParameter
              ? JSON.parse(currentUserParameter.value)
              : [];
            if (a.requestType == "grouping") {
              if (!groupedColumns.find((elem) => elem.field == a.columnName)) {
                groupedColumns.push({ field: a.columnName });
              }
            } else if (a.requestType == "ungrouping") {
              if (groupedColumns.find((elem) => elem.field == a.columnName)) {
                groupedColumns = groupedColumns.filter(
                  (elem) => elem.field != a.columnName
                );
              }
            }
            const interfaceName = this.uid + "Grouping";
            this.createOrUpdateUserParameter({
              label: "Groupements des colonnes de l'interface " + interfaceName,
              key: interfaceName,
              value: JSON.stringify(groupedColumns),
            });
          }
        );
      }
    },
    saveGridFilteringState(a) {
      if (this.saveGridStateProps && this.uid) {
        this.getUserParameterByKey({ key: this.uid + "Filtering" }).then(
          (currentUserParameter) => {
            let filteredColumns = currentUserParameter
              ? JSON.parse(currentUserParameter.value)
              : [];
            if (a.requestType == "filtering" && a.action !== "clearFilter") {
              filteredColumns = a.columns.map((elem) => {
                return {
                  field: elem.field,
                  matchCase: elem.matchCase,
                  operator: elem.operator,
                  predicate: elem.predicate,
                  value: elem.value,
                };
              });
            } else {
              filteredColumns = [];
            }
            const interfaceName = this.uid + "Filtering";
            this.createOrUpdateUserParameter({
              label: "Filtres des colonnes de l'interface " + interfaceName,
              key: interfaceName,
              value: JSON.stringify(filteredColumns),
            });
          }
        );
      }
    },
    rowDrop(args) {
      args.cancel = true;
      var value = [];
      for (var r = 0; r < args.rows.length; r++) {
        value.push(args.fromIndex + r);
      }
      this.$refs.overviewgrid.reorderRows(value, args.dropIndex);
    },
    resizeStop(args) {
      this.saveGridState();
    },
    async getSavedHeaderData() {
      this.getUserParameterByKey({ key: this.uid + "Grouping" }).then(
        (currentUserParameter) => {
          if (currentUserParameter) {
            this.groupSettings.columns = [];
            const groupedColumns = JSON.parse(currentUserParameter.value);
            groupedColumns.forEach((elem) => {
              this.groupSettings.columns.push(elem.field);
            });
            this.$refs.overviewgrid.setProperties({
              groupSettings: this.groupSettings,
            });
          }
        }
      );
      this.getUserParameterByKey({ key: this.uid + "Filtering" }).then(
        (currentUserParameter) => {
          if (currentUserParameter) {
            this.filterOptions.columns = [];
            const filteredColumns = JSON.parse(currentUserParameter.value);
            this.filterOptions.columns = filteredColumns;
            this.$refs.overviewgrid.setProperties({
              filterSettings: this.filterOptions,
            });
          }
        }
      );
      this.getUserParameterByKey({ key: this.uid }).then(
        (currentUserParameter) => {
          if (currentUserParameter) {
            const headerToSetToGrid = JSON.parse(
              currentUserParameter.value
            ).columns;

            // Ajouter les propriétés manquantes
            const headerToSetWithAllAttributes = headerToSetToGrid.map(
              (elem) => {
                const headerData = this.headerData.find(
                  (header) => header.field == elem.field
                );
                if (headerData) {
                  return {
                    ...elem,
                    template: headerData.template
                      ? this.applyTemplate(headerData.template)
                      : null,
                    valueAccessor: headerData.valueAccessor
                      ? this.applyFormatter(headerData.valueAccessor)
                      : null,
                    edit:
                      headerData.edit && headerData.edit.type
                        ? this.applyEdit(headerData.editType, headerData.edit)
                        : headerData.edit
                        ? headerData.edit
                        : null,
                    format: headerData.format
                      ? this.applyFormat(headerData.format)
                      : null,
                    direction: elem.sortDirection,
                    filter: headerData.filterTemplate
                      ? this.applyFilterTemplate(headerData.filterTemplate)
                      : headerData.filter
                      ? headerData.filter
                      : { type: "Excel" },
                    filterTemplate: null,
                  };
                } else {
                  return elem;
                }
              }
            );
            // Appliquer les sortDirection
            headerToSetWithAllAttributes
              .filter(
                (elem) =>
                  elem.visible &&
                  elem.sortDirection &&
                  elem.sortDirection != "none"
              )
              .forEach((elem) => {
                this.$refs.overviewgrid.sortColumn(
                  elem.field,
                  elem.sortDirection
                );
              });
            this.$refs.overviewgrid.setProperties({
              columns: headerToSetWithAllAttributes,
            });
          }
        }
      );
    },
    dataExcelTemplate(itemToFiltered) {
      switch (itemToFiltered) {
        case "collaboratorIds":
          const collaborators = this.gridData
            .map((data) => {
              return data.collaborators;
            })
            .flat()
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id)
            );
          return collaborators;
          break;
        case "collaboratorId":
          return this.collaboratorsList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.collaboratorId;
              })
              .includes(item.id)
          );
          break;
        case "contactId":
          return this.contactsList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.contactId;
              })
              .includes(item.id)
          );
          break;
        case "companyId":
          return this.companiesList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.companyId;
              })
              .includes(item.id)
          );
          break;
        case "payrollEvent.id":
          return this.payrollEventsList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.payrollEvent.id;
              })
              .includes(item.id)
          );
          break;
        case "affairId":
          return this.affairsList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.affairId;
              })
              .includes(item.id)
          );
          break;
        case "missionId":
          return this.missionsList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.missionId;
              })
              .includes(item.id)
          );
          break;
        case "userId":
          return this.usersList
            .filter((item) =>
              this.gridData
                .map((data) => {
                  return data.userId;
                })
                .includes(item.id)
            )
            .map((user) => {
              return {
                ...user,
                displayLabel: `${user.firstName} ${user.lastName}`,
              };
            });
          break;
        case "productType":
          let productTypeList = Object.entries(EProductLabelType).map(
            ([label, id]) => ({ id: id, displayLabel: label })
          );
          productTypeList.slice(0, 6);
          return productTypeList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.productType;
              })
              .includes(item.id)
          );
          break;
        case "nature":
          let natureList = Object.entries(ENatureShort).map(([label, id]) => ({
            id: id,
            displayLabel: label,
          }));
          natureList.slice(0, 6);
          return natureList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.nature;
              })
              .includes(item.id)
          );
          break;
        case "paymentType":
          let paymentTypeList = [
            { id: 0, displayLabel: "Débit" },
            { id: 1, displayLabel: "Crédit" },
          ];
          return paymentTypeList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.paymentType;
              })
              .includes(item.id)
          );
          break;
        case "deliveryType":
          let deliveryTypeList = [
            { id: 0, displayLabel: "Ordre de livraison" },
            { id: 1, displayLabel: "Transfert entre dépôts" },
            { id: 2, displayLabel: "Remise en main propre" },
          ];
          return deliveryTypeList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.deliveryType;
              })
              .includes(item.id)
          );
          break;
        case "directionType":
          let directionTypeList = [
            { label: "Entrée", id: 1 },
            { label: "Sortie", id: 2 },
          ];
          return directionTypeList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.directionType;
              })
              .includes(item.id)
          );
          break;
        case "contractNature":
          return contractNature.filter((item) =>
            this.gridData
              .map((data) => {
                return data.nature;
              })
              .includes(item.value)
          );
          break;
        case "contractStatus":
          return contractStatus.filter((item) =>
            this.gridData
              .map((data) => {
                return data.status;
              })
              .includes(item.value)
          );
          break;
        case "status":
          if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "affairStatus"
              )
            ]?.nature === "affairStatus"
          ) {
            let affairsStatusList = affairStatus.map((affairStatus) => {
              return {
                id: affairStatus.value,
                displayLabel: affairStatus.label,
              };
            });
            return affairsStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex((elem) => elem.nature === "quoteStatus")
            ]?.nature === "quoteStatus"
          ) {
            let quotesStatusList = quoteStatus.map((quoteStatus) => {
              return {
                id: quoteStatus.value,
                displayLabel: quoteStatus.label,
              };
            });
            return quotesStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "invoiceStatus"
              )
            ]?.nature === "invoiceStatus"
          ) {
            let invoicesStatusList = invoiceStatus.map((invoiceStatus) => {
              return {
                id: invoiceStatus.value,
                displayLabel: invoiceStatus.label,
              };
            });
            return invoicesStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "creditStatus"
              )
            ]?.nature === "creditStatus"
          ) {
            let creditsStatusList = creditStatus.map((creditStatus) => {
              return {
                id: creditStatus.value,
                displayLabel: creditStatus.label,
              };
            });
            return creditsStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "orderFormProviderStatus"
              )
            ]?.nature === "orderFormProviderStatus"
          ) {
            let orderFormsProviderStatusList = orderFormProviderStatus.map(
              (orderFormProviderStatus) => {
                return {
                  id: orderFormProviderStatus.value,
                  displayLabel: orderFormProviderStatus.label,
                };
              }
            );
            return orderFormsProviderStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "orderFormCustomerStatus"
              )
            ]?.nature === "orderFormCustomerStatus"
          ) {
            let orderFormsCustomerStatusList = orderFormCustomerStatus.map(
              (orderFormCustomerStatus) => {
                return {
                  id: orderFormCustomerStatus.value,
                  displayLabel: orderFormCustomerStatus.label,
                };
              }
            );
            return orderFormsCustomerStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            // TODO : A VERIFIER
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "reportingStatus"
              )
            ]?.nature === "reportingStatus"
          ) {
            let reportingStatusList = reportingStatus.map((reportingStatus) => {
              return {
                id: reportingStatus.value,
                displayLabel: reportingStatus.label,
              };
            });
            return reportingStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "paymentTypes"
              )
            ]?.nature === "paymentTypes"
          ) {
            let paymentTypesList = paymentTypes.map((paymentType) => {
              return {
                id: paymentType.value,
                displayLabel: paymentType.label,
              };
            });
            return paymentTypesList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "receiptFormProviderStatus"
              )
            ]?.nature === "receiptFormProviderStatus"
          ) {
            let receiptFormsProviderStatusList = receiptFormProviderStatus.map(
              (receiptFormProviderStatus) => {
                return {
                  id: receiptFormProviderStatus.value,
                  displayLabel: receiptFormProviderStatus.label,
                };
              }
            );
            return receiptFormsProviderStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "deliveryFormStatus"
              )
            ]?.nature === "deliveryFormStatus"
          ) {
            let deliveryFormsStatusList = deliveryFormStatus.map(
              (deliveryFormStatus) => {
                return {
                  id: deliveryFormStatus.value,
                  displayLabel: deliveryFormStatus.label,
                };
              }
            );
            return deliveryFormsStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            // TODO : A VERIFIER
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "inventoryFormStatus"
              )
            ]?.nature === "inventoryFormStatus"
          ) {
            let inventoryFormsStatusList = inventoryFormStatus.map(
              (inventoryFormStatus) => {
                return {
                  id: inventoryFormStatus.value,
                  displayLabel: inventoryFormStatus.label,
                };
              }
            );
            return inventoryFormsStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "invoiceProviderStatus"
              )
            ]?.nature === "invoiceProviderStatus"
          ) {
            let invoiceProvidersStatusList = invoiceProviderStatus.map(
              (invoiceProviderStatus) => {
                return {
                  id: invoiceProviderStatus.value,
                  displayLabel: invoiceProviderStatus.label,
                };
              }
            );
            return invoiceProvidersStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "creditProviderStatus"
              )
            ]?.nature === "creditProviderStatus"
          ) {
            let creditProvidersStatusList = creditProviderStatus.map(
              (creditProviderStatus) => {
                return {
                  id: creditProviderStatus.value,
                  displayLabel: creditProviderStatus.label,
                };
              }
            );
            return creditProvidersStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "interventionStatus"
              )
            ]?.nature === "interventionStatus"
          ) {
            let interventionsStatusList = interventionStatus.map(
              (interventionStatus) => {
                return {
                  id: interventionStatus.value,
                  displayLabel: interventionStatus.label,
                };
              }
            );
            return interventionsStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex(
                (elem) => elem.nature === "holdbackStatus"
              )
            ]?.nature === "holdbackStatus"
          ) {
            let holdbacksStatusList = holdbackStatus.map((holdbackStatus) => {
              return {
                id: holdbackStatus.value,
                displayLabel: holdbackStatus.label,
              };
            });
            return holdbacksStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          } else if (
            this.headerData[
              this.headerData.findIndex((elem) => elem.nature === "smsStatus")
            ]?.nature === "smsStatus"
          ) {
            let smsStatusList = smsStatus.map((smsStatus) => {
              return {
                id: smsStatus.value,
                displayLabel: smsStatus.label,
              };
            });
            return smsStatusList.filter((item) =>
              this.gridData
                .map((data) => {
                  return data.status;
                })
                .includes(item.id)
            );
          }
        case "sourceType":
          let sourceTypeList = [
            { label: "Réalisé", id: 1 },
            { label: "Prévu", id: 2 },
          ];
          return sourceTypeList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.sourceType;
              })
              .includes(item.id)
          );
        case "activitySourceTypeId":
          return this.activitySourceTypesList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.activitySourceTypeId;
              })
              .includes(item.id)
          );
          break;
        case "activityChanelTypeId":
          return this.activityChanelTypesList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.activityChanelTypeId;
              })
              .includes(item.id)
          );
          break;
        case "activityResultTypeId":
          return this.activityResultTypesList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.activityResultTypeId;
              })
              .includes(item.id)
          );
          break;
        case "companyTypeId":
          return this.companiesTypesList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.companyTypeId;
              })
              .includes(item.id)
          );
          break;
        case "typeField":
          let typeFieldsList = typeFields.map((typeField) => {
            return { id: typeField.value, displayLabel: typeField.label };
          });
          return typeFieldsList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.typeField;
              })
              .includes(item.id)
          );
          break;
        case "quantificationType":
          let quantificationTypesList = [
            { label: "Checkbox", id: 0 },
            { label: "Quantité", id: 1 },
            { label: "Montant", id: 2 },
          ];
          return quantificationTypesList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.quantificationType;
              })
              .includes(item.id)
          );
          break;
        case "unitId":
          return this.unitsList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.unitId;
              })
              .includes(item.id)
          );
          break;
        case "documentApplicationType":
          return documentApplicationType.filter((item) =>
            this.gridData
              .map((data) => {
                return data.documentApplicationType;
              })
              .includes(item.id)
          );
          break;
        case "boolean":
          // let isPresent = this.gridData.some((item) => {return item.orderFormCustomerId !== null})
          // if (isPresent) {
          return [
            { displayLabel: "Oui", value: true, id: 1 },
            { displayLabel: "Non", value: false, id: 0 },
          ];
          // }
          break;
        case "creditProviderStatus":
          let creditProvidersStatusList = creditProviderStatus.map(
            (creditProviderStatus) => {
              return {
                id: creditProviderStatus.value,
                displayLabel: creditProviderStatus.label,
              };
            }
          );
          return creditProvidersStatusList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.creditProviderStatus;
              })
              .includes(item.id)
          );
          break;
        case "invoiceProviderStatus":
          let invoiceProvidersStatusList = invoiceProviderStatus.map(
            (invoiceProviderStatus) => {
              return {
                id: invoiceProviderStatus.value,
                displayLabel: invoiceProviderStatus.label,
              };
            }
          );
          return invoiceProvidersStatusList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.invoiceProviderStatus;
              })
              .includes(item.id)
          );
          break;
        case "civility":
          let civilityList = [
            { id: 0, displayLabel: "M. " },
            { id: 1, displayLabel: "Mme " },
            { id: 3, displayLabel: "M. et Mme " },
          ];
          return civilityList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.civility;
              })
              .includes(item.id)
          );
          break;
        case "statisticsPeriod":
          let statisticsPeriodsList = statisticsPeriods.map(
            (statisticsPeriod) => {
              return {
                displayLabel: statisticsPeriod.label,
                id: statisticsPeriod.value,
              };
            }
          );
          return statisticsPeriodsList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.statisticsPeriod;
              })
              .includes(item.id)
          );
          break;
        case "affairCode":
          let tempGridData = this.gridData.map((data) => data.affairId);
          return this.affairsList.filter((affair) =>
            tempGridData.includes(affair.id)
          );
          break;
        case "affairCategory":
          let affairCategoriesList = this.affairCategoriesList.map(
            (affairCategory) => {
              return {
                displayLabel: affairCategory.label,
                id: affairCategory.id,
              };
            }
          );
          return affairCategoriesList.filter((item) =>
            this.gridData
              .map((data) => {
                return data.affairCategory?.id;
              })
              .includes(item.id)
          );

          break;
        case "internalNote":
          // A REVOIR PLUS TARD CAR FORMAT PAS BON
          // return this.gridData.map((data) => {
          //   let str = data.internalNote;
          //   const haveMultiParagraph = str?.match(/<p\b[^>]*>(.*?)<\/p>/gi);
          //   if (!str) {
          //     return null;
          //   } else if (!haveMultiParagraph && str) {
          //     return str.replace(/\r?\n|\r/g, "<br>");
          //   } else if (haveMultiParagraph.length < 2) {
          //     return str ? str.replace(/\r?\n|\r/g, "<br>") : "";
          //   } else {
          //     let div = document.createElement("div");
          //     div.innerHTML = str;
          //     let firstP = div.querySelector("p");
          //     str = firstP.innerText;
          //     str ? str.replace(/\r?\n|\r/g, "<br>") : "";
          //   }
          //   return {
          //     displayLabel: str,
          //     id: data.internalNote,
          //   };
          // });
          break;
        default:
          return null;
      }
    },
    situationRemainingPercent: function (field, data, column) {
      const isSituationTypeQuantity = this.initialDocument?.invoices.find(
        (elem) => elem.situationType == true && elem.status != 5
      );
      if (!isSituationTypeQuantity) {
        return (100 - data["situationProgress"]).toFixed(2) + " %";
      } else {
        return (
          formatNumber(
            100 - (data["situationProgressQuantity"] / data["quantity"]) * 100
          ) + " %"
        );
      }
    },
    situationRemainingQuantity: function (field, data, column) {
      const isSituationTypeQuantity = this.initialDocument?.invoices.find(
        (elem) => elem.situationType == true && elem.status != 5
      );
      if (!isSituationTypeQuantity) {
        return formatNumber(
          ((100 - data["situationProgress"]) / 100) * data["quantity"]
        );
      } else {
        return parseFloat(
          (data["quantity"] - data["situationProgressQuantity"]).toFixed(4)
        );
      }
    },
    situationRemainingAmount: function (field, data, column) {
      const haveInvoice = this.initialDocument?.invoices.find(
        (elem) => elem.status != 5 && elem.nature != 2
      );
      let priceBrutHt =
        Math.round(data["unitPriceHt"] * data["quantity"] * 100) / 100;
      let advancement = 0;
      if (haveInvoice) {
        const isSituationTypeQuantity = this.initialDocument?.invoices.find(
          (elem) => elem.situationType == true && elem.status != 5
        );
        if (!isSituationTypeQuantity) {
          advancement =
            data["type"] != 13
              ? Number(((100 - data["situationProgress"]) / 100).toFixed(4))
              : 1;
        } else {
          advancement =
            data["type"] != 13
              ? Number(1 - data["situationProgressQuantity"] / data["quantity"])
              : 1;
        }
      } else {
        advancement = (100 - data["situationProgress"]) / 100;
      }

      let advancementPriceBrutHt =
        Math.round(priceBrutHt * advancement * 100) / 100;
      let discount = data["discount"]
        ? Math.round(((100 - data["discount"]) / 100) * 100) / 100
        : 1;
      return formatCurrency(
        Math.round(advancementPriceBrutHt * discount * 100) / 100
      );
    },
    situationProgressAmount: function (field, data, column) {
      const isSituationTypeQuantity = this.initialDocument?.invoices.find(
        (elem) => elem.situationType == true && elem.status != 5
      );
      let discount = data["discount"]
        ? Math.round(((100 - data["discount"]) / 100) * 100) / 100
        : 1;
      if (!isSituationTypeQuantity) {
        return formatCurrency(
          data["unitPriceHt"] *
            data["quantity"] *
            discount *
            (data["situationProgress"] / 100)
        );
      } else {
        return formatCurrency(
          data["situationProgressQuantity"] * data["unitPriceHt"] * discount
        );
      }
    },
    situationProgressQuantity: function (field, data, column) {
      const haveInvoice = this.initialDocument?.invoices.find(
        (elem) => elem.status != 5 && elem.nature != 2
      );
      if (haveInvoice) {
        const isSituationTypeQuantity = this.initialDocument.invoices.find(
          (elem) => elem.situationType == true && elem.status != 5
        );
        if (!isSituationTypeQuantity) {
          return formatNumber(
            (data["situationProgress"] / 100) * data["quantity"]
          );
        } else {
          return data["situationProgressQuantity"];
        }
      } else {
        return formatNumber(
          (data["situationProgress"] / 100) * data["quantity"]
        );
      }
    },
    situationProgressPercent: function (field, data, column) {
      const haveInvoice = this.initialDocument?.invoices.find(
        (elem) => elem.status != 5 && elem.nature != 2
      );
      if (haveInvoice) {
        const isSituationTypeQuantity = this.initialDocument?.invoices.find(
          (elem) => elem.situationType == true && elem.status != 5
        );
        if (!isSituationTypeQuantity) {
          return data["situationProgress"].toFixed(2) + " %";
        } else {
          return (
            (
              (data["situationProgressQuantity"] / data["quantity"]) *
              100
            ).toFixed(2) + " %"
          );
        }
      } else {
        return parseFloat(data["situationProgress"]).toFixed(2) + " %";
      }
    },
    discountPercentFormatter: function (field, data, column) {
      return data["discount"] ? data["discount"].toFixed(2) + " %" : "--";
    },
    totalPriceHT: function (field, data, column) {
      let discount = data["discount"]
        ? Math.round(((100 - data["discount"]) / 100) * 100) / 100
        : 1;
      return formatCurrency(data["unitPriceHt"] * data["quantity"] * discount);
    },
    sumAffairCostingCurrencyFormatter: function (field, data, column) {
      return data["valuatedQuantity"] * data["amountUnit"];
    },
    quantityFormatter: function (field, data, column) {
      return formatNumber(data[field]);
    },
    currencyFormatter: function (field, data, column) {
      return formatCurrency(data[field]);
    },
    percentFormatter: function (field, data, column) {
      return formatNumber(data[field]) + " %";
    },
    marginCostingFormatter: function (field, data, column) {
      if (data["totalCA"] > 0) {
        return (
          formatNumber(
            ((data["totalCA"] - data["totalOfExpenses"]) / data["totalCA"]) *
              100
          ) + " %"
        );
      } else {
        return "0 %";
      }
    },
    collaboratorsFormatter: function (field, data, column) {
      return data[field].map((el) => el.displayLabel).join(", ");
    },
    actionComplete(args) {
      if (args.requestType == "grouping" || args.requestType == "ungrouping") {
        this.saveGridGroupingState(args);
      }
      if (args.requestType == "reorder" || args.requestType == "columnstate") {
        this.saveGridState();
      } else if (args.requestType == "sorting") {
        this.saveGridState(args);
      } else if (args.requestType == "filtering") {
        this.saveGridFilteringState(args);
      }
      if (args.requestType == "beginEdit") {
        let _this = this;
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          if (_this.editSettings && _this.editSettings.mode == "Dialog") {
            let dialog = args.dialog;
            dialog.width = 400;
            if (_this.editSettings.titleField == "date") {
              dialog.header =
                args.requestType === "beginEdit"
                  ? this.editSettings.titleEdit +
                    (args.rowData[this.editSettings.titleField]
                      ? " : " +
                        dayjs(
                          args.rowData[this.editSettings.titleField]
                        ).format("DD/MM/YYYY")
                      : "")
                  : this.editSettings.titleAdd;
            } else {
              dialog.header =
                args.requestType === "beginEdit"
                  ? this.editSettings.titleEdit +
                    (args.rowData[this.editSettings.titleField]
                      ? " : " + args.rowData[this.editSettings.titleField]
                      : "")
                  : this.editSettings.titleAdd;
            }
            if (this.archiveMode && this.archive == true) {
              let footerRestoreButton = {
                buttonModel: {
                  content: "",
                  cssClass: "e-outline e-warning m-0 float-left",
                  iconCss: "e-btn-sb-icons e-icon-restore",
                },
                click: function () {
                  _this.$emit("restoreButtonClicked", args);
                },
              };
              args.dialog.buttons.unshift(footerRestoreButton);
            } else if (this.archiveMode && this.archive == false) {
              let footerArchiveButton = {
                buttonModel: {
                  content: "",
                  cssClass: "e-outline e-danger m-0 float-left",
                  iconCss: "e-btn-sb-icons e-icon-archive",
                },
                click: function () {
                  _this.$emit("archiveButtonClicked", args);
                },
              };
              args.dialog.buttons.unshift(footerArchiveButton);
            }

            if (
              _this.editSettings &&
              _this.editSettings.allowDeleting &&
              args.requestType !== "add" &&
              ((this.archiveMode && this.archive) || !this.archiveMode) &&
              (!args.rowData.status ||
                (args.rowData.status && args.rowData.status !== 3))
            ) {
              let footerDeleteButton = {
                buttonModel: {
                  content: "",
                  cssClass: "e-outline e-danger m-0 float-left mr-1",
                  iconCss: "e-btn-sb-icons e-icon-trash",
                },
                click: function () {
                  _this.$emit("deleteButtonClicked", args);
                },
              };
              args.dialog.buttons.unshift(footerDeleteButton);
            }

            args.dialog.refresh();
          }
        }
      }
    },
    queryCellInfoEvent: function (args) {
      this.$emit("queryCellInfo", args);
    },
    gridLoad: function (args) {
      this.$emit("gridLoad", args);
    },
    gridCreated: function (args) {
      if (this.saveGridStateProps && this.uid) this.getSavedHeaderData();
      this.$emit("gridCreated", args);
    },
    gridBeforeDataBound: function (args) {
      this.$emit("gridBeforeDataBound", args);
    },
    dataBound: function (args) {
      this.$emit("dataBound", args);
    },
    actionBegin: function (args) {
      this.$emit("actionBegin", args);
      if (args.requestType === "save" && args.action === "add") {
        // Lors de l'ajout on annule l'action de base pour pouvoir ajouter et récupérer l'ID, sinon la nouvelle ligne se met avec un id à 0
        args.cancel = true;
        this.$refs.overviewgrid.closeEdit();
      } else if (args.requestType === "paging") {
        // this.pageOptions.pageSize = args.pageSize;
        this.$refs.overviewgrid.pageSettings.pageSize = args.pageSize;
        this.saveGridState();
      }
    },
    collaboratorFormatter(field, data, column) {
      if (data.collaboratorId) {
        return this.collaboratorsList.find((el) => el.id == data.collaboratorId)
          .displayLabel;
      } else {
        return "";
      }
    },
    contactFormatter(field, data, column) {
      if (data.contactId) {
        return (
          this.contactsList.find((el) => el.id == data.contactId).firstName +
          " " +
          this.contactsList.find((el) => el.id == data.contactId).lastName
        );
      } else {
        return "";
      }
    },
    companyFormatter(field, data, column) {
      if (data.companyId) {
        return this.companiesList.find((el) => el.id == data.companyId).name;
      } else {
        return "";
      }
    },
    activitySourceTypesFormatter(field, data, column) {
      if (data.activitySourceTypeId) {
        return this.activitySourceTypesList.find(
          (el) => el.id == data.activitySourceTypeId
        ).name;
      } else {
        return "";
      }
    },
    activityChanelTypesFormatter(field, data, column) {
      if (data.activityChanelTypeId) {
        return this.activityChanelTypesList.find(
          (el) => el.id == data.activityChanelTypeId
        ).name;
      } else {
        return "";
      }
    },
    activityResultTypesFormatter(field, data, column) {
      if (data.activityResultTypeId) {
        return this.activityResultTypesList.find(
          (el) => el.id == data.activityResultTypeId
        ).name;
      } else {
        return "";
      }
    },
    userFormatter(field, data, column) {
      if (data.userId) {
        return data.userFirstName + " " + data.userLastName;
      } else {
        return "";
      }
    },
    companyCustomerAddressFormatter(field, data, column) {
      const address = data.companyCustomerAddress
        ? data.companyCustomerAddress
        : "";
      const zipCode = data.companyCustomerZipCode
        ? data.companyCustomerZipCode
        : "";
      const city = data.companyCustomerCity ? data.companyCustomerCity : "";
      return address + " " + zipCode + " " + city;
    },
    unitPeriodicityFormatter(field, data, column) {
      return this.unitPeriodicityTranslate(data.unit);
    },
    onRowClicked(args) {
      var gridObj = this.$refs.overviewgrid;
      if (args.cellIndex !== 0) {
        if (gridObj.getSelectedRows().length > 0) {
          gridObj.clearSelection();
        }
        this.$emit("rowClicked", args);
      }
    },
    applyTemplate(template) {
      if (template && template == "avancementOrderFormCustomerTemplate") {
        return this.avancementOrderFormCustomerTemplate;
      } else if (template && template == "activitiesTemplate") {
        return this.activitiesTemplate;
      } else if (template && template == "stripHtmlTemplate") {
        return this.stripHtmlTemplate;
      } else if (template && template == "stripTagsTemplate") {
        return this.stripTagsTemplate;
      } else if (template && template == "productTypeTemplate") {
        return this.productTypeTemplate;
      } else if (template && template == "paymentTypeTemplate") {
        return this.paymentTypeTemplate;
      } else if (template && template == "tagsTemplate") {
        return this.tagsTemplate;
      } else if (template && template == "defaultTemplate") {
        return this.defaultTemplate;
      } else if (template && template == "activeTemplate") {
        return this.activeTemplate;
      } else if (template && template == "booleanTemplate") {
        return this.booleanTemplate;
      } else if (template && template == "repartitionsTemplate") {
        return this.repartitionsTemplate;
      } else if (template && template == "affairTemplate") {
        return this.affairTemplate;
      } else if (template && template == "missionTemplate") {
        return this.missionTemplate;
      } else if (template && template == "affairTemplate") {
        return this.affairTemplate;
      } else if (template && template == "collaboratorTemplate") {
        return this.collaboratorTemplate;
      } else if (template && template == "discountGlobalTemplate") {
        return this.discountGlobalTemplate;
      } else if (template && template == "discountGlobalTypeTemplate") {
        return this.discountGlobalTypeTemplate;
      } else if (template && template == "collaboratorsTemplate") {
        return this.collaboratorsTemplate;
      } else if (template && template == "collaboratorDisplayLabelTemplate") {
        return this.collaboratorDisplayLabelTemplate;
      } else if (template && template == "companyTemplate") {
        return this.companyTemplate;
      } else if (template && template == "payrollEventTemplate") {
        return this.payrollEventTemplate;
      } else if (template && template == "colorTemplate") {
        return this.colorTemplate;
      } else if (template && template == "civilityTemplate") {
        return this.civilityTemplate;
      } else if (template && template == "contactTemplate") {
        return this.contactTemplate;
      } else if (template && template == "statusTemplate") {
        return this.statusTemplate;
      } else if (template && template == "origineTemplate") {
        return this.origineTemplate;
      } else if (template && template == "dateTemplate") {
        return this.dateTemplate;
      } else if (template && template == "dateYmdTemplate") {
        return this.dateYmdTemplate;
      } else if (template && template == "heureHmTemplate") {
        return this.heureHmTemplate;
      } else if (template && template == "dateYmdHmTemplate") {
        return this.dateYmdHmTemplate;
      } else if (template && template == "monthTemplate") {
        return this.monthTemplate;
      } else if (template && template == "currencyTemplate") {
        return this.currencyTemplate;
      } else if (template && template == "tvaTemplate") {
        return this.tvaTemplate;
      } else if (template && template == "tvaProviderTemplate") {
        return this.tvaProviderTemplate;
      } else if (template && template == "typeTemplate") {
        return this.typeTemplate;
      } else if (template && template == "quantityTemplate") {
        return this.quantityTemplate;
      } else if (template && template == "sourceTypeTemplate") {
        return this.sourceTypeTemplate;
      } else if (template && template == "directionTypeTemplate") {
        return this.directionTypeTemplate;
      } else if (template && template == "typeFieldTemplate") {
        return this.typeFieldTemplate;
      } else if (template && template == "statisticsPeriodTemplate") {
        return this.statisticsPeriodTemplate;
      } else if (template && template == "natureTemplate") {
        return this.natureTemplate;
      } else if (template && template == "orderFormTemplate") {
        return this.orderFormTemplate;
      } else if (template && template == "customFieldTemplate") {
        return this.customFieldTemplate;
      } else if (template && template == "deleteTemplate") {
        return this.deleteTemplate;
      } else if (template && template == "parentTemplate") {
        return this.parentTemplate;
      } else if (template && template == "documentReferenceTemplate") {
        return this.documentReferenceTemplate;
      } else if (template && template == "contractNatureTemplate") {
        return this.contractNatureTemplate;
      } else if (template && template == "contractStatusTemplate") {
        return this.contractStatusTemplate;
      } else if (template && template == "affairCodeTemplate") {
        return this.affairCodeTemplate;
      } else if (template && template == "moduleTemplate") {
        return this.moduleTemplate;
      } else if (template && template == "affairZoneTemplate") {
        return this.affairZoneTemplate;
      } else {
        return null;
      }
    },
    applyFilterTemplate(filterTemplate) {
      if (filterTemplate && filterTemplate == "collaboratorsFilterTemplate") {
        return this.filterTemplateCustomise("collaboratorIds");
      } else if (
        filterTemplate &&
        filterTemplate == "collaboratorFilterTemplate"
      ) {
        return this.filterTemplateCustomise("collaboratorId");
      } else if (filterTemplate && filterTemplate == "contactFilterTemplate") {
        return this.filterTemplateCustomise("contactId");
      } else if (filterTemplate && filterTemplate == "companyFilterTemplate") {
        return this.filterTemplateCustomise("companyId", "name");
      } else if (
        filterTemplate &&
        filterTemplate == "payrollEventFilterTemplate"
      ) {
        return this.filterTemplateCustomise("payrollEvent.id", "label");
      } else if (filterTemplate && filterTemplate == "affairFilterTemplate") {
        return this.filterTemplateCustomise("affairId");
      } else if (filterTemplate && filterTemplate == "missionFilterTemplate") {
        return this.filterTemplateCustomise("missionId", "label");
      } else if (filterTemplate && filterTemplate == "userFilterTemplate") {
        return this.filterTemplateCustomise("userId");
      } else if (
        filterTemplate &&
        filterTemplate == "productTypeFilterTemplate"
      ) {
        return this.filterTemplateCustomise("productType");
      } else if (filterTemplate && filterTemplate == "natureFilterTemplate") {
        return this.filterTemplateCustomise("nature");
      } else if (
        filterTemplate &&
        filterTemplate == "paymentTypeFilterTemplate"
      ) {
        return this.filterTemplateCustomise("paymentType");
      } else if (
        filterTemplate &&
        filterTemplate == "deliveryTypeFilterTemplate"
      ) {
        return this.filterTemplateCustomise("deliveryType");
      } else if (
        filterTemplate &&
        filterTemplate == "directionTypeFilterTemplate"
      ) {
        return this.filterTemplateCustomise("directionType", "label");
      } else if (filterTemplate && filterTemplate == "statusFilterTemplate") {
        return this.filterTemplateCustomise("status");
      } else if (
        filterTemplate &&
        filterTemplate == "activitySourceFilterTemplate"
      ) {
        return this.filterTemplateCustomise("activitySourceTypeId", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "contractNatureFilterTemplate"
      ) {
        return this.filterTemplateCustomise("contractNature", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "contractStatusFilterTemplate"
      ) {
        return this.filterTemplateCustomise("contractStatus", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "activityChanelFilterTemplate"
      ) {
        return this.filterTemplateCustomise("activityChanelTypeId", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "activityResultFilterTemplate"
      ) {
        return this.filterTemplateCustomise("activityResultTypeId", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "companyTypeFilterTemplate"
      ) {
        return this.filterTemplateCustomise("companyTypeId", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "typeFieldFilterTemplate"
      ) {
        return this.filterTemplateCustomise("typeField");
      } else if (
        filterTemplate &&
        filterTemplate == "quantificationFilterTemplate"
      ) {
        return this.filterTemplateCustomise("quantificationType", "label");
      } else if (filterTemplate && filterTemplate == "unitFilterTemplate") {
        return this.filterTemplateCustomise("unitId", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "sourceTypeFilterTemplate"
      ) {
        return this.filterTemplateCustomise("sourceType", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "documentApplicationTypeFilterTemplate"
      ) {
        return this.filterTemplateCustomise("documentApplicationType", "label");
      } else if (
        filterTemplate &&
        filterTemplate == "creditProviderStatusFilterTemplate"
      ) {
        return this.filterTemplateCustomise("creditProviderStatus");
      } else if (
        filterTemplate &&
        filterTemplate == "invoiceProviderStatusFilterTemplate"
      ) {
        return this.filterTemplateCustomise("invoiceProviderStatus");
      } else if (filterTemplate && filterTemplate == "civilityFilterTemplate") {
        return this.filterTemplateCustomise("civility");
      } else if (
        filterTemplate &&
        filterTemplate == "statisticsPeriodsFilterTemplate"
      ) {
        return this.filterTemplateCustomise("statisticsPeriod");
      } else if (
        filterTemplate &&
        filterTemplate == "haveOrderFormFilterTemplate"
      ) {
        return this.filterTemplateCustomise("boolean");
      } else if (
        filterTemplate &&
        filterTemplate == "haveParentFilterTemplate"
      ) {
        return this.filterTemplateCustomise("boolean");
      } else if (
        filterTemplate &&
        filterTemplate == "activityDateFilterTemplate"
      ) {
        return this.filterTemplateCustomise("activityDate");
      } else if (
        filterTemplate &&
        filterTemplate == "affairCodeFilterTemplate"
      ) {
        return this.filterTemplateCustomise("affairCode", "code");
      }
    },
    filterTemplateCustomise(columnLabel, fieldsText) {
      let _this = this;
      let dataFiltered = [];
      return {
        ui: {
          create: function (args) {
            args.getOptrInstance.dropOptr.element.parentElement.parentElement.style.display =
              "none";
            let flValInput = document.createElement("input", {
              className: "flm-input",
            });
            let filterData = _this.dataExcelTemplate(columnLabel);
            let isFiltered = event.target.classList.contains("e-filtered");
            _this.multiSelect = new MultiSelect({
              placeholder: "Selectionner...",
              dataSource: filterData,
              fields: {
                text: fieldsText ? fieldsText : "displayLabel",
                value: "id",
              },
              value: isFiltered ? dataFiltered : null,
              mode: "CheckBox",
              showSelectAll: "true",
              selectAllText: "Tous",
              unSelectAllText: "Aucun",
              change: function (e) {
                if (
                  args.column.field === "orderFormCustomerId" ||
                  args.column.field === "parentId"
                ) {
                  if (e.value.length == 1) {
                    dataFiltered = e.value;
                    _this.$refs.overviewgrid.clearFiltering([
                      args.column.field,
                    ]);
                    _this.$refs.overviewgrid.filterByColumn(
                      args.column.field,
                      e.value[0] === 1 ? "isnotnull" : "isnull",
                      e.value[0]
                    );
                  } else {
                    _this.$refs.overviewgrid.clearFiltering([
                      args.column.field,
                    ]);
                  }
                } else if (args.column.field === "collaboratorIds") {
                  if (e.value.length > 0) {
                    dataFiltered = e.value;
                    _this.$refs.overviewgrid.clearFiltering([
                      args.column.field,
                    ]);
                    _this.$refs.overviewgrid.filterByColumn(
                      args.column.field,
                      "contains",
                      e.value
                    );
                  } else {
                    _this.$refs.overviewgrid.clearFiltering([
                      args.column.field,
                    ]);
                  }
                } else {
                  if (e.value.length > 0) {
                    dataFiltered = e.value;
                    _this.$refs.overviewgrid.clearFiltering([
                      args.column.field,
                    ]);
                    _this.$refs.overviewgrid.filterByColumn(
                      args.column.field,
                      "equal",
                      e.value
                    );
                  } else {
                    _this.$refs.overviewgrid.clearFiltering([
                      args.column.field,
                    ]);
                  }
                }
              },
            });
            args.target.appendChild(flValInput);
            _this.multiSelect.appendTo(flValInput);
          },
          write: function (args) {},
          read: function (args) {},
        },
      };
    },
    applyFormat(format) {
      if (format && format == "formatDateTimeOptions") {
        return { type: "date", format: "dd/MM/yyyy HH:mm" };
      } else if (format && format == "formatDateOptions") {
        return { type: "date", format: "dd/MM/yyyy" };
      } else if (format && format == "formatDateOptionsStr") {
        return { type: "date", format: "C dd M yyyy" };
      } else if (format && format == "formatCurrency") {
        return { format: "C2", currency: "EUR" };
      } else if (format && format == "formatNumber") {
        return { type: "number" };
      } else {
        return format;
      }
    },
    applyEdit(editType, options, index) {
      let _this = this;
      if (!editType && options && options.type == "disabled") {
        let element;
        //return this.applyTemplate('statusTemplate')

        return {
          create: function () {
            element = document.createElement("div");
            return element;
          },
          destroy: function () {
            element.remove();
          },
          read: function () {
            return element.innerHTML;
          },
          write: function (args) {
            element.innerHTML = ""; //args.rowData[args.column.field]
          },
        };
      } else if (!editType && options && options.type == "monthpicker") {
        // let edit = {
        //   params:   {
        //     start: 'Year',
        //     depth: 'Year',
        //     format: 'MMMM y'
        //   }
        // }
        // return edit;
        let element;
        let monthpickerobj;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            monthpickerobj.destroy();
          },
          read: function () {
            return monthpickerobj.value;
          },
          write: function (args) {
            monthpickerobj = new DatePicker({
              value: args.rowData[args.column.field],
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              placeholder: "Sélectionnez un mois",
              showClearButton: false,
              start: "Year",
              depth: "Year",
              format: "MMMM y",
            });
            monthpickerobj.appendTo(element);
          },
        };
      } else if (!editType && options && options.type == "datepicker") {
        let element;
        let datepickerobj;

        return {
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            datepickerobj.destroy();
          },
          read: function () {
            return datepickerobj.value;
          },
          write: function (args) {
            datepickerobj = new DatePicker({
              value: new Date(args.rowData[args.column.field]),
              showClearButton: false,
              format: "dd/MM/yyyy",
              change: function (params) {
                if (args.column.field == "reportDate") {
                  //Set the date of the reportDate field but keep hours of startDate
                  _this["reportStartDate"].value = new Date(
                    dayjs(_this["reportStartDate"].value).set(
                      "date",
                      params.value.getDate()
                    )
                  );
                  _this["reportEndDate"].value = new Date(
                    dayjs(_this["reportEndDate"].value).set(
                      "date",
                      params.value.getDate()
                    )
                  );
                }
              },
            });
            datepickerobj.appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.type == "datetime" &&
        options.name
      ) {
        let element;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            _this[options.name] = new DateTimePicker({
              value: args.rowData[args.column.field],
              start: "day",
              depth: "day",
              format: "dddd MMMM yyyy HH:mm",
              enabled: _this.checkCollaboratorModeForDates(args),
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (!editType && options && options.type == "time") {
        let element;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            _this[options.name] = new TimePicker({
              start: "day",
              depth: "day",
              format: "HH:mm",
              openOnFocus: true,
              placeholder: "Heure",
              showClearButton: false,
              value: args.rowData[args.column.field],
              cssClass: "numeric-align-center",
              enabled: _this.checkCollaboratorModeForDates(args),
              change: (args) => {
                if (
                  options.name == "reportStartDate" ||
                  options.name == "reportEndDate"
                ) {
                  if (
                    _this["reportStartDate"].value >
                    _this["reportEndDate"].value
                  ) {
                    _this["reportEndDate"].value = new Date(
                      dayjs(_this["reportEndDate"].value).add(1, "day")
                    );
                  } else if (
                    dayjs(_this["reportEndDate"].value).diff(
                      dayjs(_this["reportStartDate"].value),
                      "minutes"
                    ) > 1440
                  ) {
                    _this["reportEndDate"].value = new Date(
                      dayjs(_this["reportEndDate"].value).subtract(1, "day")
                    );
                  }

                  let diffMinutes = dayjs(_this["reportEndDate"].value).diff(
                    dayjs(_this["reportStartDate"].value),
                    "minutes"
                  );
                  let decimalHours = (diffMinutes / 60).toFixed(2);
                  _this["numericTextBoxDuration"].value = decimalHours;
                  _this["numericTextBoxDurationValue"].value = decimalHours;
                }
              },
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (!editType && options && options.type == "datetime") {
        let element;
        let datetimeobj;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            datetimeobj.destroy();
          },
          read: function () {
            return datetimeobj.value;
          },
          write: function (args) {
            datetimeobj = new DateTimePicker({
              value: args.rowData[args.column.field],
              start: "day",
              depth: "day",
              format: "dddd MMMM yyyy HH:mm",
              enabled: _this.checkCollaboratorModeForDates(args),
            });
            datetimeobj.appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.type &&
        options.type == "numericTextBox"
      ) {
        let element;
        return {
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            // _this[options.name] = new NumericTextBox({
            //   value: args.rowData[args.column.field],
            //   query: new Query(),
            //   fields: options.fields,
            //   enabled:
            //     options.name == "numericTextBoxDuration"
            //       ? _this.checkCollaboratorMode(args)
            //       : true,
            //   decimals:
            //     options.params && options.params.decimals
            //       ? options.params.decimals
            //       : 2,
            //   min:
            //     options.params && options.params.min ? options.params.min : 0,
            //   max:
            //     options.params && options.params.max
            //       ? options.params.max
            //       : 99999999999,
            //   showSpinButton:
            //     options.params && options.params.showSpinButton
            //       ? options.params.showSpinButton
            //       : false,
            //   placeholder:
            //     options.name == "numericTextBoxDuration"
            //       ? "Durée"
            //       : options.name == "numericTextBoxDuration"
            //       ? "Durée valorisée"
            //       : "",
            //   format:
            //     options.params && options.params.format
            //       ? options.params.format
            //       : "##.##",
            //   step:
            //     options.params && options.params.step
            //       ? options.params.step
            //       : 0.25,
            //   cssClass: "numeric-align-center",
            //   floatLabelType:
            //     _this.editSettings && _this.editSettings.mode == "Dialog"
            //       ? "Always"
            //       : "Never",
            //   change: (args) => {
            //     if (
            //       options.target &&
            //       options.target == "numericTextBoxDurationValue" &&
            //       _this[options.target]
            //     ) {
            //       _this[options.target].value = args.value;
            //     }
            //   },
            // });
            _this[options.name] = new TextBox({
              value: args.rowData[args.column.field],
              query: new Query(),
              fields: options.fields,
              enabled:
                options.name == "numericTextBoxDuration"
                  ? _this.checkCollaboratorMode(args)
                  : true,
              decimals:
                options.params && options.params.decimals
                  ? options.params.decimals
                  : 2,
              showSpinButton:
                options.params && options.params.showSpinButton
                  ? options.params.showSpinButton
                  : false,
              placeholder:
                options.name == "numericTextBoxDuration"
                  ? "Durée"
                  : options.name == "numericTextBoxDuration"
                  ? "Durée valorisée"
                  : "",
              format:
                options.params && options.params.format
                  ? options.params.format
                  : "##.##",
              cssClass: "numeric-align-center",
              htmlAttributes: {
                type: "number",
                step:
                  options.params && options.params.step
                    ? options.params.step
                    : 0.25,
                min:
                  options.params && options.params.min ? options.params.min : 0,
                max:
                  options.params && options.params.max
                    ? options.params.max
                    : 99999999999,
              },
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              input: (args) => {
                if (
                  options.target &&
                  options.target == "numericTextBoxDurationValue" &&
                  _this[options.target]
                ) {
                  _this[options.target].value = args.value;
                }
              },
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.type &&
        options.type == "textarea"
      ) {
        let element;
        let textareaobj;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            textareaobj.destroy();
          },
          read: function () {
            return textareaobj.value;
          },
          write: function (args) {
            textareaobj = new TextBox({
              value: args.rowData[args.column.field],
              floatLabelType: "Always",
              placeholder: args.column.headerText,
              multiline: true,
            });
            textareaobj.appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.source &&
        options.type &&
        options.type == "dropdowntree"
      ) {
        let element;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            if (args.rowData[args.column.field]) {
              args.rowData[args.column.field] = [
                args.rowData[args.column.field].toString(),
              ];
            } else {
              args.rowData[args.column.field] = [];
            }
            _this[options.name] = new DropDownTree({
              dataSource: options.source,
              value: args.rowData[args.column.field],
              query: new Query(),
              allowFiltering: true,
              filterType: "Contains",
              sortOrder: "Ascending",
              fields: options.fields,
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              placeholder: args.column.headerText,
              enabled: _this.checkViewEditReportValued(args, options)
                ? false
                : true,
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.source &&
        options.type &&
        options.type == "dropdowntreedependencelabel"
      ) {
        let element;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            if (args.rowData[args.column.field]) {
              args.rowData[args.column.field] = [
                args.rowData[args.column.field].toString(),
              ];
            } else {
              args.rowData[args.column.field] = [];
            }
            _this[options.name] = new DropDownTree({
              dataSource: options.source,
              allowFiltering: true,
              filterType: "Contains",
              sortOrder: "Ascending",
              value: args.rowData[args.column.field],
              query: new Query(),
              fields: options.fields,
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              placeholder: args.column.headerText,
              change: (args) => {
                if (args.value && args.value.length > 0) {
                  _this["textboxlabel"].value = options.source.find(
                    (el) => el.id == args.value.toString()
                  ).label;
                  _this["textboxlabel"].enabled = false;
                  _this["dropdowntreeanalytics"].value = [
                    options.source.find((el) => el.id == args.value.toString())
                      .analyticsSettingId,
                  ];
                  _this["dropdowntreeanalytics"].enabled = false;
                } else {
                  _this["textboxlabel"].value = "";
                  _this["textboxlabel"].enabled = true;
                  _this["dropdowntreeanalytics"].value = [];
                  _this["dropdowntreeanalytics"].enabled = true;
                }
              },
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.type &&
        options.type == "textbox"
      ) {
        let element;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            // if(args.rowData[args.column.field] ){
            //     args.rowData[args.column.field] = args.rowData[args.column.field].toString()
            // }else{
            //     args.rowData[args.column.field] = null
            // }
            _this[options.name] = new TextBox({
              value: args.rowData[args.column.field],
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              placeholder: args.column.headerText,
              multiline: false,
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.source &&
        options.type &&
        options.type == "dropdowndependencies"
      ) {
        let element;
        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            _this[options.name] = new DropDownList({
              dataSource:
                options.origine && options.name == "dropdowncontact"
                  ? _this[options.origine].itemData.contacts
                    ? _this[options.origine].itemData.contacts
                    : []
                  : options.origine && options.name == "dropdownaffair"
                  ? _this.affairsList.filter(
                      (el) =>
                        el.company?.id == _this[options.origine].itemData?.id
                    )
                  : options.source,
              allowFiltering: true,
              filterType: "Contains",
              sortOrder: "Ascending",
              value: args.rowData[args.column.field],
              query: new Query(),
              fields: options.fields,
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              enabled:
                !options.target &&
                options.origine &&
                !_this[options.origine].value
                  ? false
                  : _this.checkViewEditReportValued(args, options)
                  ? false
                  : true,
              placeholder: args.column.headerText,
              change: (args) => {
                if (options.target && options.target == "dropdowncontact") {
                  _this[options.target].enabled = true;
                  _this[options.target].dataSource = args.itemData.contacts
                    ? args.itemData.contacts
                    : [];
                } else if (
                  options.target &&
                  options.target == "dropdownaffair"
                ) {
                  _this[options.target].enabled = true;
                  _this[options.target].dataSource = args.itemData?.id
                    ? _this.affairsList.filter(
                        (el) => el.company?.id == args.itemData?.id
                      )
                    : [];
                } else if (
                  options.target &&
                  options.target === "dropdownmission"
                ) {
                  _this[options.target].enabled = true;
                  _this[options.target].dataSource = args.itemData?.id
                    ? _this.missionsListFiltered.filter(
                        (mission) =>
                          mission.affairId === args.itemData.id ||
                          mission.affairId === null
                      )
                    : [];
                } else if (
                  options.target &&
                  options.target === "documentApplicationType"
                ) {
                  _this[options.target].dataSource = args.itemData?.id
                    ? documentApplicationType.filter((source) =>
                        source.typeDependant.includes(args.value)
                      )
                    : [];
                } else if (options.target) {
                  _this[options.target].enabled = true;
                  _this[options.target].text = null;
                  _this[options.target].dataBind();
                }
              },
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.source &&
        options.type &&
        options.type == "dropdown"
      ) {
        let element;
        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            // _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            _this[options.name] = new DropDownList({
              dataSource:
                options.name == "documentApplicationType"
                  ? documentApplicationType.filter((source) =>
                      source.typeDependant.includes(args.rowData.directionType)
                    )
                  : options.source,
              allowFiltering: true,
              filterType: "Contains",
              sortOrder: "Ascending",
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              value: args.rowData[args.column.field],
              query: new Query(),
              fields: options.fields,
              placeholder: args.column.headerText,
              change: (args) => {
                if (options.name == "reportDropdownCollaboratorsList") {
                  if (args.itemData.reportEntryMode) {
                    _this["reportEndDate"].enabled = true;
                    _this["reportStartDate"].enabled = true;
                    _this["numericTextBoxDuration"].enabled = false;
                    _this["numericTextBoxDurationValue"].enabled = true;
                  } else {
                    _this["reportEndDate"].enabled = false;
                    _this["reportStartDate"].enabled = false;
                    _this["numericTextBoxDuration"].enabled = true;
                    _this["numericTextBoxDurationValue"].enabled = true;
                  }
                } else if (options.name == "dropdownPayrollEventsList") {
                  if (args.itemData.isValued) {
                    _this["dropdownaffair"].enabled = true;
                    _this["dropdownmission"].enabled = true;
                  } else {
                    _this["dropdownaffair"].enabled = false;
                    _this["dropdownmission"].enabled = false;
                    _this["dropdownaffair"].value = null;
                    _this["dropdownmission"].value = null;
                  }
                }
              },
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.source &&
        options.type &&
        options.type == "dropdownmultiple"
      ) {
        let element;

        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            element = document.createElement("input");
            return element;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function () {
            return _this[options.name].value;
          },
          write: function (args) {
            _this[options.name] = new MultiSelect({
              closePopupOnSelect: false,
              dataSource: options.source,
              allowFiltering: true,
              filterType: "Contains",
              sortOrder: "Ascending",
              value: args.rowData[args.column.field],
              query: new Query(),
              fields: options.fields,
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              placeholder: args.column.headerText,
              change: (args) => {},
            });
            _this[options.name].appendTo(element);
          },
        };
      } else if (
        !editType &&
        options &&
        options.source &&
        options.type &&
        options.type == "autocomplete"
      ) {
        let inpuEle;
        return {
          dataSource: options.source,
          fields: options.fields,
          create: function () {
            inpuEle = document.createElement("input");
            return inpuEle;
          },
          destroy: function () {
            _this[options.name].destroy();
          },
          read: function (args) {
            return _this[options.name].value;
          },
          write: function (args) {
            _this[options.name] = new AutoComplete({
              allowCustom: true,
              dataSource: options.source,
              value: args.rowData[args.column.field],
              query: new Query(),
              actionComplete: () => false,
              fields: options.fields,
              floatLabelType:
                _this.editSettings && _this.editSettings.mode == "Dialog"
                  ? "Always"
                  : "Never",
              placeholder: args.column.headerText,
              itemTemplate:
                options.origine && options.origine == "companyContacts"
                  ? _this.autocompleteContactTemplate
                  : null,
              change: function (args) {
                if (options.origine && options.origine == "companyContacts") {
                  if (
                    (!args.value || args.value == "") &&
                    args.e &&
                    args.e.target &&
                    args.e.target.className ==
                      "e-clear-icon e-clear-icon-hide" &&
                    args.previousItemData &&
                    args.previousItemData.id > 0
                  ) {
                    if (options.name !== "contactCivility") {
                      _this["contactCivility"].value = "";
                    }
                    if (options.name !== "contactLastName") {
                      _this["contactLastName"].value = "";
                    }
                    if (options.name !== "contactFirstName") {
                      _this["contactFirstName"].value = "";
                    }
                    if (options.name !== "contactPhoneNumber") {
                      _this["contactPhoneNumber"].clearAll();
                    }
                    if (options.name !== "contactSecondaryPhoneNumber") {
                      _this["contactSecondaryPhoneNumber"].value = "";
                    }
                    if (options.name !== "contactEmail") {
                      _this["contactEmail"].value = "";
                    }
                    if (options.name !== "contactId") {
                      _this["contactId"].value = 0;
                    }
                    args.value = "";
                  }
                }
              },
              select: function (selectedItem) {
                if (options.origine && options.origine == "companyContacts") {
                  _this["contactCivility"].value =
                    selectedItem.itemData.civility;
                  _this["contactLastName"].value =
                    selectedItem.itemData.lastName;
                  _this["contactFirstName"].value =
                    selectedItem.itemData.firstName;
                  _this["contactPhoneNumber"].value =
                    selectedItem.itemData.phoneNumber;
                  _this["contactSecondaryPhoneNumber"].value =
                    selectedItem.itemData.secondaryPhoneNumber;
                  _this["contactEmail"].value = selectedItem.itemData.email;
                  _this["contactId"].value = selectedItem.itemData.id;
                }
                return true;
              },
            });
            _this[options.name].appendTo(inpuEle);
          },
        };
      } else if (editType == "numericedit") {
        return options;
      } else if (
        !editType &&
        options &&
        options.type &&
        options.type == "colorpicker"
      ) {
        let colorPickerObj;
        let elem;
        return {
          create: function () {
            elem = document.createElement("input");
            return elem;
          },
          destroy: function () {
            colorPickerObj.destroy();
          },
          read: function () {
            return colorPickerObj.value;
          },
          write: function (args) {
            colorPickerObj = new ColorPicker({
              value: args.rowData[args.column.field],
            });
            colorPickerObj.appendTo(elem);
          },
        };
      } else {
        return null;
      }
    },
    fullNameCollaborator(field, data, column) {
      return data["collaborator"].displayLabel;
    },
    booleanStandardFormat(field, data, column) {
      return data[field] ? "Oui" : "Non";
    },
    dropdownFormatter(field, data, column) {
      if (field && field.includes(".")) {
        const fieldSplit = field.split(".");
        return column.edit.params &&
          column.edit.params.dataSource.find(
            (el) => el.id == data[fieldSplit[0]][fieldSplit[1]]
          )
          ? column.edit.params.dataSource.find(
              (el) => el.id == data[fieldSplit[0]][fieldSplit[1]]
            )[column.edit.params.fields.text]
          : data[fieldSplit[0]][fieldSplit[1]] &&
            column.edit.dataSource.find(
              (el) => el.id == data[fieldSplit[0]][fieldSplit[1]]
            )
          ? column.edit.dataSource.find(
              (el) => el.id == data[fieldSplit[0]][fieldSplit[1]]
            )[column.edit.fields.text]
          : "--";
      } else {
        return column.edit.params &&
          column.edit.params.dataSource &&
          column.edit.params.dataSource.find((el) => el.id == data[field]) &&
          column.edit.dataSource.find((el) => el.id == data[field])[
            column.edit.fields.text
          ]
          ? column.edit.params.dataSource.find((el) => el.id == data[field])[
              column.edit.params.fields.text
            ]
          : data[field] &&
            column.edit.dataSource &&
            column.edit.dataSource.find((el) => el.id == data[field]) &&
            column.edit.dataSource.find((el) => el.id == data[field])[
              column.edit.fields.text
            ]
          ? column.edit.dataSource.find((el) => el.id == data[field])[
              column.edit.fields.text
            ]
          : "--";
      }
    },
    applyFormatter(formatter) {
      if (formatter && formatter == "quantity") {
        return this.quantityFormatter;
      } else if (formatter && formatter == "currency") {
        return this.currencyFormatter;
      } else if (formatter && formatter == "marginCosting") {
        return this.marginCostingFormatter;
      } else if (formatter && formatter == "percent") {
        return this.percentFormatter;
      } else if (formatter && formatter == "situationRemainingPercent") {
        return this.situationRemainingPercent;
      } else if (formatter && formatter == "situationRemainingQuantity") {
        return this.situationRemainingQuantity;
      } else if (formatter && formatter == "situationProgressQuantity") {
        return this.situationProgressQuantity;
      } else if (formatter && formatter == "totalPriceHT") {
        return this.totalPriceHT;
      } else if (formatter && formatter == "situationProgressAmount") {
        return this.situationProgressAmount;
      } else if (formatter && formatter == "situationProgressPercent") {
        return this.situationProgressPercent;
      } else if (formatter && formatter == "situationRemainingAmount") {
        return this.situationRemainingAmount;
      } else if (
        formatter &&
        formatter == "sumAffairCostingCurrencyFormatter"
      ) {
        return this.sumAffairCostingCurrencyFormatter;
      } else if (formatter && formatter == "user") {
        return this.userFormatter;
      } else if (formatter && formatter == "companyCustomerAddress") {
        return this.companyCustomerAddressFormatter;
      } else if (formatter && formatter == "dropdown") {
        return this.dropdownFormatter;
      } else if (formatter && formatter == "fullNameCollaborator") {
        return this.fullNameCollaborator;
      } else if (formatter && formatter == "boolean") {
        return this.booleanStandardFormat;
      } else if (formatter && formatter == "unitPeriodicity") {
        return this.unitPeriodicityFormatter;
      } else if (formatter && formatter == "discountPercent") {
        return this.discountPercentFormatter;
      } else {
        return formatter;
      }
    },
    onFilterTextBoxChanged(input) {
      this.$refs.overviewgrid.search(input);
    },
    rowSelectedGrid() {
      this.$emit(
        "setSelectedRows",
        this.$refs.overviewgrid.getSelectedRecords()
      );
      this.$emit(
        "setSelectedRowsIndexes",
        this.$refs.overviewgrid.getSelectedRowIndexes()
      );
    },
    setTagsSelected(val) {
      this.$emit("setTagsSelected", val);
    },
    exportData(type) {
      let selectedRecords = this.$refs.overviewgrid.getSelectedRecords();
      let exportProperties = {
        dataSource:
          selectedRecords.length > 0 ? selectedRecords : this.gridData,
        enableFilter: true,
      };
      let columns = this.$refs.overviewgrid.getColumns();
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        if (column.unavailableExport)
          this.$refs.overviewgrid.getColumns()[i].visible = false;
        else if (column.availableExport) {
          this.$refs.overviewgrid.getColumns()[i].visible = true;
        }
      }
      if (type == "csv") {
        this.$refs.overviewgrid.csvExport(exportProperties);
      } else if (type == "xls") {
        this.$refs.overviewgrid.excelExport(exportProperties);
      } else if (type == "print") {
        this.$refs.overviewgrid.print(exportProperties);
      }
    },
    exportComplete(args) {
      let columns = this.$refs.overviewgrid.getColumns();
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        if (column.unavailableExport)
          this.$refs.overviewgrid.getColumns()[i].visible = true;
      }
    },
    toolbarClick(args) {
      if (args.item.id === "overviewgrid-dashboard_pdfexport") {
        // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgrid.pdfExport();
      } else if (args.item.id === "overviewgrid-dashboard_csvexport") {
        // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgrid.csvExport();
      } else if (args.item.id === "overviewgrid-dashboard_excelexport") {
        this.$refs.overviewgrid.excelExport();
      }
    },
    groupNameFormat(value) {
      // TAGS
      if (value.field.includes("Tags") || value.field == "sourceType") {
        if (value.key >= 0) {
          return sourceTypes.find((el) => el.value == value.key);
        } else if (value.key.length) {
          return {
            ...value,
            key: value.key[0].label,
            color: value.key[0].color,
          };
        } else {
          return { ...value, key: "Aucun tag" };
        }
      }
      switch (value.field) {
        // DATE
        case "date":
        case "startDate":
        case "remittedBankDate":
        case "endDate":
        case "documentDate":
        case "receiptDate":
        case "movementDate":
        case "inventoryDate":
        case "estimatedDateReceipt":
        case "reminderDate":
        case "activityDate":
        case "signedDate":
        case "limiteDate":
          if (value.key) {
            if (value.headerText === "Mois" && value.field == "date") {
              return { ...value, key: dayjs(value.key).format("MMMM YYYY") };
            } else {
              return {
                ...value,
                key: dayjs(value.key).format("dddd D MMMM YYYY"),
              };
            }
          } else {
            return { ...value, key: "Aucune date" };
          }
        case "reportDate":
          if (value.key) {
            if (value.headerText === "Mois" && value.field == "date") {
              return { ...value, key: dayjs(value.key).format("MMMM YYYY") };
            } else {
              return {
                ...value,
                key: dayjs(value.key).format("dddd D MMMM YYYY"),
              };
            }
          } else {
            return { ...value, key: "Aucune date" };
          }

        // NATURE
        case "nature":
          return nature.find((el) => el.value == value.key).label;

        // PAYMENTMETHOD
        case "paymentMethod":
          if (value?.key >= 0) {
            return paymentMethods.find((el) => el.value == value.key).key;
          } else {
            return "Aucune méthode de paiement";
          }

        // PAYMENTTYPE
        case "paymentType":
          if (value?.key >= 0) {
            return paymentTypes.find((el) => el.value == value.key).label;
          } else {
            return "Aucun type de paiement";
          }

        // PRODUCTTYPE
        case "productType":
          if (value?.key >= 0) {
            return productLabelTypes.find((el) => el.value == value.key).label;
          } else {
            return "Aucun type";
          }

        // DELIVERYTYPE
        case "deliveryType":
          if (value?.key >= 0) {
            return deliveryFormTypes.find((el) => el.value == value.key).label;
          } else {
            return "Aucun type";
          }

        // USERID
        case "userId":
          if (value?.key >= 0) {
            return (
              this.usersList.find((user) => user.id == value.key).firstName +
              " " +
              this.usersList.find((user) => user.id == value.key).lastName
            );
          } else {
            return "Non assigné";
          }

        // COLLABORATORID
        case "collaboratorId":
          if (value?.key >= 0) {
            return this.collaboratorsList.find(
              (collaborator) => collaborator.id == value.key
            ).displayLabel;
          } else {
            return "Non assigné";
          }

        case "collaboratorIds":
          if (value?.key.length > 0) {
            return value.key
              .map((collaboratorId) => {
                return this.collaboratorsList.find(
                  (collaborator) => collaborator.id == collaboratorId
                ).displayLabel;
              })
              .join(", ");
          } else {
            return "Non assigné";
          }

        // COLLABORATOR
        case "collaborator":
          if (value?.key) {
            return value.key.displayLabel;
          } else {
            return "Non assigné";
          }
        // payrollEvent.id
        case "payrollEvent.id":
          if (value?.key >= 0) {
            return this.payrollEventsList.find((event) => event.id == value.key)
              .label;
          } else {
            return "Non assigné";
          }

        // SOURCE
        case "directionType":
          if (value?.key >= 0) {
            return directionTypes.find((el) => el.value == value.key)
              .displayLabel;
          } else {
            return "Aucun type";
          }

        // CANAL
        case "canal":
          if (value?.key >= 0) {
            return canals.find((el) => el.value == value.key).label;
          } else {
            return "Aucun canal";
          }

        // RESULTS
        case "activityResultTypeId":
          if (value?.key >= 0) {
            return this.activityResultTypesList.find((el) => el.id == value.key)
              .label;
          } else {
            return "Aucun résultat";
          }

        // COMPANY
        case "companyId":
          if (value?.key >= 0) {
            return this.companiesList.find((company) => company.id == value.key)
              .name;
          } else {
            return "Aucune société";
          }

        // INTERLOCUTEUR
        case "contactId":
          if (value?.key >= 0) {
            return (
              this.contactsList.find((contact) => contact.id == value.key)
                .firstName +
              " " +
              this.contactsList.find((contact) => contact.id == value.key)
                .lastName
            );
          } else {
            return "Non assigné";
          }

        // ACTIVITYSOURCE
        case "activitySourceTypeId":
          if (value?.key >= 0) {
            return this.activitySourceTypesList.find((el) => el.id == value.key)
              .label;
          } else {
            return "Aucune source";
          }

        // ACTIVITYCHANEL
        case "activityChanelTypeId":
          if (value?.key >= 0) {
            return this.activityChanelTypesList.find((el) => el.id == value.key)
              .label;
          } else {
            return "Aucune source";
          }

        // NRP
        case "isUnreachable":
          if (value?.key >= 0) {
            return value.key === true ? "Oui" : "Non";
          } else {
            return "Aucun résultat";
          }

        // MESSAGE
        case "hasLeavingMessage":
          if (value?.key >= 0) {
            return value.key === true ? "Oui" : "Non";
          } else {
            return "Aucun résultat";
          }

        // DIRECTION TYPE
        case "documentApplicationType":
          if (value?.key >= 0) {
            return documentApplicationType.find((el) => el.id == value.key)
              .label;
          } else {
            return "Aucune source";
          }

        // missionId
        case "missionId":
          if (
            value.key >= 0 &&
            this.missionsList.find((mission) => mission.value == value.key)
          ) {
            return this.missionsList.find(
              (mission) => mission.value == value.key
            ).label;
          } else if (
            value.key >= 0 &&
            this.missionsListFiltered.find((mission) => mission.id == value.key)
          ) {
            return this.missionsListFiltered.find(
              (mission) => mission.id == value.key
            ).label;
          } else {
            return "Non assigné";
          }

        // AFFAIRID
        case "affairId":
          if (value?.key >= 0) {
            return this.affairsList.find((affair) => affair.id == value.key)
              .name;
          } else {
            return "Aucune affaire";
          }

        // COMPANYID
        case "companyId":
          if (value?.key >= 0) {
            return this.companiesList.find((company) => company.id == value.key)
              .name;
          } else {
            return "Aucun client";
          }

        // CIVILITY
        case "civility":
          if (value.key) {
            return civilities.find((el) => el.value == value.key).label;
          } else {
            return "Non précisé";
          }

        // STATUS
        case "status":
          switch (this.name) {
            case "quote":
              return quoteStatus.find((el) => el.value == value.key);
            case "orderFormCustomer":
              return orderFormCustomerStatus.find(
                (el) => el.value == value.key
              );
            case "invoice":
              return invoiceStatus.find((el) => el.value == value.key);
            case "holdback":
              return holdbackStatus.find((el) => el.value == value.key);
            case "invoiceProvider":
              return invoiceProviderStatus.find(
                (el) => el.value == value.items[0].invoiceProviderStatus
              );
            case "creditProvider":
              return creditProviderStatus.find(
                (el) => el.value == value.items[0].creditProviderStatus
              );
            case "credit":
              return creditStatus.find((el) => el.value == value.key);
            case "orderFormProvider":
              return orderFormProviderStatus.find(
                (el) => el.value == value.key
              );
            case "report":
              return reportingStatus.find((el) => el.value == value.key);
            case "myReport":
              return reportingStatus.find((el) => el.value == value.key);
            case "receiptFormProvider":
              return receiptFormProviderStatus.find(
                (el) => el.value == value.key
              );
            case "deliveryForm":
              return deliveryFormStatus.find((el) => el.value == value.key);
            case "inventories":
              return inventoryFormStatus.find((el) => el.value == value.key);
            case "interventions":
              return interventionStatus.find((el) => el.value == value.key);
            case "sms":
              return smsStatus.find((el) => el.value == value.key);
            default:
              return { ...value, key: null };
          }

        default:
          if (value.key) {
            return value.key;
          } else {
            return "Aucun(e)";
          }
      }
    },
    checkViewEditReportValued(args, options) {
      if (
        (options.uid == "viewEditReportAffair" ||
          options.uid == "viewEditReportMission") &&
        args.rowData.payrollEventId
      ) {
        return !this.payrollEventsList.find(
          (el) => el.id == args.rowData.payrollEventId
        )?.isValued;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.e-headercell .e-filtered {
  background-color: #0c3571 !important;
  border-radius: 4px;

  &:before {
    color: #fff !important;
  }
}

.e-gridcontent .e-tooltip-wrap.e-griderror {
  display: none !important;
}

.e-gridpopup:has(> .e-content .e-sortdirect) {
  display: none !important;
}
</style>
