<template lang="pug">
div(class="d-flex align-items-center justify-content-start h-100 w-100")
    | {{ item }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  computed: {
    ...mapGetters(["contactsList"]),
  },
  mounted() {
    if (this.data.contactId) {
      let find = this.contactsList.find(
        (contact) => contact.id == this.data.contactId
      );
      if (find) {
        this.item = find.displayLabel;
      } else {
        this.item = "--";
      }
    } else {
      this.item = "--";
    }
  },
};
</script>
