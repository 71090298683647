<template lang="pug">
.d-flex.align-items-center.justify-content-center.h-100.w-100
  span.chip.justify-content-center
    span.point.big.m-0(:style="'background-color:'+item")
</template>

<script>
export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  beforeMount() {
    this.item = this.data.column
      ? this.data[this.data?.column.field]
      : this.data["color"];
  },
};
</script>
