<template lang="pug">
b-modal(ref='modal-tag' :id="'modal-tag-'+ origine" @shown="focusDefault" @cancel="closeModal" @close="closeModal" centered='centered' :title="(tag.id ? 'Modifier': 'Ajouter') + ` un tag`" @ok='create')
  div(v-if='isCreatingTag || isUpdatingTag || isArchivingTag || isDeletingTag')
    .text-center.flex-center
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement...
  validation-observer(v-else='' ref='formTag')
    b-row
      b-col.d-flex.mb-1(cols='12')
        b-form-group.pr-1(label='Libellé *' label-for='Libellé' style='flex:1')
          validation-provider(#default='{ errors }' name='Libellé' rules='required')
            b-form-input.not-autocomplete( ref="focusDefault" v-model='tag.label' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope' v-on:keyup.enter="create")
              small.text-danger {{ errors[0] }}
        b-form-group.d-flex.flex-column.align-content-center.align-items-center(label='Couleur' label-for='color')
          validation-provider(#default='{ errors }' name='Color')
            ejs-colorpicker(id="color" v-model="tag.color" mode="Palette")
  template(#modal-footer='{ ok, cancel }')
    .w-100
      b-button.mr-1.float-left(title="Supprimer" v-if='tag.id && archive' variant='outline-danger' @click='deleteTagLocal(tag.id, tag.label)')
        feather-icon(icon='TrashIcon')
      b-button.float-left(title="Archiver" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if='tag.id && !archive' variant='outline-danger' @click='archiveTagLocal(tag.id, tag.label)')
        feather-icon(icon='ArchiveIcon')
      b-button.float-left(title="Restaurer" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if='tag.id && archive' variant='outline-warning' @click='restoreTagLocal(tag.id, tag.label)')
        feather-icon(icon='ArrowDownIcon')
      b-button.float-right(variant='primary' @click='ok()') {{tag.id ? &apos;Modifier&apos;: &apos;Ajouter&apos;}}
      b-button.float-right.mr-1(variant='outline-primary' @click='cancel()') Annuler
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";

configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
    };
  },
  props: {
    origine: {
      type: String,
      default: "",
    },
    archive: {
      type: Boolean,
      default: false,
    },
    nature: {
      type: String,
      required: true,
    },
    companyTypeId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    closeModal(tag = null) {
      this.$emit("refreshGrid", tag);
      this.$bvModal.hide("modal-tag-" + this.origine);
    },
    focusDefault() {
      this.$refs.focusDefault.focus();
    },
    popupTagEvent(id) {
      if (id > 0) {
        this.getFunctions(id);
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.tag = {
        label: null,
        color: "#0c3571",
      };
    },
    async create(bvModalEvt) {
      bvModalEvt.preventDefault();
      await this.$refs.formTag.validate().then(async (success1) => {
        if (success1) {
          if (this.tag.id) {
            await this.updateFunctions(this.tag);
          } else {
            await this.createFunctions(this.tag);
          }
        }
      });
    },
    getFunctions(id) {
      if (this.archive) {
        switch (this.nature) {
          case "company":
            this.getCompanyTagArchivedById({ companyTagId: id });
            break;
          case "quote":
            this.getQuoteTagByIdArchived({ quoteTagId: id });
            break;
          case "invoice":
            this.getInvoiceTagArchivedById({ invoiceTagId: id });
            break;
          case "credit":
            this.getCreditTagArchivedById({ creditTagId: id });
            break;
          case "orderFormProvider":
            this.getOrderFormProviderTagArchivedById({
              orderFormProviderTagId: id,
            });
            break;
          case "deliveryForm":
            this.getDeliveryFormTagArchivedById({ deliveryFormTagId: id });
            break;
          case "receiptFormProvider":
            this.getReceiptFormProviderTagArchivedById({
              receiptFormProviderTagId: id,
            });
            break;
          case "invoiceProvider":
            this.getInvoiceProviderTagArchivedById({
              invoiceProviderTagId: id,
            });
            break;
          case "creditProvider":
            this.getCreditProviderTagArchivedById({ creditProviderTagId: id });
            break;
          case "affair":
            this.getAffairTagArchivedById({ affairTagId: id });
            break;
          case "contact":
            this.getContactTagArchivedById({ contactTagId: id });
            break;
          case "collaborator":
            this.getCollaboratorTagArchivedById({ collaboratorTagId: id });
            break;
          case "orderFormCustomer":
            this.getOrderFormCustomerTagByIdArchived({
              orderFormCustomerTagId: id,
            });
            break;
          default:
            break;
        }
      } else {
        switch (this.nature) {
          case "company":
            this.getCompanyTagById({ companyTagId: id });
            break;
          case "quote":
            this.getQuoteTagById({ quoteTagId: id });
            break;
          case "invoice":
            this.getInvoiceTagById({ invoiceTagId: id });
            break;
          case "credit":
            this.getCreditTagById({ creditTagId: id });
            break;
          case "orderFormProvider":
            this.getOrderFormProviderTagById({ orderFormProviderTagId: id });
            break;
          case "deliveryForm":
            this.getDeliveryFormTagById({ deliveryFormTagId: id });
            break;
          case "receiptFormProvider":
            this.getReceiptFormProviderTagById({
              receiptFormProviderTagId: id,
            });
            break;
          case "invoiceProvider":
            this.getInvoiceProviderTagById({ invoiceProviderTagId: id });
            break;
          case "creditProvider":
            this.getCreditProviderTagById({ creditProviderTagId: id });
            break;
          case "affair":
            this.getAffairTagById({ affairTagId: id });
            break;
          case "contact":
            this.getContactTagById({ contactTagId: id });
            break;
          case "collaborator":
            this.getCollaboratorTagById({ collaboratorTagId: id });
            break;
          case "orderFormCustomer":
            this.getOrderFormCustomerTagById({ orderFormCustomerTagId: id });
            break;
          default:
            break;
        }
      }
    },
    async createFunctions(tag) {
      switch (this.nature) {
        case "company":
          let tagInfos = { ...tag, companyTypeId: this.companyTypeId };
          await this.createCompanyTag({ tag: tagInfos }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "quote":
          await this.createQuoteTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "invoice":
          await this.createInvoiceTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "credit":
          await this.createCreditTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "orderFormProvider":
          this.createOrderFormProviderTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "deliveryForm":
          this.createDeliveryFormTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "receiptFormProvider":
          this.createReceiptFormProviderTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "invoiceProvider":
          await this.createInvoiceProviderTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "creditProvider":
          await this.createCreditProviderTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "affair":
          await this.createAffairTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "contact":
          await this.createContactTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "collaborator":
          await this.createCollaboratorTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        case "orderFormCustomer":
          await this.createOrderFormCustomerTag({ tag: tag }).then((res) => {
            this.closeModal(tag);
          });
          break;
        default:
          break;
      }
    },
    async updateFunctions(tag) {
      switch (this.nature) {
        case "company":
          let tagInfos = { ...tag, companyTypeId: this.companyTypeId };
          await this.updateCompanyTag({ tag: tagInfos }).then((res) => {
            this.closeModal();
          });
          break;
        case "quote":
          await this.updateQuoteTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "invoice":
          await this.updateInvoiceTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "credit":
          await this.updateCreditTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "orderFormProvider":
          await this.updateOrderFormProviderTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "deliveryForm":
          this.updateDeliveryFormTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "receiptFormProvider":
          this.updateReceiptFormProviderTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "invoiceProvider":
          await this.updateInvoiceProviderTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "creditProvider":
          await this.updateCreditProviderTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "affair":
          await this.updateAffairTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "contact":
          await this.updateContactTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "collaborator":
          await this.updateCollaboratorTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        case "orderFormCustomer":
          await this.updateOrderFormCustomerTag({ tag: tag }).then((res) => {
            this.closeModal();
          });
          break;
        default:
          break;
      }
    },
    archiveFunctions(id) {
      switch (this.nature) {
        case "company":
          this.archiveCompanyTags({ companyTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "quote":
          this.archiveQuoteTags({ quoteTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "invoice":
          this.archiveInvoiceTags({ invoiceTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "credit":
          this.archiveCreditTags({ creditTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "orderFormProvider":
          this.archiveOrderFormProviderTags({
            orderFormProviderTagIds: [id],
          }).then((res) => {
            this.closeModal();
          });
          break;
        case "deliveryForm":
          this.archiveDeliveryFormTags({ deliveryFormTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "receiptFormProvider":
          this.archiveReceiptFormProviderTags({
            receiptFormProviderTagIds: [id],
          }).then((res) => {
            this.closeModal();
          });
          break;
        case "invoiceProvider":
          this.archiveInvoiceProviderTags({ invoiceProviderTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "creditProvider":
          this.archiveCreditProviderTags({ creditProviderTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "affair":
          this.archiveAffairTags({ affairTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "contact":
          this.archiveContactTags({ contactTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "collaborator":
          this.archiveCollaboratorTags({ collaboratorTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "orderFormCustomer":
          this.archiveOrderFormCustomerTags({
            orderFormCustomerTagIds: [id],
          }).then((res) => {
            this.closeModal();
          });
          break;
        default:
          break;
      }
    },
    restoreFunctions(id) {
      switch (this.nature) {
        case "company":
          this.restoreCompanyTags({ companyTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "quote":
          this.restoreQuoteTags({ quoteTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "invoice":
          this.restoreInvoiceTags({ invoiceTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "credit":
          this.restoreCreditTags({ creditTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "orderFormProvider":
          this.restoreOrderFormProviderTags({
            orderFormProviderIds: [id],
          }).then((res) => {
            this.closeModal();
          });
          break;
        case "deliveryForm":
          this.restoreDeliveryFormTags({ deliveryFormTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "receiptFormProvider":
          this.restoreReceiptFormProviderTags({
            receiptFormProviderTagIds: [id],
          }).then((res) => {
            this.closeModal();
          });
          break;
        case "invoiceProvider":
          this.restoreInvoiceProviderTags({ invoiceProviderTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "creditProvider":
          this.restoreCreditProviderTags({ creditProviderTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "affair":
          this.restoreAffairTags({ affairTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "contact":
          this.restoreContactTags({ contactTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "collaborator":
          this.restoreCollaboratorTags({ collaboratorTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "orderFormCustomer":
          this.restoreOrderFormCustomerTags({
            orderFormCustomerTagIds: [id],
          }).then((res) => {
            this.closeModal();
          });
          break;
        default:
          break;
      }
    },
    deleteFunctions(id) {
      switch (this.nature) {
        case "company":
          this.deleteCompanyTags({ companyTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "quote":
          this.deleteQuoteTags({ quoteTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "invoice":
          this.deleteInvoiceTags({ invoiceTagIds: [id] }).then((res) => {});
          break;
        case "credit":
          this.deleteCreditTags({ creditTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "orderFormProvider":
          this.deleteOrderFormProviderTags({
            orderFormCustomerTagIds: [id],
          }).then((res) => {
            this.closeModal();
          });
          break;
        case "deliveryForm":
          this.deleteDeliveryFormTags({ deliveryFormTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "receiptFormProvider":
          this.deleteReceiptFormProviderTags({
            receiptFormProviderTagIds: [id],
          }).then((res) => {
            this.closeModal();
          });
          break;
        case "invoiceProvider":
          this.deleteInvoiceProviderTags({ invoiceProviderTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "creditProvider":
          this.deleteCreditProviderTags({ creditProviderTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "affair":
          this.deleteAffairTags({ affairTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "contact":
          this.deleteContactTags({ contactTagIds: [id] }).then((res) => {
            this.closeModal();
          });
          break;
        case "collaborator":
          this.deleteCollaboratorTags({ collaboratorTagIds: [id] }).then(
            (res) => {
              this.closeModal();
            }
          );
          break;
        case "orderFormCustomer":
          this.deleteOrderFormCustomerTags({
            orderFormCustomerTagIds: [id],
          }).then((res) => {
            this.closeModal();
          });
          break;
        default:
          break;
      }
    },
    archiveTagLocal(id, label) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce tag en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver le tag "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveFunctions(id);
          }
        });
    },
    restoreTagLocal(id, label) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez ré-utiliser ce tag dans tous les modules de votre application",
          {
            title:
              'Êtes-vous sûr de vouloir restaurer le tag "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreFunctions(id);
          }
        });
    },
    deleteTagLocal(id, label) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: 'Êtes-vous sûr de vouloir supprimer le tag "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteFunctions(id);
          }
        });
    },
    ...mapActions([
      "getCompanyTagById",
      "getCompanyTagArchivedById",
      "createCompanyTag",
      "updateCompanyTag",
      "restoreCompanyTags",
      "archiveCompanyTags",
      "deleteCompanyTags",
      "getQuoteTagById",
      "getQuoteTagByIdArchived",
      "createQuoteTag",
      "updateQuoteTag",
      "restoreQuoteTags",
      "archiveQuoteTags",
      "deleteQuoteTags",
      "getInvoiceTagById",
      "getInvoiceTagArchivedById",
      "createInvoiceTag",
      "updateInvoiceTag",
      "restoreInvoiceTags",
      "archiveInvoiceTags",
      "deleteInvoiceTags",
      "getCreditTagById",
      "getCreditTagArchivedById",
      "createCreditTag",
      "updateCreditTag",
      "restoreCreditTags",
      "archiveCreditTags",
      "deleteCreditTags",
      "getOrderFormProviderTagById",
      "getOrderFormProviderTagArchivedById",
      "createOrderFormProviderTag",
      "updateOrderFormProviderTag",
      "restoreOrderFormProviderTags",
      "archiveOrderFormProviderTags",
      "deleteOrderFormProviderTags",
      "getDeliveryFormTagById",
      "getDeliveryFormTagArchivedById",
      "createDeliveryFormTag",
      "updateDeliveryFormTag",
      "restoreDeliveryFormTags",
      "archiveDeliveryFormTags",
      "deleteDeliveryFormTags",
      "getReceiptFormProviderTagById",
      "getReceiptFormProviderTagArchivedById",
      "createReceiptFormProviderTag",
      "updateReceiptFormProviderTag",
      "restoreReceiptFormProviderTags",
      "archiveReceiptFormProviderTags",
      "deleteReceiptFormProviderTags",
      "getInvoiceProviderTagById",
      "getInvoiceProviderTagArchivedById",
      "createInvoiceProviderTag",
      "updateInvoiceProviderTag",
      "restoreInvoiceProviderTags",
      "archiveInvoiceProviderTags",
      "deleteInvoiceProviderTags",
      "getCreditProviderTagById",
      "getCreditProviderTagArchivedById",
      "createCreditProviderTag",
      "updateCreditProviderTag",
      "restoreCreditProviderTags",
      "archiveCreditProviderTags",
      "deleteCreditProviderTags",
      "getAffairTagById",
      "getAffairTagArchivedById",
      "createAffairTag",
      "updateAffairTag",
      "restoreAffairTags",
      "archiveAffairTags",
      "deleteAffairTags",
      "getContactTagById",
      "getContactTagArchivedById",
      "createContactTag",
      "updateContactTag",
      "restoreContactTags",
      "archiveContactTags",
      "deleteContactTags",
      "getCollaboratorTagById",
      "getCollaboratorTagArchivedById",
      "createCollaboratorTag",
      "updateCollaboratorTag",
      "restoreCollaboratorTags",
      "archiveCollaboratorTags",
      "deleteCollaboratorTags",
      "getOrderFormCustomerTagById",
      "getOrderFormCustomerTagByIdArchived",
      "createOrderFormCustomerTag",
      "updateOrderFormCustomerTag",
      "restoreOrderFormCustomerTags",
      "archiveOrderFormCustomerTags",
      "deleteOrderFormCustomerTags",
    ]),
  },
  computed: {
    ...mapGetters([
      "isCreatingTag",
      "isUpdatingTag",
      "isArchivingTag",
      "isDeletingTag",
    ]),
    tag: {
      get() {
        return this.$store.getters.tag;
      },
      set(value) {
        this.$store.commit("SET_TAG", value);
      },
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
};
</script>
