import store from "@/store";
import {
  ENature,
  ENatureShort,
  EProductLabelType,
  EDocumentDetailLaborTypeReduce,
  EDocumentDetailTypeReduce,
  EDocumentStatus,
  EStorageLocationType,
  EOrderFormProviderStatus,
  EOrderFormProviderDetailTypeReduce,
  EInventoryFormStatus,
  EReceiptFormProviderStatus,
  EDeliveryFormStatus,
  EOrderFormCustomerStatus,
  reportingStatus,
} from "@/types/api-orisis/enums/enums";

export function inventoryStatusTranslate(status: number) {
  if (status == undefined) {
    return "";
  } else {
    return EInventoryFormStatus[status];
  }
}

export function natureTranslate(natureId: number): string {
  if (natureId == undefined) {
    return "";
  } else if (store.getters.natureList.length == 0) {
    return ENature[natureId];
  }
  return store.getters.natureList[natureId].label;
}

export function natureShortTranslate(natureId: number): string {
  if (natureId == undefined) {
    return "";
  } else if (store.getters.natureList.length == 0) {
    return ENatureShort[natureId];
  }
  return store.getters.natureList[natureId].label;
}

export function natureSimplifiedTranslate(natureId: number): string {
  if (natureId == undefined) {
    return "";
  } else if (natureId == 0) {
    return "Devis";
  } else if (natureId >= 1 && natureId <= 4) {
    return "Facture";
  } else if (natureId >= 5 && natureId <= 6) {
    return "Avoir";
  }
}

export function referencielTvaTranslate(referencielTvaId: number) {
  if (referencielTvaId) {
    return store.getters.institutionSettingsActive.referencielTvas.find(
      (item) => item.referencielTva.id == referencielTvaId
    ).referencielTva.label;
  } else {
    return null;
  }
}

export function unitPeriodicityTranslate(unitId: string) {
  let unitList = [
    {
      label: "Jour",
      value: "day",
    },
    {
      label: "Semaine",
      value: "week",
    },
    {
      label: "Mois",
      value: "month",
    },
    {
      label: "Trimestre",
      value: "quarter",
    },
    {
      label: "Année",
      value: "year",
    },
    {
      label: "Heure",
      value: "hour",
    },
    {
      label: "Minute",
      value: "minute",
    },
    {
      label: "Seconde",
      value: "second",
    },
    {
      label: "Milliseconde",
      value: "millisecond",
    },
  ];
  let unitLabel = unitList.find((u) => u.value == unitId);
  if (unitLabel) {
    return unitLabel.label;
  } else {
    return null;
  }
}

export function productTypeTranslate(productTypeId: number) {
  if (productTypeId == undefined) {
    return "";
  } else {
    return EProductLabelType[productTypeId];
  }
}
export function storageLocationTypeTranslate(storageLocationTypeId: number) {
  if (storageLocationTypeId == undefined) {
    return "";
  } else {
    return EStorageLocationType[storageLocationTypeId];
  }
}
export function orderFormProviderStatusTranslate(statusId: number) {
  if (statusId == undefined) {
    return "";
  } else {
    return statusId === 0
      ? EOrderFormCustomerStatus[1]
      : EOrderFormProviderStatus[statusId];
  }
}
export function receiptFormProviderStatusTranslate(statusId: number) {
  if (statusId == undefined) {
    return "";
  } else {
    return EReceiptFormProviderStatus[statusId];
  }
}
export function deliveryFormStatusTranslate(statusId: number) {
  if (statusId == undefined) {
    return "";
  } else {
    return EDeliveryFormStatus[statusId];
  }
}

export function amountTypeTranslate(amountType: number) {
  if (amountType == undefined) {
    return "";
  } else {
    return store.getters.amountTypeList.find((el) => el.value == amountType)
      .label;
  }
}

export function paymentMethodTranslate(paymentMethodId: number) {
  if (paymentMethodId) {
    const paymentMethod = store.getters.paymentMethodsList.find(
      (el) => el.id == paymentMethodId
    );
    return paymentMethod ? paymentMethod.label : "Méthode de paiement inconnue";
  } else {
    return null;
  }
}

export function documentDetailTypeTranslate(type: number) {
  if (type >= 14) {
    return EDocumentDetailLaborTypeReduce[type];
  } else {
    return EDocumentDetailTypeReduce[type];
  }
}
export function orderFormProviderDetailTypeTranslate(type: number) {
  return EOrderFormProviderDetailTypeReduce[type];
}
export function guaranteeTypeTranslate(guaranteeTypeId: number) {
  if (guaranteeTypeId == undefined) {
    return "";
  } else {
    return store.getters.guaranteeTypeList.find(
      (el) => el.value == guaranteeTypeId
    ).label;
  }
}

export function legalFormTranslate(legalFormId: number) {
  if (legalFormId == undefined) {
    return "";
  } else {
    return store.getters.legalFormList.find((el) => el.value == legalFormId)
      .label;
  }
}

export function statusToUpperCaseTranslate(status: number) {
  if (status == undefined) {
    return "";
  } else {
    return EDocumentStatus[status].toUpperCase();
  }
}

export function orderFormCustomerStatusToUpperCaseTranslate(status: number) {
  if (status == undefined) {
    return "";
  } else {
    return EOrderFormCustomerStatus[status].toUpperCase();
  }
}

export function statusTranslate(status: number) {
  if (status == undefined) {
    return "";
  } else {
    return EDocumentStatus[status];
  }
}

export function orderFormCustomerStatusTranslate(status: number) {
  if (status == undefined) {
    return "";
  } else {
    return EOrderFormCustomerStatus[status];
  }
}

export function downPaymentRequestTypeTranslate(
  downPaymentRequestType: number
) {
  switch (Number(downPaymentRequestType)) {
    case 0:
      return "%";
    case 1:
      return "€ HT";
    case 2:
      return "€ TTC";
  }
}

export function downPaymentRequestDueTranslate(downPaymentRequestDue: number) {
  switch (downPaymentRequestDue) {
    case 0:
      return "à la signature";
    case 1:
      return "au début des travaux";
    case 2:
      return "à la moitié des travaux";
  }
}

export function natureFolderTranslate(natureId: number) {
  if (natureId == undefined) {
    return "Autres";
  } else if (natureId >= 5 && natureId <= 6) {
    return "Avoirs";
  } else if (natureId >= 1 && natureId <= 4) {
    return "Factures";
  } else {
    return ENature[natureId];
  }
}

export function wasteVolumeUnityTranslate(wasteVolumeUnityId: number) {
  switch (Number(wasteVolumeUnityId)) {
    case 0:
      return "m³";
    case 1:
      return "Kg";
    case 2:
      return "T";
  }
}

export function quoteBTPWasteCollectionPointIdTranslate(
  quoteBTPWasteCollectionPointId: number
) {
  let point = store.getters.wasteCollectionPointsList.find(
    (elem) => elem.id == quoteBTPWasteCollectionPointId
  );
  if (point) {
    return (
      point.label +
      ", " +
      (point.address ? point.address : "") +
      ", " +
      (point.zipCode ? point.zipCode : "") +
      " " +
      (point.city ? point.city : "")
    );
  }
  return "N.C.";
}

export function quoteBTPWasteTypeIdTranslate(quoteBTPWasteTypeId: number) {
  let type = store.getters.wasteTypesList.find(
    (elem) => elem.id == quoteBTPWasteTypeId
  );
  if (type) {
    return type.label;
  }
  return "N.C.";
}

export function civilityTranslate(civilityId: number) {
  if (civilityId == undefined) {
    return "";
  } else {
    switch (civilityId) {
      case 0:
        return "M. ";
      case 1:
        return "Mme ";
      case 3:
        return "M. et Mme ";
      default:
        return "";
    }
  }
}

export function payrollEventTranslate(payrollEventId: number) {
  if (payrollEventId == undefined) {
    return "";
  } else {
    return store.getters.payrollEventsList.find(
      (elem) => elem.id == payrollEventId
    ).label;
  }
}

export function affairTranslate(affairId: number) {
  if (affairId == undefined) {
    return "";
  } else {
    return store.getters.affairsList.find((elem) => elem.id == affairId).name;
  }
}

export function reportStatusTranslate(status: number) {
  if (status == undefined) {
    return null;
  } else {
    return reportingStatus.find((elem) => elem.value == status);
  }
}
