<template lang="pug">
div(v-if="data && (data.firstName || data.lastName || data.phoneNumber || data.email)")
    div(class="d-flex align-items-center justify-content-start w-100")
        b {{ data.firstName }} {{ data.lastName }}
    div(v-if="data.phoneNumber || data.email")
        span {{ data.phoneNumber }}
        span(v-if="data.phoneNumber && data.email" ) /
        span {{ data.email }}
</template>
<script>
export default {
    data() {
        return {
            data: null,
        };
    },
};
</script>