<template lang="pug">
div(class="d-flex align-items-center justify-content-start h-100 w-100")
    | {{ item == 0 ? 'Débit' : 'Crédit' }}
</template>

<script>
export default {
    data() {
        return {
            item: null,
            data: null,
        };
    },
    beforeMount() {
        this.item = this.data[this.data.column.field]
    },
};
</script>