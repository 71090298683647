<template lang="pug">
div {{item}}

</template>
<script>
export default {
    data() {
        return {
            data: null,
            item: null
        }
    },
    created() {
        switch (this.data.typeField) {
            case 7:
                this.item = this.formatDate(this.data.defaultValue)
                break;
            case 8:
                this.item = this.formatDate(this.data.defaultValue)
                break;
            case 2:
                this.item = this.data.defaultValue + ' €'
                break;
            case 3:
                this.item = this.data.defaultValue + ' %'
                break;
            case 10:
                this.item = this.data.defaultValue.join(';')
                break;
            case 11: 
                this.item = this.data.defaultValue === true ? "Oui" : "Non"
                break;
            default:
                this.item = this.data.defaultValue
                break;
        }
    },
    methods: {
        formatDate(date){
            const dateToFormat = new Date(date)
            if (this.data.typeField === 7) {
                const day = dateToFormat.getDate();
                const month = dateToFormat.getMonth() + 1;
                const year = dateToFormat.getFullYear();
                const formattedDay = day < 10 ? '0' + day : day;
                const formattedMonth = month < 10 ? '0' + month : month;
                return `${formattedDay}/${formattedMonth}/${year}`
            } else if(this.data.typeField === 8){
                const day = dateToFormat.getDate();
                const month = dateToFormat.getMonth() + 1;
                const year = dateToFormat.getFullYear();
                const formattedDay = day < 10 ? '0' + day : day;
                const formattedMonth = month < 10 ? '0' + month : month;
                const hours = dateToFormat.getHours();
                const minutes = dateToFormat.getMinutes();
                const formattedHours = hours < 10 ? '0' + hours : hours;
                const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
                return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`
            }
        }
    },
}
</script>