<template lang="pug">
  div.chip.d-flex.justify-content-center.align-items-center(style="height: 100%;")
    span.material-icons-outlined.text-primary(v-if="data.eventId && !data.interventionId" style="cursor: default;font-size: 16px;" title="Planning") event
    div(v-else-if="(data.createdBy || data.collaboratorId) && !data.interventionId" class="d-flex justify-content-center align-items-center")
      span.e-icons.e-avatar.e-avatar-xsmall.e-avatar-circle.bg-primary.text-white(style="font-family: inherit; width: 24px; height: 24px; display: flex; justify-content: center; align-items: center;") {{ initials }}
    span.material-icons-outlined.text-primary(v-else-if="data.interventionId" style="cursor: default;font-size: 16px;" title="Intervention") construction
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/fr";
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");

export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  computed: {
    collaboratorsList() {
      return this.$store.getters["collaboratorsList"];
    },
    usersList() {
      return this.$store.getters["usersList"];
    },
    initials() {
      if (!this.data) return "";

      let person = this.usersList.find((u) => u.userId === this.data.createdBy);

      if (!person) {
        person = this.collaboratorsList.find(
          (c) => c.id === this.data.collaboratorId
        );
      }
      return person
        ? this.getInitials(person.firstName + " " + person.lastName)
        : "N/A";
    },
  },
  methods: {
    getInitials(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
  },
};
</script>

<style lang="scss">
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-vue-layouts/styles/material.css";
</style>
