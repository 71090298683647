<template lang="pug">
  div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
    b-row.content-scrollable-sticky.m-0.justify-content-center(style="margin-top: -12px !important;")
      b-col#nav-sticky-horizontal.content-scrollable-sticky-inner(cols='12' style='padding: 0 !important; height: 100% !important')
        ul.d-flex
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'all' ? 'current' : ''" @click="tabActive = 'all'")
              span Tous les produits ({{ getCountDataByStatus("all") }})
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'availableForSale' ? 'current' : ''" @click="tabActive = 'availableForSale'")
              span Disponibles à la vente ({{ getCountDataByStatus("availableForSale") }})
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'others' ? 'current' : ''" @click="tabActive = 'others'")
              span Autres ({{ getCountDataByStatus("others") }})
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingCatalog || isLoadingCatalogArchived || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des produits/services...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows"  :tagguerButton="tagguerButton")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-product-content(ref="sidebarProductContent" @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import { productTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarProductContent from "@/components/catalog/SidebarProductContent.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarProductContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "product",
      uid: "products",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un produit",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "productType",
        filterTemplate: "productTypeFilterTemplate",
        headerText: "Type",
        width: 140,
        maxWidth: 140,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        template: "productTypeTemplate",
        visible: true,
      },
      {
        type: "string",
        field: "reference",
        headerText: "Référence",
        width: 140,
        minWidth: 140,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        allowGrouping: false,
      },
      {
        type: "string",
        field: "description",
        headerText: "Désignation",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowGrouping: false,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "stripHtmlTemplate",
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "string",
        field: "label",
        headerText: "Libellé de recherche",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowGrouping: false,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "number",
        format: "formatNumber",
        field: "quantity",
        headerText: "Qté",
        width: 100,
        minWidth: 100,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "unit.label",
        headerText: "Unité",
        width: 150,
        minWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "number",
        field: "priceHT",
        headerText: "Prix de vente HT",
        width: 150,
        minWidth: 150,
        textAlign: "right",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        allowGrouping: false,
      },
      {
        type: "number",
        field: "parentId",
        headerText: "Dépendant",
        width: 150,
        minWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        visible: true,
        allowGrouping: false,
        template: "parentTemplate",
        filterTemplate: "haveParentFilterTemplate",
      },
    ];
  },
  computed: {
    ...mapGetters(["isLoadingCatalog", "isLoadingCatalogArchived"]),
    dataOrigine() {
      if (this.archive) {
        return this.$store.getters.catalogArchived;
      } else {
        return this.$store.getters.catalog;
      }
    },
    dataFiltered() {
      if (this.tabActive == "all") {
        if (this.archive) {
          return this.$store.getters.catalogArchived;
        } else {
          return this.$store.getters.catalog;
        }
      } else if (this.tabActive == "availableForSale") {
        if (this.archive) {
          return this.$store.getters.catalogArchived.filter(
            (elem) => elem.unAvailableForSale === false
          );
        } else {
          return this.$store.getters.catalog.filter(
            (elem) => elem.unAvailableForSale === false
          );
        }
      } else if (this.tabActive == "others") {
        if (this.archive) {
          return this.$store.getters.catalogArchived.filter(
            (elem) => elem.unAvailableForSale === true
          );
        } else {
          return this.$store.getters.catalog.filter(
            (elem) => elem.unAvailableForSale === true
          );
        }
      }
    },
  },
  async created() {
    await this.getProducts({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
      }
      this.indexSearch++;
      this.headerLoaded = true;
    });
  },
  mounted() {
    this.viewMap = false;
  },
  methods: {
    ...mapActions(["getProducts", "getProductsArchived"]),
    productTypeTranslate,
    formatCurrency,
    setColumnsByObject,
    async changeTab(tab) {
      this.tabActive = tab;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({ path: "/new-product" });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getProductsArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getProducts({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      if (!this.archive) {
        this.$refs["sidebarProductContent"].getProductDetails(event.rowData.id);
      } else {
        this.$refs["sidebarProductContent"].getProductArchivedDetails(
          event.rowData.id,
          this.archive
        );
      }
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else if (status == "availableForSale") {
        return this.dataOrigine.filter((elem) => !elem.unAvailableForSale)
          .length;
      } else if (status == "others") {
        return this.dataOrigine.filter((elem) => elem.unAvailableForSale)
          .length;
      }
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
