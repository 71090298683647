<template lang="pug">
span
    .material-icons.text-success(v-if="data.directionType == 1") add   
    .material-icons.text-danger(v-else) horizontal_rule   
</template>
<script>
export default {
  data() {
    return {
      data: null
    };
  },
}
</script>
